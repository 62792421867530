import styled, { css } from 'styled-components';

const ToggleItemWrapper = styled.div<{
  $isSelected: boolean;
}>`
  ${({ theme: { convertVW }, $isSelected }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${convertVW(210)};
    height: ${convertVW(50)};
    background: ${$isSelected ? '#858585' : 'var(--card)'};
    border: ${convertVW(1)} solid var(--border);
    border-radius: ${convertVW(8)};
    cursor: pointer;

    user-select: none;
    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(16)};
    line-height: ${convertVW(25)};
    color: ${$isSelected ? '#ffffff' : 'var(--text)'};
  `}
`;

export { ToggleItemWrapper as Wrapper };
