/** Dependencies */
import React, { FC, useCallback, useEffect, useRef } from 'react';

/** Features */
import SkeletonList from 'features/SkeletonList/SkeletonList';
import NoContent from 'features/NoContent/NoContent';

/** Store */
import { selectColumnCount } from 'store/slices/Grid.slice';
import { setIsHeaderBottomSideDisabled } from 'store/slices/Header.slice';

/** Hooks */
import { useAppSelector } from 'hooks/UseAppSelector';
import { useAppDispatch } from 'hooks/UseAppDispatch';

/** Components */
import Card from 'components/Card/Card';
import { ICard } from 'components/Card/types';

/** Styles */
import * as S from './Page.styled';

interface IPageProps {
  designs?: ICard[];
  isLoading: boolean;
  isContentShown: boolean;
  isAdminCard?: boolean;
  noContentTexts?: {
    title: string;
    description: string;
    acknowledgment: string;
  };
  onCopy?: () => Promise<string | void>;
  onLoadMore?: () => Promise<void>;
  hasMoreData?: boolean;
  loadingMore?: boolean;
  refreshDesigns?: () => Promise<void>;
  refreshLikes?: () => Promise<void>;
}

const Page: FC<IPageProps> = ({
  designs,
  isLoading,
  noContentTexts,
  isContentShown,
  isAdminCard = false,
  onCopy,
  onLoadMore,
  hasMoreData = false,
  loadingMore = false,
  refreshDesigns = async () => Promise.resolve(),
  refreshLikes = async () => Promise.resolve(),
}) => {
  const dispatch = useAppDispatch();
  const columnCount = useAppSelector(selectColumnCount);
  const observerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(setIsHeaderBottomSideDisabled(false));

    return () => {
      dispatch(setIsHeaderBottomSideDisabled(true));
    };
  }, []);

  // Handle infinite scroll
  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting && hasMoreData && !loadingMore && onLoadMore) {
        onLoadMore();
      }
    },
    [hasMoreData, loadingMore, onLoadMore]
  );

  useEffect(() => {
    const element = observerRef.current;
    if (!element || !onLoadMore) return;

    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [handleObserver, onLoadMore]);

  // Display skeleton when initially loading and no designs are available yet
  if (isLoading) {
    return (
      <S.Wrapper $columnCount={columnCount}>
        <SkeletonList />
      </S.Wrapper>
    );
  }

  // Display no content when loading is complete and there are no designs
  if (!isLoading && !isContentShown) {
    return (
      <S.Wrapper $columnCount={columnCount}>
        <NoContent texts={noContentTexts} />
      </S.Wrapper>
    );
  }

  // Display designs
  return (
    <S.Wrapper $columnCount={columnCount}>
      {designs?.map((card, i) => (
        <Card
          id={card.id}
          key={i}
          documentId={card.documentId}
          title={card.subjectLine}
          image={card.image.url}
          figmaObject={card.figmaObject}
          likeCount={card.likedUsers.length}
          isAdminCard={isAdminCard}
          onCopy={onCopy}
          refreshDesigns={refreshDesigns}
          refreshLikes={refreshLikes}
        />
      ))}

      {/* Loader reference element */}
      <div
        ref={observerRef}
        style={{ gridColumn: '1 / -1', height: '20px', margin: '20px 0' }}
      >
        {loadingMore && (
          <S.LoaderWrapper>
            <S.Loader />
          </S.LoaderWrapper>
        )}
      </div>
    </S.Wrapper>
  );
};

export default Page;
