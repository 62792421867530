import styled, { css } from 'styled-components';
import { BreakPoint } from 'constants/BreakPoint';
import { SPACING } from 'constants/Spacing';

const TagsWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: ${convertVW(SPACING.sm)};
    width: 100%;
    margin-bottom: ${convertVW(8)};

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      width: 100%;
    }
  `}
`;

const TagsLabel = styled.div`
  ${({ theme: { convertVW } }) => css`
    user-select: none;
    font-family: 'DM Sans', sans-serif;
    font-size: ${convertVW(14)};
    line-height: ${convertVW(20)};
    color: var(--tab-text);
    font-weight: 400;
    letter-spacing: ${convertVW(0.5)};
  `}
`;

export { TagsWrapper as Wrapper, TagsLabel as Label };
