/** Dependencies */
import React, { FC, useEffect, useState } from 'react';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

/** Images */
import Logo from 'assets/images/svg/dark/logo-dark.svg';

/** Components */
import Button from 'components/Button/Button';

/** Store */
import { hideModal } from 'store/slices/Modal.slice';

/** Hooks */
import { useAppDispatch } from 'hooks/UseAppDispatch';
import { useTheme } from 'hooks/UseTheme';

/** Styles */
import * as S from './CopyLimitModalContent.styled';

interface IPlanOption {
  title: string;
  subtitle: string;
  price: number;
  originalPrice?: number;
  billingPeriod: string;
  features: string[];
  highlightFeatures: string[];
  isPopular?: boolean;
}

const CopyLimitModalContent: FC = () => {
  const { openProfile, plans, user } = useAuth();
  const dispatch = useAppDispatch();
  const { isDarkMode } = useTheme();

  // State management
  const [selectedPlanId, setSelectedPlanId] = useState<string>('pro');
  const [isYearlyBilling, setIsYearlyBilling] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<{
    hours: number;
    minutes: number;
    seconds: number;
  }>({
    hours: 23,
    minutes: 59,
    seconds: 42,
  });

  // Find user's current plan
  const selectedPlan = plans.find(
    (plan) => plan.Uid === user?.Account?.CurrentSubscription?.Plan?.Uid
  );
  const isBasic = selectedPlan?.Name === 'Basic';

  // Define plan options
  const monthlyPlans: Record<string, IPlanOption> = {
    basic: {
      title: 'Basic Plan',
      subtitle: 'Perfect for DTC Brands',
      price: 59,
      billingPeriod: 'Billed monthly',
      features: ['Full template library', '10 exports/day', 'Weekly updates'],
      highlightFeatures: [],
    },
    pro: {
      title: 'Pro Plan',
      subtitle: 'Perfect for Agencies',
      price: 99,
      billingPeriod: 'Billed monthly',
      features: ['Full template library'],
      highlightFeatures: [
        'Unlimited exports',
        'Priority support',
        'Early access to templates',
      ],
      isPopular: true,
    },
  };

  const yearlyPlans: Record<string, IPlanOption> = {
    basic: {
      title: 'Basic Plan',
      subtitle: 'Perfect for DTC Brands',
      price: 41,
      originalPrice: 59,
      billingPeriod: 'Billed annually ($492)',
      features: ['Full template library', '10 exports/day', 'Weekly updates'],
      highlightFeatures: [],
    },
    pro: {
      title: 'Pro Plan',
      subtitle: 'Perfect for Agencies',
      price: 70,
      originalPrice: 99,
      billingPeriod: 'Billed annually ($840)',
      features: ['Full template library'],
      highlightFeatures: [
        'Unlimited exports',
        'Priority support',
        'Early access to templates',
      ],
      isPopular: true,
    },
  };

  // Countdown timer functionality
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        let { hours, minutes, seconds } = prev;

        seconds--;
        if (seconds < 0) {
          seconds = 59;
          minutes--;

          if (minutes < 0) {
            minutes = 59;
            hours--;

            if (hours < 0) {
              hours = 23;
              minutes = 59;
              seconds = 59;
            }
          }
        }

        return { hours, minutes, seconds };
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleUpgradeClick = (): void => {
    dispatch(hideModal());
    openProfile({ tab: 'planChange' });
  };

  const handleRemindClick = (): void => {
    dispatch(hideModal());
  };

  const handlePlanSelect = (planId: string): void => {
    setSelectedPlanId(planId);
  };

  const handleBillingToggle = (): void => {
    setIsYearlyBilling(!isYearlyBilling);
  };

  const activePlans = isYearlyBilling ? yearlyPlans : monthlyPlans;

  return (
    <S.PopupContainer $isDark={isDarkMode}>
      {/* Close Button */}
      <S.CloseButton onClick={() => dispatch(hideModal())}>×</S.CloseButton>

      {/* Left Section */}
      <S.PopupLeft>
        <S.BrandContainer>
          <S.BrandIcon src={Logo} alt={'logo'} />
        </S.BrandContainer>

        <S.HeaderContainer>
          <S.Title>Unlock Unlimited Design & Export Power</S.Title>
          <S.Subtitle>
            {'You`ve reached today`s limit on free exports'}
          </S.Subtitle>
        </S.HeaderContainer>

        <S.StatusContainer>
          <S.StatusLabel>
            <span>{'Today`s Usage'}</span>
            <S.StatusAlert>
              <S.AlertIcon>!</S.AlertIcon>
              100% Used
            </S.StatusAlert>
          </S.StatusLabel>
          <S.StatusBar>
            <S.StatusFill />
          </S.StatusBar>
        </S.StatusContainer>

        <S.BenefitContainer>
          <S.BenefitTitle>Premium users enjoy:</S.BenefitTitle>
          <S.BenefitList>
            <S.BenefitItem>
              <S.BenefitIcon>⚡</S.BenefitIcon>
              <S.BenefitText>
                Save <S.BenefitHighlight>15+ hours</S.BenefitHighlight> each
                week on design work
              </S.BenefitText>
            </S.BenefitItem>

            <S.BenefitItem>
              <S.BenefitIcon>📈</S.BenefitIcon>
              <S.BenefitText>
                Increase conversions by{' '}
                <S.BenefitHighlight>38%</S.BenefitHighlight> on average
              </S.BenefitText>
            </S.BenefitItem>

            <S.BenefitItem>
              <S.BenefitIcon>📁</S.BenefitIcon>
              <S.BenefitText>
                Access to <S.BenefitHighlight>800+ premium</S.BenefitHighlight>{' '}
                templates
              </S.BenefitText>
            </S.BenefitItem>
          </S.BenefitList>
        </S.BenefitContainer>
      </S.PopupLeft>

      {/* Right Section */}
      <S.PopupRight>
        <S.HeaderContainer>
          <S.RightTitle>Choose Your Membership Plan</S.RightTitle>
          <S.RightSubtitle>
            All plans include access to our full template library
          </S.RightSubtitle>
        </S.HeaderContainer>

        <S.PricingToggleContainer $isYearly={isYearlyBilling}>
          <S.ToggleOption
            $isActive={!isYearlyBilling}
            onClick={() => setIsYearlyBilling(false)}
          >
            Monthly
          </S.ToggleOption>
          <S.ToggleOption
            $isActive={isYearlyBilling}
            onClick={() => setIsYearlyBilling(true)}
          >
            <S.YearlyLabelContainer>
              Yearly <S.SaveLabel>SAVE 30%</S.SaveLabel>
            </S.YearlyLabelContainer>
          </S.ToggleOption>
          <S.ToggleBg $isYearly={isYearlyBilling} />
        </S.PricingToggleContainer>

        <S.PlansContainer>
          {Object.entries(activePlans).map(([planId, plan]) => (
            <S.PlanCard
              key={planId}
              $isPopular={plan.isPopular}
              $isSelected={selectedPlanId === planId}
              onClick={() => handlePlanSelect(planId)}
            >
              {plan.isPopular && <S.Badge $type="popular">POPULAR</S.Badge>}
              {isYearlyBilling && plan.isPopular && (
                <S.Badge $type="save">SAVE 30%</S.Badge>
              )}

              {selectedPlanId === planId && <S.SelectedMark>✓</S.SelectedMark>}

              <S.PlanTitle>{plan.title}</S.PlanTitle>
              <S.PlanSubtitle>{plan.subtitle}</S.PlanSubtitle>

              <S.PlanPrice>
                {plan.originalPrice && (
                  <S.OriginalPrice>${plan.originalPrice}</S.OriginalPrice>
                )}
                ${plan.price}
                <S.PriceUnit>/mo</S.PriceUnit>
              </S.PlanPrice>

              <S.PlanBilling>{plan.billingPeriod}</S.PlanBilling>

              <S.PlanFeatures>
                {plan.features.map((feature, index) => (
                  <S.PlanFeature key={index}>{feature}</S.PlanFeature>
                ))}
                {plan.highlightFeatures.map((feature, index) => (
                  <S.PlanFeature $highlight key={index}>
                    {feature}
                  </S.PlanFeature>
                ))}
              </S.PlanFeatures>
            </S.PlanCard>
          ))}
        </S.PlansContainer>

        <S.CtaSection>
          <S.CountdownContainer>
            <S.Countdown>
              <span>⚡ Special offer ends in: </span>
              <S.Timer>
                <S.TimeUnit>
                  {String(countdown.hours).padStart(2, '0')}
                </S.TimeUnit>
                <S.TimeSeparator>:</S.TimeSeparator>
                <S.TimeUnit>
                  {String(countdown.minutes).padStart(2, '0')}
                </S.TimeUnit>
                <S.TimeSeparator>:</S.TimeSeparator>
                <S.TimeUnit>
                  {String(countdown.seconds).padStart(2, '0')}
                </S.TimeUnit>
              </S.Timer>
            </S.Countdown>
          </S.CountdownContainer>

          <Button
            text={`Upgrade to ${activePlans[selectedPlanId].title}`}
            onClick={handleUpgradeClick}
            bgColor={'#4361EE'}
            size="large"
          />

          <S.SupportText>
            <S.GuaranteeIcon>🛡️</S.GuaranteeIcon>
            <span>72-hour satisfaction guarantee. Try risk-free.</span>
          </S.SupportText>

          <S.NoThanksButton onClick={handleRemindClick}>
            Continue with limited exports
          </S.NoThanksButton>
        </S.CtaSection>
      </S.PopupRight>
    </S.PopupContainer>
  );
};

export default CopyLimitModalContent;
