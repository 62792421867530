/** Dependencies */
import React, { FC } from 'react';

type props = {
  children: React.ReactNode;
  textToCopy: string;
  onCopy: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const CopyToClipboardHTML: FC<props> = ({ children, textToCopy, onCopy }) => {
  const copyToClipboard = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): boolean | undefined => {
    e.preventDefault();

    // Clean HTML tags that cause issues
    const cleanHtmlTags = (htmlContent: string): string => {
      const tagsToRemove: string[] = [
        '<!--EndFragment-->',
        '<!--StartFragment-->',
        '<html>',
        '</html>',
        '<body>',
        '</body>',
      ];

      let cleanedContent: string = htmlContent;
      for (const tag of tagsToRemove) {
        cleanedContent = cleanedContent.replace(tag, '');
      }

      return cleanedContent;
    };

    // Clean the HTML content
    const cleanedHtml = cleanHtmlTags(textToCopy);

    // Extract plain text from HTML for fallback and Figma compatibility
    const getPlainTextFromHtml = (html: string): string => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      return tempDiv.textContent || tempDiv.innerText || '';
    };

    const plainText = getPlainTextFromHtml(cleanedHtml);

    // Fallback copy method
    const fallbackCopy = (): boolean => {
      document.addEventListener(
        'copy',
        (event: ClipboardEvent) => {
          if (event.clipboardData) {
            event.clipboardData.setData('text/html', cleanedHtml);
            event.clipboardData.setData('text/plain', plainText);
            event.preventDefault();
          }
        },
        { once: true }
      );

      if (!document.execCommand('copy')) {
        const textarea = document.createElement('textarea');
        textarea.textContent = plainText; // Use plain text for maximum compatibility
        textarea.style.position = 'fixed';
        textarea.style.opacity = '0';
        document.body.appendChild(textarea);
        textarea.select();

        try {
          const success = document.execCommand('copy');
          if (success) onCopy(e);
          return success;
        } catch (ex) {
          console.warn('Copy to clipboard failed.', ex);
          return false;
        } finally {
          document.body.removeChild(textarea);
        }
      } else {
        onCopy(e);
        return true;
      }
    };

    // Modern clipboard API approach (preferred)
    if (navigator.clipboard && navigator.clipboard.write) {
      const clipboardItems = [
        new ClipboardItem({
          'text/html': new Blob([cleanedHtml], { type: 'text/html' }),
          'text/plain': new Blob([plainText], { type: 'text/plain' }),
        }),
      ];

      navigator.clipboard
        .write(clipboardItems)
        .then(() => {
          onCopy(e);
        })
        .catch((err) => {
          console.warn(
            'Clipboard API failed, falling back to execCommand',
            err
          );
          fallbackCopy();
        });

      return true;
    }
    return fallbackCopy();
  };

  return <div onClick={copyToClipboard}>{children}</div>;
};

export default CopyToClipboardHTML;
