import { configureStore } from '@reduxjs/toolkit';

import sortReducer from 'store/slices/Sort.slice';
import gridReducer from 'store/slices/Grid.slice';
import sidebarReducer from 'store/slices/Sidebar.slice';
import headerReducer from 'store/slices/Header.slice';
import modalReducer from 'store/slices/Modal.slice';

export const store = configureStore({
  reducer: {
    sort: sortReducer,
    grid: gridReducer,
    sidebar: sidebarReducer,
    header: headerReducer,
    modal: modalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: [
          'modal.content.props.onConfirm',
          'payload.content',
        ],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
