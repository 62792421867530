import styled, { css, RuleSet } from 'styled-components';
import { SPACING } from 'constants/Spacing';
import { ButtonSize, ButtonVariant } from './Button';

// Size configurations
const getSizeStyles = (
  size: ButtonSize,
  convertVW: (sizeInPx: number) => string
): RuleSet<object> => {
  switch (size) {
    case 'small':
      return css`
        height: ${convertVW(32)};
        font-size: ${convertVW(11)};
        padding: 0 ${convertVW(SPACING.sm)};
      `;
    case 'large':
      return css`
        height: ${convertVW(48)};
        font-size: ${convertVW(14)};
        padding: 0 ${convertVW(SPACING.lg)};
      `;
    case 'medium':
    default:
      return css`
        height: ${convertVW(40)};
        font-size: ${convertVW(12)};
        padding: 0 ${convertVW(SPACING.md)};
      `;
  }
};

// Get variant color styles
const getVariantStyles = (
  variant: ButtonVariant,
  theme: any,
  isOutline: boolean,
  isDisabled: boolean
): RuleSet<object> => {
  if (isDisabled) {
    return css`
      background: ${theme.buttonDisabled};
      color: ${theme.buttonDisabledText};
      border-color: ${theme.buttonDisabled};
    `;
  }

  const variantMap = {
    primary: {
      bg: theme.button,
      text: theme.buttonText,
      hover: theme.buttonHover,
    },
    secondary: {
      bg: theme.background,
      text: theme.text,
      hover: theme.border,
    },
    success: {
      bg: theme.success,
      text: '#FFFFFF',
      hover: '#0DA271',
    },
    error: {
      bg: theme.error,
      text: '#FFFFFF',
      hover: '#D93A3A',
    },
    warning: {
      bg: theme.warning,
      text: '#FFFFFF',
      hover: '#E08F0A',
    },
    info: {
      bg: theme.info,
      text: '#FFFFFF',
      hover: '#2563EB',
    },
  };

  const { bg, text, hover } = variantMap[variant];

  if (isOutline) {
    return css`
      background: transparent;
      color: ${bg};
      border-color: ${bg};

      &:hover {
        background: ${bg}10; /* 10% opacity */
        border-color: ${hover};
        color: ${hover};
      }
    `;
  }

  return css`
    background: ${bg};
    color: ${text};
    border-color: ${bg};

    &:hover {
      background: ${hover};
      border-color: ${hover};
    }
  `;
};

const ButtonWrapper = styled.button<{
  $isDisabled: boolean;
  $isRounded: boolean;
  $isOutline: boolean;
  $variant: ButtonVariant;
  $size: ButtonSize;
  $fullWidth: boolean;
  $bgColor?: string;
}>`
  ${({
    theme,
    $isDisabled,
    $isRounded,
    $isOutline,
    $bgColor,
    $variant,
    $size,
    $fullWidth,
  }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${theme.convertVW(SPACING.sm)};
    width: ${$isRounded ? theme.convertVW(40) : $fullWidth ? '100%' : 'auto'};
    border-radius: ${$isRounded ? '50%' : theme.convertVW(SPACING.xs * 2)};
    cursor: ${$isDisabled ? 'not-allowed' : 'pointer'};
    transition: all 0.2s ease-in-out;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    user-select: none;
    border: ${theme.convertVW(1)} solid;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;

    /* Apply size-specific styles */

    ${getSizeStyles($size, theme.convertVW)}
    /* Apply variant-specific styles or custom background */
        ${$bgColor
      ? css`
          background: ${$bgColor};
          border-color: ${$bgColor};
          color: ${theme.buttonText};

          &:hover {
            filter: brightness(95%);
          }
        `
      : getVariantStyles($variant, theme, $isOutline, $isDisabled)}
        &:hover {
      box-shadow: ${$isDisabled
        ? 'none'
        : `0 ${theme.convertVW(4)} ${theme.convertVW(8)} ${theme.shadow}`};
      transform: ${$isDisabled ? 'none' : `translateY(${theme.convertVW(-1)})`};
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 ${theme.convertVW(2)}
        ${$isDisabled ? 'transparent' : `${theme[$variant] || theme.button}40`};
    }

    &:active {
      transform: ${$isDisabled ? 'none' : 'translateY(0)'};
    }

    /* Add a subtle ripple effect on click */

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: ${theme.buttonText};
      opacity: 0;
      border-radius: inherit;
      pointer-events: none;
      transform: scale(0);
      transition: transform 0.3s, opacity 0.3s;
    }

    &:active::after {
      transform: scale(2);
      opacity: 0.1;
      transition: 0s;
    }

    /* Handle rounded icon-only buttons */
    ${$isRounded &&
    css`
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${theme.convertVW(
        $size === 'small' ? 32 : $size === 'large' ? 48 : 40
      )};
      height: ${theme.convertVW(
        $size === 'small' ? 32 : $size === 'large' ? 48 : 40
      )};
    `}
  `}
`;

const ButtonText = styled.span`
  ${() => css`
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
  `}
`;

const ButtonIcon = styled.img`
  ${({ theme }) => css`
    max-width: ${theme.convertVW(16)};
    transition: transform 0.2s ease-in-out;

    ${ButtonWrapper}:hover & {
      transform: scale(1.1);
    }
  `}
`;

export { ButtonWrapper as Wrapper, ButtonText as Text, ButtonIcon as Icon };
