/** Dependencies */
import React, { FC, useEffect, useRef, useState } from 'react';

/** Components */
import Input from 'components/Input/Input';

/** Images */
import ArrowDownIcon from 'assets/images/svg/arrow-down-icon.svg';
import ArrowUpIcon from 'assets/images/svg/arrow-up-icon.svg';
import ArrowDownIconDark from 'assets/images/svg/dark/arrow-down-icon-dark.svg';
import ArrowUpIconDark from 'assets/images/svg/dark/arrow-up-icon-dark.svg';

/** Hooks */
import { useOnClickOutside } from 'hooks/UseOnClickOutside';
import { useTheme } from 'hooks/UseTheme';

/** Store */
import { ISortItem } from 'store/slices/Sort.slice';

/** Styles */
import * as S from 'components/Select/Select.styled';

interface IProps {
  name?: string;
  placeholder?: string;
  list: ISortItem[];
  isArrowShown?: boolean;
  isMultiple?: boolean;
  isSelectedValuesShown?: boolean;
  onChange: (listItem: ISortItem, isRemoving: boolean) => void;
  selectedValue: ISortItem | ISortItem[] | null;
}

const Select: FC<IProps> = ({
  name,
  placeholder = '',
  list,
  isArrowShown = false,
  onChange,
  selectedValue,
  isMultiple = false,
  isSelectedValuesShown = true,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const { isDarkMode } = useTheme();
  const [isListVisible, setIsListVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string | string[]>();

  const ArrowUp = isDarkMode ? ArrowUpIconDark : ArrowUpIcon;
  const ArrowDown = isDarkMode ? ArrowDownIconDark : ArrowDownIcon;

  useOnClickOutside(ref, () => {
    setIsListVisible(false);
  });

  const handleWrapperClick = (): void => {
    setIsListVisible(!isListVisible);
  };

  const handleListClick = (e: any): void => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleListItemClick = (
    listItem: ISortItem,
    isMultipleSelected: boolean
  ): void => {
    if (isMultiple && Array.isArray(selectedItems)) {
      if (isMultipleSelected) {
        setSelectedItems(
          selectedItems.filter((item) => item !== listItem?.text)
        );
        onChange(listItem, true);
      } else {
        setSelectedItems([...selectedItems, listItem?.text]);
        onChange(listItem, false);
      }
    } else {
      setSelectedItems(listItem?.text);
      onChange(listItem, false);
    }
    setIsListVisible(false);
  };

  const handleMultiSelectObjectClick = (vl: string): void => {
    const selectedItem = list.find((listItem) => vl === listItem?.text);

    selectedItem &&
      isMultiple &&
      Array.isArray(selectedItems) &&
      handleListItemClick(selectedItem, true);
  };

  useEffect(() => {
    if (isMultiple && Array.isArray(selectedValue)) {
      setSelectedItems(selectedValue.map((vl) => vl?.text));
    } else {
      setSelectedItems((selectedValue as ISortItem)?.text);
    }
  }, [selectedValue]);

  return (
    <S.Wrapper ref={ref} onClick={handleWrapperClick}>
      <Input
        name={name}
        placeholder={placeholder}
        defaultValue={selectedItems}
        isIconShown={isArrowShown}
        isSelectedValuesShown={isSelectedValuesShown}
        icon={isListVisible ? ArrowUp : ArrowDown}
        type={'select'}
        isMultiple={isMultiple}
        onMultiSelectObjectClick={handleMultiSelectObjectClick}
      />
      {isListVisible && (
        <S.List onClick={handleListClick}>
          {list.map((listItem, i) => {
            const isMultipleSelected =
              isMultiple &&
              Array.isArray(selectedItems) &&
              selectedItems.some((vl) => vl === listItem?.text);

            return (
              <S.ListItem
                $isSelected={
                  isMultiple && Array.isArray(selectedItems)
                    ? isMultipleSelected
                    : listItem?.text === selectedItems
                }
                onClick={() =>
                  handleListItemClick(listItem, isMultipleSelected)
                }
                key={i}
              >
                {listItem?.text}
              </S.ListItem>
            );
          })}
        </S.List>
      )}
    </S.Wrapper>
  );
};

export default Select;
