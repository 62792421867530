/** Dependencies */
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/** Api */
import { getRequest, putRequest } from 'api/apiClient';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

/** Utilities */
import { flattenResponse } from 'utilities/functions';

interface IUseDesignLikesReturn {
  likes?: number[];
  handleLikeClick: (id: number) => Promise<void>;
  getLikes: () => Promise<void>;
  isFetchLikesLoading: boolean;
  isLikeClickLoading: boolean;
}

export const useDesignLikes = (): IUseDesignLikesReturn => {
  const { pathname } = useLocation();
  const [likes, setLikes] = useState<number[]>([]);
  const [isFetchLikesLoading, setIsFetchLikesLoading] = useState<boolean>(true);
  const [isLikeClickLoading, setIsLikeClickLoading] = useState<boolean>(false);
  const [likesWrapperObjectId, setLikesWrapperObjectId] = useState<number>(0);
  const { user } = useAuth();

  const getLikes = useCallback(async (): Promise<void> => {
    setIsFetchLikesLoading(true);
    const response = await getRequest(
      ENDPOINTS.SYSTEM_USER_WITH_UID_POPULATED(user?.Uid)
    );

    setLikesWrapperObjectId(response.data.data[0]?.documentId);
    setLikes(
      flattenResponse(
        flattenResponse(response?.data?.data)?.[0]?.likes ?? []
      )?.map((like) => like.id) ?? []
    );
    setIsFetchLikesLoading(false);
  }, [user?.Uid]);

  const handleLikeClick = useCallback(
    async (id: number): Promise<void> => {
      setIsLikeClickLoading(true);
      const newLikes = likes.some((like) => like === id)
        ? likes.filter((like) => like !== id)
        : [...likes, id];

      await putRequest(ENDPOINTS.LIKE_DESIGN(likesWrapperObjectId), {
        likes: newLikes,
        userId: user.Uid,
      });

      await getLikes();
      setIsLikeClickLoading(false);
    },
    [getLikes, likes, likesWrapperObjectId, user.Uid]
  );

  useEffect(() => {
    void getLikes();
  }, [pathname]);

  return {
    ...(likes && likes.length > 0 ? { likes } : {}),
    handleLikeClick,
    getLikes,
    isFetchLikesLoading,
    isLikeClickLoading,
  };
};
