import styled, { css } from 'styled-components';

export type ToggleSize = 'sm' | 'md' | 'lg';

// Function to get size-specific values
const getSizeValues = (
  size: ToggleSize,
  convertVW: (value: number) => string
): any => {
  switch (size) {
    case 'sm':
      return {
        width: convertVW(48),
        height: convertVW(17),
        borderRadius: convertVW(10),
        knobSize: convertVW(14),
        knobMargin: convertVW(1.5),
      };
    case 'lg':
      return {
        width: convertVW(88),
        height: convertVW(30),
        borderRadius: convertVW(18),
        knobSize: convertVW(24),
        knobMargin: convertVW(3),
      };
    case 'md':
    default:
      return {
        width: convertVW(68),
        height: convertVW(23.57),
        borderRadius: convertVW(14.51),
        knobSize: convertVW(18.86),
        knobMargin: convertVW(2.36),
      };
  }
};

const ToggleWrapper = styled.div<{ $background: string; $size?: ToggleSize }>`
  ${({ theme: { convertVW, shadow }, $background, $size = 'md' }) => {
    const { width, height, borderRadius } = getSizeValues($size, convertVW);

    return css`
      position: relative;
      width: ${width};
      height: ${height};
      background: url(${$background}) no-repeat center;
      background-size: contain;
      border-radius: ${borderRadius};
      cursor: pointer;
      user-select: none;
      transition: all var(--transition-speed) var(--transition-timing);
      box-shadow: 0 ${convertVW(1)} ${convertVW(3)} rgba(0, 0, 0, 0.1);

      &:hover {
        transform: scale(1.03);
        box-shadow: 0 ${convertVW(2)} ${convertVW(5)} ${shadow};
      }

      &:active {
        transform: scale(0.98);
      }

      /* Add focus styles for accessibility */

      &:focus-visible {
        outline: none;
        box-shadow: 0 0 0 ${convertVW(2)} var(--button);
      }
    `;
  }}
`;

const ToggleKnob = styled.img<{ $isActive: boolean; $size?: ToggleSize }>`
  ${({ theme: { convertVW }, $isActive, $size = 'md' }) => {
    const { knobSize, knobMargin } = getSizeValues($size, convertVW);

    return css`
      position: absolute;
      top: 50%;
      transform: translateY(-50%)
        ${$isActive ? 'rotate(0deg)' : 'rotate(-5deg)'};
      width: ${knobSize};
      height: ${knobSize};
      border-radius: 50%;
      transition: all var(--transition-speed) var(--transition-timing);
      box-shadow: 0 ${convertVW(1)} ${convertVW(3)} rgba(0, 0, 0, 0.2);

      ${$isActive
        ? css`
            right: ${knobMargin};
          `
        : css`
            left: ${knobMargin};
          `}
      ${ToggleWrapper}:hover & {
        transform: translateY(-50%)
          ${$isActive ? 'rotate(5deg)' : 'rotate(5deg)'};
        box-shadow: 0 ${convertVW(2)} ${convertVW(4)} rgba(0, 0, 0, 0.3);
      }

      ${ToggleWrapper}:active & {
        transform: translateY(-50%)
          ${$isActive ? 'rotate(0deg)' : 'rotate(0deg)'} scale(0.95);
      }
    `;
  }}
`;

export { ToggleWrapper as Wrapper, ToggleKnob as Knob };
