/** Dependencies */
import React, { FC, useEffect, useState } from 'react';

/** Components */
import Select from 'components/Select/Select';

/** Types */
import { ITag } from 'features/Header/types';
import { ISortItem } from 'store/slices/Sort.slice';

/** Styles */
import * as S from 'features/Tags/Tags.styled';

interface IProps {
  title: string;
  placeholder: string;
  tags: ITag[];
  onSelect?: (selectedTags: ITag[]) => void;
}

const Tags: FC<IProps> = ({ title, placeholder, tags, onSelect }) => {
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);

  const transformedTags: ISortItem[] = [
    { key: '0', order: '0', text: 'All' },
    ...tags.map((tag) => ({
      key: tag.id.toString(),
      order: tag.id.toString(),
      text: tag.text,
    })),
  ];

  const selectedTagsAsSort: ISortItem[] = selectedTags.map((tag) => ({
    key: tag.id.toString(),
    order: tag.id.toString(),
    text: tag.text,
  }));

  const handleSelectChange = (
    sortItem: ISortItem,
    isRemoving: boolean
  ): void => {
    // Convert back from ISortItem to ITag format
    const tagId = parseInt(sortItem.key);
    const tagText = sortItem.text;

    // If it's the All option
    if (tagText?.toLowerCase() === 'all') {
      setSelectedTags([]);
      return;
    }

    // Create ITag from the sort item data
    const tag: ITag = { id: tagId, text: tagText };

    if (isRemoving) {
      setSelectedTags((prevTags) => prevTags.filter((t) => t.id !== tagId));
    } else {
      // Remove 'All' tag if it exists and add the new tag
      const newSelectedTags = selectedTags.filter(
        (t) => t?.text?.toLowerCase() !== 'all'
      );
      setSelectedTags([...newSelectedTags, tag]);
    }
  };

  useEffect(() => {
    onSelect?.(selectedTags);
  }, [selectedTags, onSelect]);

  return (
    <S.Wrapper>
      <S.Label>{title}</S.Label>
      <Select
        placeholder={placeholder}
        list={transformedTags}
        onChange={handleSelectChange}
        selectedValue={selectedTagsAsSort.length ? selectedTagsAsSort : []}
        isSelectedValuesShown={false}
        isArrowShown
        isMultiple={true}
      />
    </S.Wrapper>
  );
};

export default Tags;
