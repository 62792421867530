import styled, { css } from 'styled-components';
import { BreakPoint } from 'constants/BreakPoint';
import { SPACING } from 'constants/Spacing';

const SortButtonWrapper = styled.div`
  ${({ theme: { convertVW, text, border } }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${convertVW(SPACING.xl)};
    width: ${convertVW(200)};
    cursor: pointer;
    transition: transform var(--transition-speed-fast) var(--transition-timing);

    &:hover {
      transform: translateY(-1px);
    }

    &:active {
      transform: translateY(1px);
    }

    input {
      background: var(--card);
      transition: all var(--transition-speed-fast) var(--transition-timing);
      border-color: ${border};
      color: ${text};
    }

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      gap: ${convertVW(SPACING.sm)};
    }
  `}
`;

export { SortButtonWrapper as Wrapper };
