import styled, { css, RuleSet } from 'styled-components';
import { HTMLInputTypeAttribute } from 'react';
import { SPACING } from 'constants/Spacing';

const InputWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: ${convertVW(SPACING.input.gap)};
    width: 100%;
    user-select: none;
  `}
`;

const returnTextStyle = (
  convertVW: any,
  type: HTMLInputTypeAttribute | undefined,
  isError?: boolean,
  theme?: any
): RuleSet<object> => css`
  position: relative;
  width: 100%;
  min-height: ${convertVW(46)};
  background: ${theme?.card || '#ffffff'};
  border: ${convertVW(1)} solid
    ${isError ? theme?.error || 'red' : theme?.border || '#e4e4e7'};
  border-radius: ${convertVW(SPACING.xs * 2)}; /* 8px */
  padding: ${convertVW(SPACING.input.padding.vertical)}
    ${convertVW(SPACING.input.padding.horizontal)};
  outline: none;
  pointer-events: ${type === 'select' ? `none` : `unset`};
  transition: all var(--transition-speed-fast) var(--transition-timing);
  box-shadow: 0 0 0 transparent;

  font-family: Plus Jakarta Sans, sans-serif;
  font-size: ${convertVW(14)};
  line-height: ${convertVW(20)};
  color: ${theme?.text || 'rgba(10, 26, 37, 0.75)'};

  &:focus {
    border-color: ${theme?.button || '#4A5878'};
    box-shadow: 0 0 0 ${convertVW(2)} ${theme?.button}40; /* 40 is hex for 25% opacity */
  }
`;

const InputText = styled.input<{ $isError?: boolean }>`
  ${({ theme, theme: { convertVW }, type, $isError = false }) => css`
    position: relative;

    &[type='file']::file-selector-button {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    &::placeholder {
      color: ${theme.secondaryText || '#959595'};
      opacity: ${theme.background === '#121212' ||
      theme.background === '#343434'
        ? 0.7
        : 0.5};
      transition: opacity 0.2s ease;
    }

    &:focus::placeholder {
      opacity: 0.3;
    }

    ${returnTextStyle(convertVW, type, $isError, theme)}
  `}
`;

const InputTextArea = styled.textarea<{ $isError?: boolean }>`
  ${({ theme, theme: { convertVW }, $isError = false }) => css`
    position: relative;

    &[type='file']::file-selector-button {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    &::placeholder {
      color: ${theme.secondaryText || '#959595'};
      opacity: ${theme.background === '#121212' ||
      theme.background === '#343434'
        ? 0.7
        : 0.5};
      transition: opacity 0.2s ease;
    }

    &:focus::placeholder {
      opacity: 0.3;
    }

    ${returnTextStyle(convertVW, undefined, $isError, theme)}
  `}
`;

const InputMultiSelect = styled.div`
  ${({ theme, theme: { convertVW } }) =>
    returnTextStyle(convertVW, 'multi-select', false, theme)}
`;

const InputMultiSelectObjects = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    top: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: ${convertVW(SPACING.md)};
    width: calc(100% - ${convertVW(SPACING.input.padding.horizontal * 2)});
    overflow: auto;
    transform: translateY(-50%);

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

const InputMultiSelectObject = styled.div`
  ${({ theme: { convertVW, border, secondaryText } }) => css`
    display: flex;
    align-items: flex-start;
    padding: ${convertVW(SPACING.xxs + 1)} ${convertVW(SPACING.xs + 1)};
    gap: ${convertVW(SPACING.xs + 2)};
    background: transparent;
    border: ${convertVW(1)} solid ${border};
    border-radius: ${convertVW(SPACING.xs)};
    width: fit-content;
    cursor: pointer;
    transition: all var(--transition-speed-fast) var(--transition-timing);

    font-family: Plus Jakarta Sans, sans-serif;
    font-weight: 300;
    font-size: ${convertVW(18)};
    line-height: ${convertVW(24)};
    letter-spacing: ${convertVW(0.36)};
    color: ${secondaryText};
    white-space: nowrap;

    &:hover {
      border-color: ${secondaryText};
    }
  `}
`;

const InputMultiSelectObjectDelete = styled.div`
  ${({ theme: { secondaryText } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    font-weight: 700;
    color: ${secondaryText};
  `}
`;

const InputLabel = styled.label`
  ${({ theme: { convertVW, text } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(18)};
    line-height: ${convertVW(25)};
    color: ${text};
    margin-bottom: ${convertVW(SPACING.xs)};
    font-weight: 500;
  `}
`;

const InputIcon = styled.img`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    right: ${convertVW(SPACING.sm + 4)};
    bottom: ${convertVW(SPACING.sm + 5)};
    height: ${convertVW(20)};
    transition: transform var(--transition-speed-fast) var(--transition-timing);

    &:hover {
      transform: scale(1.1);
    }
  `}
`;

const InputPlaceholder = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(14)};
    line-height: ${convertVW(30)};
    color: var(--text);
  `}
`;

export {
  InputWrapper as Wrapper,
  InputText as Text,
  InputTextArea as TextArea,
  InputMultiSelect as MultiSelect,
  InputMultiSelectObjects as MultiSelectObjects,
  InputMultiSelectObject as MultiSelectObject,
  InputMultiSelectObjectDelete as MultiSelectObjectDelete,
  InputLabel as Label,
  InputPlaceholder as Placeholder,
  InputIcon as Icon,
};
