/** Dependencies */
import React, { FC, ReactElement, useRef } from 'react';
import { createPortal } from 'react-dom';

/** Features */
import ModalContentRenderer from 'features/ModalContentRenderer/ModalContentRenderer';

/** Hooks */
import { useOnClickOutside } from 'hooks/UseOnClickOutside';
import { useAppSelector } from 'hooks/UseAppSelector';
import { useAppDispatch } from 'hooks/UseAppDispatch';

/** Store */
import { hideModal, selectModal } from 'store/slices/Modal.slice';

/** Styles */
import * as S from './Modal.styled';

const Modal: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { isVisible, content } = useAppSelector(selectModal);

  const handleClose = (): void => {
    dispatch(hideModal());
  };

  useOnClickOutside(ref, () => {
    handleClose();
  });

  const returnModal = (): ReactElement => {
    return (
      <S.Overlay>
        <div ref={ref}>
          <ModalContentRenderer content={content} />
        </div>
      </S.Overlay>
    );
  };

  return isVisible
    ? createPortal(
        returnModal(),
        document.getElementById('modal-container') as HTMLElement
      )
    : null;
};

export default Modal;
