/** Dependencies */
import React, { FC, KeyboardEvent } from 'react';

/** Styles */
import * as S from './Toggle.styled';
import { ToggleSize } from './Toggle.styled';

interface IProps {
  isActive: boolean;
  knobBackground: string;
  background: string;
  onClick: () => void;
  ariaLabel?: string;
  size?: ToggleSize;
}

const Toggle: FC<IProps> = ({
  isActive,
  knobBackground,
  background,
  onClick,
  ariaLabel = isActive ? 'Switch to light mode' : 'Switch to dark mode',
  size = 'md',
}) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <S.Wrapper
      $background={background}
      $size={size}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      role="switch"
      aria-checked={isActive}
      tabIndex={0}
      aria-label={ariaLabel}
    >
      <S.Knob src={knobBackground} $isActive={isActive} $size={size} />
    </S.Wrapper>
  );
};

export default Toggle;
