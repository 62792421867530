import styled, { css, keyframes } from 'styled-components';
import { BreakPoint } from 'constants/BreakPoint';

const PageWrapper = styled.div<{
  $columnCount: number;
}>`
  ${({ theme: { convertVW }, $columnCount }) => css`
    position: relative;
    display: grid;
    grid-template-columns: repeat(${$columnCount}, 1fr);
    grid-auto-flow: dense;
    gap: ${convertVW(36 - $columnCount * 4)};
    padding: ${convertVW(32)} ${convertVW(40)};

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      grid-template-columns: repeat(1, 1fr);
      padding: ${convertVW(32)} ${convertVW(20)} ${convertVW(32)};
    }
  `}
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
`;

const LoaderItem = styled.div`
  ${({ theme }) => css`
    width: 30px;
    height: 30px;
    border: 3px solid ${theme.border || '#E4E4E7'};
    border-top: 3px solid ${theme.button || '#4A5878'};
    border-radius: 50%;
    animation: ${spin} 1s linear infinite;
  `}
`;

export {
  PageWrapper as Wrapper,
  LoaderContainer as LoaderWrapper,
  LoaderItem as Loader,
};
