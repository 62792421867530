/** Dependencies */
import { useEffect, useState } from 'react';

/** Api */
import { getRequest } from 'api/apiClient';

/** Components */
import { ICard } from 'components/Card/types';

/** Hooks */
import { useDesignFilters } from 'hooks/UseDesignFilters';
import { useDesignLikes } from 'hooks/UseDesignLikes';

/** Utilities */
import { flattenResponse } from 'utilities/functions';

interface IUseDesignsReturn {
  designs: ICard[];
  isPageLoading: boolean;
  getDesigns: () => Promise<void>;
  getLikes: () => Promise<void>;
  loadMoreDesigns: () => Promise<void>;
  hasMoreData: boolean;
  loadingMore: boolean;
}

interface IUseDesignsProps {
  endpoint: string;
  isSavedPage?: boolean;
  isArchivedPage?: boolean;
  itemsPerPage?: number;
}

export const useDesigns = ({
  endpoint,
  isSavedPage = false,
  isArchivedPage = false,
  itemsPerPage = 10,
}: IUseDesignsProps): IUseDesignsReturn => {
  const [designs, setDesigns] = useState<ICard[]>([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const { likes, getLikes, isFetchLikesLoading } = useDesignLikes();

  const { returnFilters } = useDesignFilters({ likes, isSavedPage });

  // Helper function to construct the API URL with correct pagination parameters
  const constructApiUrl = (pageNum: number): string => {
    if (isArchivedPage) {
      return endpoint;
    }

    // Check if the endpoint already has query parameters
    const separator = endpoint.includes('?') ? '&' : '?';

    // Construct the pagination part following Strapi pattern
    const paginationParams = `pagination[page]=${pageNum}&pagination[pageSize]=${itemsPerPage}`;

    // Combine the endpoint with filters and pagination
    const filters = returnFilters().startsWith('&')
      ? returnFilters().substring(1) // Remove leading & if present
      : returnFilters();

    // Build the complete URL with proper separators
    let url = `${endpoint}${separator}${paginationParams}`;

    // Add filters if any
    if (filters) {
      url += `&${filters}`;
    }

    return url;
  };

  const getDesigns = async (): Promise<void> => {
    setIsPageLoading(true);
    setPage(1); // Reset to first page

    try {
      const apiUrl = constructApiUrl(1);

      const response = await getRequest(apiUrl);

      // Process the returned data
      const responseData = response.data?.data || [];
      const newDesigns = flattenResponse(responseData);

      setDesigns(newDesigns);

      // Check if there are more items to load
      if (response.data?.meta?.pagination) {
        const { page: currentPage, pageCount } = response.data.meta.pagination;
        setHasMoreData(currentPage < pageCount);
      } else {
        // Fallback if no pagination meta is available
        setHasMoreData(newDesigns.length === itemsPerPage);
      }
    } catch (error) {
      console.error('Error fetching designs:', error);
      setDesigns([]);
      setHasMoreData(false);
    } finally {
      setIsPageLoading(false);
    }
  };

  const loadMoreDesigns = async (): Promise<void> => {
    if (loadingMore || !hasMoreData) return;

    setLoadingMore(true);
    const nextPage = page + 1;

    try {
      const apiUrl = constructApiUrl(nextPage);

      const response = await getRequest(apiUrl);

      // Process the returned data
      const responseData = response.data?.data || [];
      const newDesigns = flattenResponse(responseData);

      if (newDesigns.length > 0) {
        setDesigns((prevDesigns) => [...prevDesigns, ...newDesigns]);
        setPage(nextPage);

        // Check if there are more items to load
        if (response.data?.meta?.pagination) {
          const { page: currentPage, pageCount } =
            response.data.meta.pagination;
          setHasMoreData(currentPage < pageCount);
        } else {
          // Fallback if no pagination meta is available
          setHasMoreData(newDesigns.length === itemsPerPage);
        }
      } else {
        setHasMoreData(false);
      }
    } catch (error) {
      console.error('Error fetching more designs:', error);
      setHasMoreData(false);
    } finally {
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    !isFetchLikesLoading && void getDesigns();
  }, [location.pathname, isFetchLikesLoading, returnFilters]);

  return {
    designs,
    isPageLoading,
    getDesigns,
    getLikes,
    loadMoreDesigns,
    hasMoreData,
    loadingMore,
  };
};
