/**
 * Spacing constants for consistent layout throughout the application
 * These are base values that should be used with convertVW for responsive design
 */

// Base spacing unit (4px)
export const BASE_SPACING = 4;

// Spacing scale (multiples of BASE_SPACING)
export const SPACING = {
  // Smaller values for tight spacing
  xxs: BASE_SPACING * 0.5, // 2px
  xs: BASE_SPACING, // 4px
  sm: BASE_SPACING * 2, // 8px

  // Medium values for standard spacing
  md: BASE_SPACING * 3, // 12px
  lg: BASE_SPACING * 4, // 16px
  xl: BASE_SPACING * 6, // 24px

  // Larger values for section spacing
  xxl: BASE_SPACING * 8, // 32px
  xxxl: BASE_SPACING * 12, // 48px

  // Component-specific spacing
  input: {
    padding: {
      vertical: BASE_SPACING * 3, // 12px
      horizontal: BASE_SPACING * 4, // 16px
    },
    gap: BASE_SPACING * 2, // 8px
  },

  card: {
    padding: BASE_SPACING * 6, // 24px
    gap: BASE_SPACING * 4, // 16px
  },

  sidebar: {
    padding: BASE_SPACING * 6, // 24px
    itemGap: BASE_SPACING * 4, // 16px
  },

  header: {
    padding: {
      top: BASE_SPACING * 6, // 24px
      bottom: BASE_SPACING * 2, // 8px
      horizontal: BASE_SPACING * 10, // 40px
    },
    gap: BASE_SPACING * 4, // 16px
  },
};

/**
 * Helper function to get spacing value with specified multiplier
 * @param size Base spacing size from SPACING object
 * @param multiplier Optional multiplier for the spacing
 * @returns The calculated spacing value
 */
export const getSpacing = (size: number, multiplier = 1): number => {
  return size * multiplier;
};
