import styled, { css, keyframes } from 'styled-components';

// Define fade-in animation
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Admin Action Icon Container
export const AdminIconContainer = styled.div<{
  $columnCount: number;
}>`
  ${({ theme: { convertVW }, $columnCount }) => css`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${convertVW((10 - $columnCount) * 2)};
    width: 100%;
    z-index: 2;
    transition: all var(--transition-speed-fast) var(--transition-timing);
    animation: ${fadeIn} 0.3s ease-out forwards;
    top: 50%;
    left: 0;
  `}
`;

// Admin Action Icon
export const Icon = styled.img<{
  $columnCount: number;
  $isVisible: boolean;
}>`
  ${({ theme: { convertVW }, $columnCount, $isVisible }) => css`
    justify-content: center;
    align-items: center;
    width: ${convertVW((10 - $columnCount) * 6)};
    height: ${convertVW((10 - $columnCount) * 6)};
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    padding: ${convertVW(10 - $columnCount)};
    cursor: pointer;
    transition: all var(--transition-speed-fast) var(--transition-timing);
    box-shadow: 0 ${convertVW((10 - $columnCount) / 3)}
      ${convertVW((10 - $columnCount) / 1.5)} rgba(0, 0, 0, 0.1);

    ${$isVisible
      ? css`
          display: inline-block;
        `
      : css`
          display: none;
        `};

    &:hover {
      transform: scale(1.1);
      background-color: rgba(255, 255, 255, 0.95);
      box-shadow: 0 ${convertVW((10 - $columnCount) / 2)}
        ${convertVW(10 - $columnCount)} rgba(0, 0, 0, 0.15);
    }

    &:active {
      transform: scale(0.9);
      box-shadow: 0 ${convertVW((10 - $columnCount) / 6)}
        ${convertVW((10 - $columnCount) / 3)} rgba(0, 0, 0, 0.1);
    }

    /* Improve focus for accessibility */

    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 ${convertVW((10 - $columnCount) / 3)} var(--button),
        0 ${convertVW((10 - $columnCount) / 2)} ${convertVW(10 - $columnCount)}
          rgba(0, 0, 0, 0.2);
    }

    /* Style variants for admin action icons */

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    @media (max-width: 768px) {
      width: ${convertVW((10 - $columnCount) * 5)};
      height: ${convertVW((10 - $columnCount) * 5)};
    }
  `}
`;
