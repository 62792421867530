/** Dependencies */
import React, { ButtonHTMLAttributes, FC } from 'react';

/** Styles */
import * as S from './Button.styled';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'warning'
  | 'info';
export type ButtonSize = 'small' | 'medium' | 'large';

interface IProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  altKey?: string;
  text?: string;
  icon?: string;
  bgColor?: string;
  isOutline?: boolean;
  isDisabled?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  fullWidth?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: FC<IProps> = ({
  altKey,
  text,
  icon,
  bgColor,
  isOutline = false,
  isDisabled = false,
  variant = 'primary',
  size = 'medium',
  fullWidth = true,
  onClick,
  ...restProps
}) => {
  return (
    <S.Wrapper
      $isDisabled={isDisabled}
      $isRounded={!text}
      onClick={onClick}
      $isOutline={isOutline}
      $bgColor={bgColor}
      $variant={variant}
      $size={size}
      $fullWidth={fullWidth}
      disabled={isDisabled}
      type="button"
      {...restProps}
    >
      {text && <S.Text>{text}</S.Text>}
      {icon && <S.Icon src={icon} alt={altKey || 'button icon'} />}
    </S.Wrapper>
  );
};

export default Button;
