/** Dependencies */
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

/** Api */
import { getRequest, postRequest, putRequest } from 'api/apiClient';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

/** Utilities */
import {
  capitalizeFirstLetters,
  flattenResponse,
  toastOptions,
} from 'utilities/functions';

/** Store */
import { setIsCountsRequestTriggered } from 'store/slices/Sidebar.slice';

/** Hooks */
import { useAppDispatch } from 'hooks/UseAppDispatch';

interface IUseDesignApproveReturn {
  directApprove: (message?: string) => Promise<void>;
  directDisapprove: (message?: string) => Promise<void>;
  directSuspend: () => Promise<void>;
  directArchive: () => Promise<void>;
  directUnarchive: () => Promise<void>;
  isLoading: boolean;
}

interface IUseDesignApproveProps {
  id: string;
  getDesigns: () => Promise<void>;
  getLikes: () => Promise<void>;
  onFinish?: () => void;
  isArchivedCard: boolean;
}

export const useDesignApprove = ({
  id,
  getDesigns,
  getLikes,
  onFinish,
  isArchivedCard,
}: IUseDesignApproveProps): IUseDesignApproveReturn => {
  const dispatch = useAppDispatch();
  const [design, setDesign] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const alterDesignerTotalBalance = async (): Promise<void> => {
    await putRequest(
      ENDPOINTS.DESIGNER_USER_WITH_ID(design.designerUser.documentId),
      {
        totalBalance:
          design.designerUser.totalBalance + design.designerUser.fixedFee,
      }
    );
  };

  const createTransaction = async (): Promise<void> => {
    await postRequest(ENDPOINTS.TRANSACTIONS, {
      designerUser: design?.designerUser.documentId,
      balanceDelta: design?.designerUser.fixedFee,
      totalBalance:
        design?.designerUser.totalBalance + design?.designerUser.fixedFee,
    });
  };

  const postProcessing = async (actionType: string): Promise<void> => {
    if (actionType === 'approve') {
      await alterDesignerTotalBalance();
      await createTransaction();
    }

    await getDesigns();
    await getLikes();

    dispatch(setIsCountsRequestTriggered(true));
    onFinish?.();

    toast(
      `${capitalizeFirstLetters(actionType)} process successful`,
      toastOptions('👏')
    );
  };

  useEffect(() => {
    if (!isArchivedCard) {
      void getRequest(ENDPOINTS.DESIGN(id))
        .then((response) => {
          setDesign(flattenResponse([response.data.data])[0]);
        })
        .catch((err) => console.error(err));
    }
  }, [id, isArchivedCard]);

  // Direct action methods without modal confirmations
  const directApprove = async (message = 'Design approved'): Promise<void> => {
    try {
      setIsLoading(true);
      await putRequest(ENDPOINTS.DESIGN(id), {
        isApproved: true,
        message,
      });
      await postProcessing('approve');
    } catch (error) {
      console.error('Error approving design:', error);
      toast.error(
        'Failed to approve design. Please try again.',
        toastOptions('❌')
      );
    } finally {
      setIsLoading(false);
    }
  };

  const directDisapprove = async (
    message = 'Design disapproved'
  ): Promise<void> => {
    try {
      setIsLoading(true);
      await putRequest(ENDPOINTS.DESIGN(id), {
        isApproved: false,
        message,
      });
      await postProcessing('disapprove');
    } catch (error) {
      console.error('Error disapproving design:', error);
      toast.error(
        'Failed to disapprove design. Please try again.',
        toastOptions('❌')
      );
    } finally {
      setIsLoading(false);
    }
  };

  const directSuspend = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await putRequest(ENDPOINTS.DESIGN(id), {
        isApproved: null,
        message: 'Design suspended',
      });
      await postProcessing('suspend');
    } catch (error) {
      console.error('Error suspending design:', error);
      toast.error(
        'Failed to suspend design. Please try again.',
        toastOptions('❌')
      );
    } finally {
      setIsLoading(false);
    }
  };

  const directArchive = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await postRequest(
        ENDPOINTS.ARCHIVE_DESIGN,
        {
          documentId: id,
        },
        undefined,
        true
      );
      await postProcessing('archive');
    } catch (error) {
      console.error('Error archiving design:', error);
      toast.error(
        'Failed to archive design. Please try again.',
        toastOptions('❌')
      );
    } finally {
      setIsLoading(false);
    }
  };

  const directUnarchive = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await postRequest(
        ENDPOINTS.UNARCHIVE_DESIGN,
        {
          documentId: id,
        },
        undefined,
        true
      );
      await postProcessing('unarchive');
    } catch (error) {
      console.error('Error unarchiving design:', error);
      toast.error(
        'Failed to unarchive design. Please try again.',
        toastOptions('❌')
      );
    } finally {
      setIsLoading(false);
    }
  };

  return {
    directApprove,
    directDisapprove,
    directSuspend,
    directArchive,
    directUnarchive,
    isLoading,
  };
};
