import styled, { css } from 'styled-components';

const BubbleAnimation = css`
  @keyframes bubble {
    0% {
      transform: scale(0.85);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.85);
    }
  }
`;

const CircleAnimation = css`
  @keyframes circle {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    0% {
      transform: scale(1);
    }
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

const LoadingContent = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: relative;
    width: ${convertVW(150)};
    height: ${convertVW(190)};
  `}
`;

const LoadingArrow = styled.img`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    width: ${convertVW(106)};
    left: 0;
    bottom: 0;
  `}
`;

const LoadingShortBubbleContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    width: ${convertVW(67)};
    right: ${convertVW(47)};
    top: ${convertVW(19)};

    animation-name: bubble;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;

    ${BubbleAnimation}
  `}
`;

const LoadingShortBubble = styled.img`
  width: 100%;
`;

const LoadingLongBubbleContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    width: ${convertVW(83)};
    right: 0;
    top: ${convertVW(28.75)};

    animation-name: bubble;
    animation-duration: 1s;
    animation-iteration-count: infinite;

    ${BubbleAnimation}
  `}
`;

const LoadingLongBubble = styled.img`
  width: 100%;
`;

const LoadingBigCircle = styled.img`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    width: ${convertVW(21)};
    right: ${convertVW(22.57)};
    top: 0;

    animation-name: circle;
    animation-duration: 1.25s;
    animation-iteration-count: infinite;

    ${CircleAnimation}
  `}
`;

const LoadingMediumCircle = styled.img`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    width: ${convertVW(10)};
    right: ${convertVW(10.03)};
    top: ${convertVW(74.2)};

    animation-name: circle;
    animation-duration: 1.75s;
    animation-iteration-count: infinite;

    ${CircleAnimation}
  `}
`;

const LoadingSmallCircle = styled.img`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    width: ${convertVW(7)};
    left: ${convertVW(42.86)};
    top: ${convertVW(39.57)};

    animation-name: circle;
    animation-duration: 0.75s;
    animation-iteration-count: infinite;

    ${CircleAnimation}
  `}
`;

const LoadingMessageText = styled.p`
  ${({ theme: { convertVW } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(16)};
    font-weight: 500;
    margin-top: ${convertVW(30)};
    text-align: center;
    color: ${({ theme }) => theme.text};
    transition: color 0.3s ease;
  `}
`;

const LoadingProgressContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    width: ${convertVW(300)};
    height: ${convertVW(6)};
    background-color: ${({ theme }) => theme.cardBorder};
    border-radius: ${convertVW(3)};
    margin-top: ${convertVW(20)};
    overflow: hidden;
    position: relative;
  `}
`;

const LoadingProgressBar = styled.div<{ width: number }>`
  ${({ width, theme: { convertVW } }) => css`
    height: 100%;
    width: ${width}%;
    background-color: ${({ theme }) => theme.button};
    border-radius: ${convertVW(3)};
    transition: width 0.3s ease-out;
  `}
`;

export {
  LoadingWrapper as Wrapper,
  LoadingContent as Content,
  LoadingArrow as Arrow,
  LoadingShortBubbleContainer as ShortBubbleContainer,
  LoadingShortBubble as ShortBubble,
  LoadingLongBubbleContainer as LongBubbleContainer,
  LoadingLongBubble as LongBubble,
  LoadingBigCircle as BigCircle,
  LoadingMediumCircle as MediumCircle,
  LoadingSmallCircle as SmallCircle,
  LoadingMessageText as MessageText,
  LoadingProgressContainer as ProgressContainer,
  LoadingProgressBar as ProgressBar,
};
