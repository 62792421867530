/** Dependencies */
import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/** Types */
import { IFlattenedItem } from 'features/Sidebar/types';

/** Store */
import {
  selectAdminDesignCounts,
  selectDesignerDesignCounts,
} from 'store/slices/Sidebar.slice';
import { setHeaderTitle } from 'store/slices/Header.slice';

/** Hooks */
import { useAppDispatch } from 'hooks/UseAppDispatch';
import { useAppSelector } from 'hooks/UseAppSelector';
import { useTheme } from 'hooks/UseTheme';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

/** Styles */
import * as S from './Tab.styled';

interface IProps {
  tab: IFlattenedItem;
  isTabShownToUser: (tab: IFlattenedItem) => boolean;
}

const Tab: FC<IProps> = ({ tab, isTabShownToUser }) => {
  const { user, isUserDesigner, isUserAdmin } = useAuth();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isDarkMode } = useTheme();

  const adminDesignCounts = useAppSelector(selectAdminDesignCounts);
  const designerDesignCounts = useAppSelector(selectDesignerDesignCounts);

  const {
    approvedDesignsCount,
    disapprovedDesignsCount,
    pendingDesignsCount,
    archivedDesignsCount,
  } = {
    approvedDesignsCount: isUserAdmin
      ? adminDesignCounts.approvedDesignsCount
      : isUserDesigner
      ? designerDesignCounts.approvedDesignsCount
      : '',
    disapprovedDesignsCount: isUserAdmin
      ? adminDesignCounts.disapprovedDesignsCount
      : isUserDesigner
      ? designerDesignCounts.disapprovedDesignsCount
      : '',
    pendingDesignsCount: isUserAdmin
      ? adminDesignCounts.pendingDesignsCount
      : isUserDesigner
      ? designerDesignCounts.pendingDesignsCount
      : '',
    archivedDesignsCount: isUserAdmin
      ? adminDesignCounts.archivedDesignsCount
      : '',
  };

  const returnDesignCount = (text: string): number | string => {
    if (text.includes('Approved')) {
      return approvedDesignsCount;
    } else if (text.includes('Disapproved')) {
      return disapprovedDesignsCount;
    } else if (text.includes('Pending')) {
      return pendingDesignsCount;
    } else if (text.includes('Archive')) {
      return archivedDesignsCount ?? '';
    }
    return '';
  };

  useEffect(() => {
    if (location.pathname.includes('edit'))
      dispatch(setHeaderTitle('Edit Design'));
    if (location.pathname === tab.url) {
      dispatch(setHeaderTitle(tab.text));
    } else {
      tab?.children?.map(
        (child) =>
          location.pathname === child.url &&
          dispatch(setHeaderTitle(child.text))
      );
    }
  }, [location.pathname, user]);

  // Use the appropriate icon based on the current theme
  const getIconUrl = (): string => {
    if (isDarkMode) {
      return tab.icon?.url || tab.iconDark?.url || '';
    }
    return tab.iconDark?.url || tab.icon?.url || '';
  };

  return (
    <S.Wrapper>
      <S.Content
        to={tab.isAvailable ? tab.url : {}}
        $isActive={location.pathname === tab.url}
      >
        <S.IconWrapper>
          <S.Icon
            src={getIconUrl()}
            alt={tab.text}
            style={{
              filter: isDarkMode ? 'brightness(1)' : 'none',
              opacity: location.pathname === tab.url ? 1 : 0.8,
            }}
          />
        </S.IconWrapper>
        <S.Text $isActive={location.pathname === tab.url}>{tab.text}</S.Text>
        {!!returnDesignCount(tab.text) && (
          <S.Count>{returnDesignCount(tab.text)}</S.Count>
        )}
        {!tab.isAvailable && <S.ComingSoon>coming soon</S.ComingSoon>}
      </S.Content>
    </S.Wrapper>
  );
};

export default Tab;
