import styled, { css } from 'styled-components';
import { WIDTH } from 'constants/Style';
import { BreakPoint } from 'constants/BreakPoint';
import { SPACING } from 'constants/Spacing';

const AppWrapper = styled.div<{
  $isSidebarVisible: boolean;
  $isSidebarMaximized: boolean;
}>`
  ${({
    theme: { convertVW, background },
    $isSidebarVisible,
    $isSidebarMaximized,
  }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    background-color: ${background};
    transition: background-color var(--transition-speed)
        var(--transition-timing),
      padding-left var(--transition-speed-slow) var(--transition-timing);
    padding-left: ${!$isSidebarVisible
      ? 0
      : $isSidebarMaximized
      ? convertVW(WIDTH.sidebar)
      : convertVW(57)};
    overflow: hidden;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      padding-left: 0;
    }
  `}
`;

// PageTransition wrapper for route transitions
const PageTransition = styled.div`
  width: 100%;
  flex: 1;
  position: relative;
  animation: fadeIn 0.5s ease forwards;
  height: 100%;
  overflow: auto;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(${SPACING.xs}px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export { AppWrapper as Wrapper, PageTransition };
