import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/App.store';

interface IDesignCounts {
  approvedDesignsCount: number;
  disapprovedDesignsCount: number;
  pendingDesignsCount: number;
  archivedDesignsCount?: number;
}

interface ISidebarState {
  isSidebarVisible: boolean;
  isSidebarMaximized: boolean;
  isDark: boolean;
  useSystemTheme: boolean;
  adminDesignCounts: IDesignCounts;
  designerDesignCounts: IDesignCounts;
  isCountsRequestTriggered: boolean;
}

const loadThemeFromStorage = (): {
  isDark: boolean;
  useSystemTheme: boolean;
} => {
  try {
    const savedTheme = localStorage.getItem('themePreference');
    if (savedTheme) {
      return JSON.parse(savedTheme);
    }
  } catch (error) {
    console.error('Error loading theme from localStorage:', error);
  }

  return { isDark: false, useSystemTheme: true };
};

const { isDark, useSystemTheme } = loadThemeFromStorage();

const initialState: ISidebarState = {
  isSidebarVisible: false,
  isSidebarMaximized: true,
  isDark,
  useSystemTheme,
  adminDesignCounts: {
    approvedDesignsCount: 0,
    disapprovedDesignsCount: 0,
    pendingDesignsCount: 0,
    archivedDesignsCount: 0,
  },
  designerDesignCounts: {
    approvedDesignsCount: 0,
    disapprovedDesignsCount: 0,
    pendingDesignsCount: 0,
  },
  isCountsRequestTriggered: false,
};

const saveThemeToStorage = (
  darkMode: boolean,
  useSystemPref: boolean
): void => {
  try {
    localStorage.setItem(
      'themePreference',
      JSON.stringify({ isDark: darkMode, useSystemTheme: useSystemPref })
    );
  } catch (error) {
    console.error('Error saving theme to localStorage:', error);
  }
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setIsSidebarVisible: (state, action) => {
      state.isSidebarVisible = action.payload;
    },
    setIsSidebarMaximized: (state, action) => {
      state.isSidebarMaximized = action.payload;
    },
    setThemeIsDark: (state, action) => {
      state.isDark = action.payload;
      if (state.useSystemTheme) {
        state.useSystemTheme = false;
      }
      saveThemeToStorage(state.isDark, state.useSystemTheme);
    },
    setUseSystemTheme: (state, action) => {
      state.useSystemTheme = action.payload;
      saveThemeToStorage(state.isDark, state.useSystemTheme);
    },
    setAdminDesignCounts: (state, action) => {
      state.adminDesignCounts = action.payload;
    },
    setDesignerDesignCounts: (state, action) => {
      state.designerDesignCounts = action.payload;
    },
    setIsCountsRequestTriggered: (state, action) => {
      state.isCountsRequestTriggered = action.payload;
    },
  },
});

export const {
  setIsSidebarVisible,
  setIsSidebarMaximized,
  setThemeIsDark,
  setUseSystemTheme,
  setAdminDesignCounts,
  setDesignerDesignCounts,
  setIsCountsRequestTriggered,
} = sidebarSlice.actions;

export const selectIsSidebarVisible = (state: RootState): boolean =>
  state.sidebar.isSidebarVisible;

export const selectIsSidebarMaximized = (state: RootState): boolean =>
  state.sidebar.isSidebarMaximized;

export const selectThemeIsDark = (state: RootState): boolean =>
  state.sidebar.isDark;

export const selectUseSystemTheme = (state: RootState): boolean =>
  state.sidebar.useSystemTheme;

export const selectAdminDesignCounts = (state: RootState): IDesignCounts =>
  state.sidebar.adminDesignCounts;

export const selectDesignerDesignCounts = (state: RootState): IDesignCounts =>
  state.sidebar.designerDesignCounts;

export const selectIsCountsRequestTriggered = (state: RootState): boolean =>
  state.sidebar.isCountsRequestTriggered;

export default sidebarSlice.reducer;
