/** Dependencies */
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

/** Components */
import Card from 'components/Card/Card';
import Button from 'components/Button/Button';
import Select from 'components/Select/Select';
import PasteFigmaInput from 'components/PasteFigmaInput/PasteFigmaInput';
import Input from 'components/Input/Input';
import ToggleItem from 'components/ToggleItem/ToggleItem';

/** Images */
import UploadBackgroundImage from 'assets/images/svg/upload-background.svg';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

/** Hooks */
import { useAppDispatch } from 'hooks/UseAppDispatch';
import { useAppSelector } from 'hooks/UseAppSelector';

/** Store */
import { ISortItem } from 'store/slices/Sort.slice';
import { hideModal, selectModal, setModal } from 'store/slices/Modal.slice';
import { setIsCountsRequestTriggered } from 'store/slices/Sidebar.slice';

/** Api */
import { getRequest, postRequest, putRequest } from 'api/apiClient';

/** Utilities */
import {
  flattenResponse,
  returnDesignObject,
  toastOptions,
} from 'utilities/functions';

/** Constants */
import { ROUTE } from 'constants/Routes';
import { ENDPOINTS } from 'constants/EndPoints';
import { ModalType } from 'constants/ModalType';

/** Styles */
import * as S from './Upload.styled';
import Loading from 'components/Loading/Loading';

const Upload: FC = () => {
  const { user, isUserAdmin } = useAuth();
  const { id } = useParams();
  const { isConfirmed, content } = useAppSelector(selectModal);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isUploadDisabled, setIsUploadDisabled] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidationActive, setIsValidationActive] = useState(false);
  const [categories, setCategories] = useState<any[]>([]);
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [industries, setIndustries] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [emailTags, setEmailTags] = useState<any[]>([]);

  const [prevDesign, setPrevDesign] = useState<any>();
  const [design, setDesign] = useState<any>();
  const [selectedImage, setSelectedImage] = useState<File>();

  const getDesign = async (): Promise<void> => {
    setIsLoading(true);

    const requestLink = isUserAdmin
      ? `${ENDPOINTS.DESIGN(id ?? '')}`
      : `${ENDPOINTS.DESIGN(id ?? '')}${ENDPOINTS.MY_DESIGNS(user.Uid)}${
          ENDPOINTS.NOT_APPROVED_DESIGNS
        }`;

    const response = await getRequest(requestLink);

    const designResponse = flattenResponse(response.data.data);

    setPrevDesign(designResponse);
    setDesign(designResponse);
    setIsLoading(false);
  };

  const getBrands = async (): Promise<void> => {
    const brandsResponse = await getRequest(ENDPOINTS.BRANDS);

    setBrands(flattenResponse(brandsResponse.data.data));
  };

  const getEmailTags = async (): Promise<void> => {
    const emailTagsResponse = await getRequest(ENDPOINTS.EMAIL_TAGS);

    setEmailTags(flattenResponse(emailTagsResponse.data.data));
  };

  const getIndustries = async (): Promise<void> => {
    const industriesResponse = await getRequest(ENDPOINTS.INDUSTRIES);
    const all = { id: 0, documentId: 'all', text: 'All' };

    const industriesArray = [
      all,
      ...flattenResponse(industriesResponse.data.data),
    ];

    setIndustries(industriesArray);
  };

  const getCategories = async (): Promise<void> => {
    const categoriesResponse = await getRequest(ENDPOINTS.CATEGORIES);

    setCategories(
      flattenResponse(categoriesResponse.data.data).sort(
        (itemA, itemB) => itemB.id - itemA.id
      )
    );

    if (!id) {
      setDesign((oldDesign: any) => {
        return {
          ...oldDesign,
          category: flattenResponse(categoriesResponse.data.data)[1],
        };
      });
    }
  };

  const getSubCategories = async (): Promise<void> => {
    const subCategoriesResponse = await getRequest(
      ENDPOINTS.SUB_CATEGORIES_BY_CATEGORY(design?.category?.id)
    );

    setSubCategories(flattenResponse(subCategoriesResponse.data.data));
  };

  const getData = async (): Promise<void> => {
    setIsDataLoading(true);

    await getCategories();
    await getIndustries();
    await getBrands();
    await getEmailTags();

    setIsDataLoading(false);
  };

  const handleSelectSubCategories = (
    listItem: ISortItem,
    isRemoving: boolean
  ): void => {
    setDesign({
      ...design,
      subCategories: isRemoving
        ? design?.subCategories?.filter(
            (item: any) => item.text !== listItem?.text
          )
        : [...(design?.subCategories ?? []), listItem],
    });
  };

  const handleUploadImage = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedImage(event.target.files[0]);
      setDesign({
        ...design,
        image: {
          ...design.image,
          url: URL.createObjectURL(event.target.files[0]),
        },
      });
    }
  };

  const handleSubjectLineChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setDesign({ ...design, subjectLine: e.target.value });
  };

  const handleFigmaObjectChange = (object: string): void => {
    setDesign({ ...design, figmaObject: object });
  };

  const handleCanvaUrlChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setDesign({ ...design, canvaUrl: e.target.value });
  };

  const handleSelectIndustries = (
    listItem: ISortItem,
    isRemoving: boolean
  ): void => {
    if (listItem?.text === 'All') {
      setDesign({
        ...design,
        industries: isRemoving ? [] : [...industries],
      });
    } else {
      setDesign({
        ...design,
        industries: isRemoving
          ? design?.industries.filter(
              (item: any) => item.text !== listItem?.text && item.text !== 'All'
            )
          : [...(design?.industries ?? []), listItem],
      });
    }
  };

  const handleSelectBrand = (listItem: ISortItem): void => {
    setDesign({ ...design, brand: listItem });
  };

  const handleSelectEmailTags = (
    listItem: ISortItem,
    isRemoving: boolean
  ): void => {
    setDesign({
      ...design,
      emailTags: isRemoving
        ? design?.emailTags.filter((item: any) => item.text !== listItem?.text)
        : [...(design?.emailTags ?? []), listItem],
    });
  };

  const handleToggle = (category: any): void => {
    setDesign({ ...design, category, subCategories: [] });
  };

  const uploadImage = async (image: File): Promise<any> => {
    const formData = new FormData();

    formData.append('files', image);

    return await postRequest(ENDPOINTS.UPLOAD, null, formData);
  };

  const handleSubmit = async (): Promise<void> => {
    if ((selectedImage || id) && design?.figmaObject && design?.subjectLine) {
      setIsLoading(true);

      if (id) {
        if (isUploadDisabled)
          toast('You should make a change to update!', toastOptions());
        else {
          const imageResponse = selectedImage
            ? await uploadImage(selectedImage)
            : undefined;

          await putRequest(ENDPOINTS.DESIGN(id), {
            ...(selectedImage ? { image: imageResponse.data[0].id } : {}),
            ...returnDesignObject(design),
            designerUser: [design.designerUser.id],
            isApproved: null,
          });

          toast('Successfully updated', toastOptions('👏'));
          isUserAdmin
            ? navigate(ROUTE.PENDING_DESIGNS)
            : navigate(ROUTE.MY_PENDING_DESIGNS);
        }
      } else {
        const imageResponse =
          selectedImage && (await uploadImage(selectedImage));

        const userResponse = await getRequest(
          ENDPOINTS.DESIGNER_USER_WITH_UID(user.Uid)
        );

        await postRequest(ENDPOINTS.DESIGNS, {
          ...design,
          ...returnDesignObject(design),
          image: imageResponse.data[0].id,
          designerUser: [userResponse.data.data[0].id],
        });

        setIsValidationActive(false);
        toast('Successfully uploaded', toastOptions('👏'));
        setDesign({});
        setSelectedImage(undefined);
        navigate(ROUTE.MY_PENDING_DESIGNS);
      }

      !isUploadDisabled && dispatch(setIsCountsRequestTriggered(true));
      setIsLoading(false);
    } else {
      setIsValidationActive(true);
      toast('Please fill required fields!', toastOptions());
    }
  };

  const handleRemoveImage = (): void => {
    dispatch(
      setModal({
        isVisible: true,
        title: 'Remove Image',
        content: {
          type: ModalType.Remove,
          props: {
            text: 'You are about to remove image. Are you sure to remove it?',
          },
        },
      })
    );
  };

  const handleCardClick = (): void => {
    document.getElementById('Upload Preview Image')?.click();
  };

  useEffect(() => {
    void getData();
  }, []);

  useEffect(() => {
    design?.category?.id && void getSubCategories();
  }, [design?.category]);

  useEffect(() => {
    if (isUserAdmin !== null) {
      if (id) void getDesign();
      else setDesign(design?.category ? { category: design.category } : {});
    }
  }, [id, isUserAdmin]);

  useEffect(() => {
    setIsUploadDisabled(JSON.stringify(design) === JSON.stringify(prevDesign));
  }, [design]);

  useEffect(() => {
    if (isConfirmed && content?.type === ModalType.Remove) {
      setSelectedImage(undefined);
      setDesign({
        ...design,
        image: undefined,
      });
      dispatch(hideModal());
    }
  }, [isConfirmed, content?.type]);

  return (
    <S.Wrapper>
      {isDataLoading ? (
        <Loading showProgress />
      ) : (
        <>
          <S.Form>
            <S.ToggleContainer>
              {categories.map((category, index) => (
                <ToggleItem
                  key={index}
                  onClick={() => handleToggle(category)}
                  text={category.text}
                  isSelected={
                    design?.category?.documentId === category.documentId
                  }
                />
              ))}
            </S.ToggleContainer>
            <Select
              name={'Sub Category'}
              list={subCategories}
              placeholder={'Select sub categories'}
              onChange={handleSelectSubCategories}
              selectedValue={design?.subCategories ?? []}
              isMultiple
            />
            <Input
              name={'Upload Preview Image'}
              type={'file'}
              isError={isValidationActive}
              accept={'.jpg, .jpeg, .png, .svg'}
              onChange={handleUploadImage}
            />
            <Input
              name={'Subject Line'}
              defaultValue={design?.subjectLine ?? ''}
              isError={isValidationActive}
              placeholder={`The Mother's Day Gift Guide`}
              onChange={handleSubjectLineChange}
            />
            <PasteFigmaInput
              name={'Paste Figma Object'}
              isError={isValidationActive}
              onChange={handleFigmaObjectChange}
              value={design?.figmaObject ?? ''}
            />
            <Input
              name={'Canva Url'}
              defaultValue={design?.canvaUrl ?? ''}
              placeholder={`The Mother's Day Gift Guide`}
              onChange={handleCanvaUrlChange}
            />
            <Select
              name={'Industry'}
              list={industries}
              placeholder={'Select industries'}
              onChange={handleSelectIndustries}
              selectedValue={design?.industries ?? []}
              isMultiple
            />
            <Select
              name={'Brand'}
              list={brands}
              placeholder={'Select brand'}
              onChange={handleSelectBrand}
              selectedValue={design?.brand}
              isArrowShown
            />
            <Select
              name={'Email Tags (Add More Tag to Increase Views!)'}
              list={emailTags}
              placeholder={'Select sub email tags'}
              onChange={handleSelectEmailTags}
              selectedValue={design?.emailTags ?? []}
              isMultiple
            />
            <Button
              isDisabled={isLoading || isUploadDisabled}
              onClick={handleSubmit}
              text={
                isLoading
                  ? 'Loading...'
                  : id
                  ? 'UPDATE MY DESIGN'
                  : 'SUBMIT MY DESIGN'
              }
            />
          </S.Form>
          <S.CardContainer>
            <Card
              id={Number(id) ?? 1}
              documentId={id ?? ''}
              image={design?.image?.url ?? UploadBackgroundImage}
              title={design?.subjectLine ?? ''}
              figmaObject={design?.figmaObject ?? ''}
              onClick={design?.image?.url ? handleRemoveImage : handleCardClick}
              isUpload
            />
          </S.CardContainer>
        </>
      )}
    </S.Wrapper>
  );
};

export default Upload;
