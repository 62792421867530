import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const TabWrapper = styled.div`
  width: 100%;
`;

const TabContent = styled(Link)<{ $isActive: boolean }>`
  ${({ theme, $isActive }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${theme.convertVW(12)};
    width: 100%;
    padding: ${theme.convertVW(12)};
    height: ${theme.convertVW(44)};
    cursor: pointer;
    transition: all var(--transition-speed-fast) var(--transition-timing);
    background-color: ${$isActive ? 'var(--tab-active)' : 'var(--tab)'};
    border-radius: ${theme.convertVW(6)};
    position: relative;
    overflow: hidden;
    text-decoration: none;

    ${$isActive &&
    css`
      border-left: ${theme.convertVW(3)} solid var(--tab-border);
      padding-left: ${theme.convertVW(9)};
    `};

    &:hover {
      background-color: var(--tab-hover);
    }
  `}
`;

const TabText = styled.div<{ $isActive: boolean }>`
  ${({ theme, $isActive }) => css`
    overflow: hidden;
    transition: color var(--transition-speed-fast) var(--transition-timing);

    font-family: 'DM Sans', sans-serif;
    font-size: ${theme.convertVW(16)};
    line-height: ${theme.convertVW(19)};
    color: ${$isActive ? 'var(--tab-text-active)' : 'var(--tab-text)'};
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

const TabIconWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${theme.convertVW(20)};
    height: ${theme.convertVW(24)};
  `}
`;

const TabIcon = styled.img`
  ${({ theme }) => css`
    width: ${theme.convertVW(20)};
  `}
`;

const TabCount = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--button-disabled);
    min-width: ${theme.convertVW(20)};
    height: ${theme.convertVW(20)};
    padding: 0 ${theme.convertVW(6)};
    border-radius: ${theme.convertVW(10)};
    transition: background-color var(--transition-speed-fast)
      var(--transition-timing);

    font-family: 'DM Sans', sans-serif;
    font-size: ${theme.convertVW(12)};
    font-weight: 500;
    white-space: nowrap;
    color: var(--secondary-text);

    ${TabContent}:hover & {
      background-color: var(--border);
      color: var(--text);
    }
  `}
`;

const TabComingSoon = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--button-disabled);
    padding: 0 ${theme.convertVW(8)};
    border-radius: ${theme.convertVW(12)};
    transition: background-color var(--transition-speed-fast)
      var(--transition-timing);

    font-family: 'DM Sans', sans-serif;
    font-size: ${theme.convertVW(10)};
    line-height: ${theme.convertVW(16)};
    white-space: nowrap;
    color: var(--secondary-text);

    ${TabContent}:hover & {
      background-color: var(--border);
      color: var(--text);
    }
  `}
`;

export {
  TabWrapper as Wrapper,
  TabContent as Content,
  TabText as Text,
  TabIconWrapper as IconWrapper,
  TabIcon as Icon,
  TabCount as Count,
  TabComingSoon as ComingSoon,
};
