/** Dependencies */
import React, { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

/** Components */
import { SkeletonCardS } from 'components/SkeletonCard';
import AdminActions from 'components/AdminActions';

/** Hooks */
import { useAppSelector } from 'hooks/UseAppSelector';
import { useDesignLikes } from 'hooks/UseDesignLikes';
import { useAuth } from 'outseta/AuthProvider';

/** Store */
import { selectColumnCount } from 'store/slices/Grid.slice';
import { selectFilterCategory } from 'store/slices/Header.slice';

/** Constants */
import { ROUTE } from 'constants/Routes';

/** Images */
import InfoIcon from 'assets/images/svg/info-icon.svg';
import EditIcon from 'assets/images/svg/pencil.svg';
import FigmaIcon from 'assets/images/svg/figma.svg';
import LikeIcon from 'assets/images/svg/heart-icon.svg';
import LikedIcon from 'assets/images/svg/red-heart-icon.svg';
import LoadingIcon from 'assets/images/svg/classic-loading.svg';

/** Features */
import CopyToClipboardHTML from 'features/CopyToClipboard/CopyToClipboard';

/** Styles */
import * as S from './Card.styled';

interface IProps {
  id: number;
  documentId: string;
  title: string;
  image: string;
  figmaObject: string;
  likeCount?: number;
  isAdminCard?: boolean;
  isUpload?: boolean;
  onClick?: () => void;
  onCopy?: () => Promise<string | void>;
  refreshDesigns?: () => Promise<void>;
  refreshLikes?: () => Promise<void>;
}

const imageVisibilityStyle = (isDisplayed: boolean): React.CSSProperties =>
  isDisplayed ? { display: 'inline-block' } : { display: 'none' };

const Card: FC<IProps> = ({
  id,
  documentId,
  title,
  image,
  figmaObject,
  likeCount = 0,
  isAdminCard = false,
  isUpload = false,
  onClick = () => ({}),
  onCopy = async () => ({}),
  refreshDesigns = async () => Promise.resolve(),
  refreshLikes = async () => Promise.resolve(),
}) => {
  const navigate = useNavigate();
  const { likes, isLikeClickLoading, handleLikeClick } = useDesignLikes();
  const { isUserAdmin } = useAuth();

  const category = useAppSelector(selectFilterCategory);
  const columnCount = useAppSelector(selectColumnCount);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isCopied, setCopied] = useState(false);
  const [isCopyLoading, setIsCopyLoading] = useState(false);
  const [span, setSpan] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const getMeta = (url: any, cb: any): void => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  useEffect(() => {
    getMeta(image, (err: any, img: any): void => {
      setSpan(
        img?.naturalHeight / img?.naturalWidth === 1
          ? 9
          : img?.naturalHeight / img?.naturalWidth === 1350 / 1080
          ? 11
          : 16
      );
    });
  }, [image]);

  const isStaticAds = category?.text?.toLowerCase().includes('static ads');

  const isLiked = likes?.some((like) => like === id);

  const handleEditButtonClick = useCallback((): void => {
    navigate(ROUTE.EDIT(documentId));
  }, [navigate, documentId]);

  const notifyCopy = useCallback(
    async (e: MouseEvent<HTMLDivElement>): Promise<void> => {
      e.preventDefault();
      e.stopPropagation();

      if (!isCopyLoading && !isCopied) {
        setIsCopyLoading(true);

        try {
          const copyResponse = await onCopy?.();

          if (copyResponse !== 'update') {
            setCopied(true);
            setIsCopyLoading(false);
            toast('Successfully copied to clipboard', {
              duration: 2000,
              position: 'bottom-center',
              style: {
                background: '#2c2c2c',
                color: 'white',
              },
              icon: '👏',
            });
          }
        } catch (error) {
          toast.error('Failed to copy to clipboard', {
            duration: 2000,
            position: 'bottom-center',
          });
        }
      }
    },
    [onCopy, isCopyLoading, isCopied]
  );

  const handleLikeButtonClick = useCallback(
    (e: MouseEvent<HTMLImageElement>): void => {
      if (!isLikeClickLoading && !isAdminCard) {
        e.preventDefault();
        e.stopPropagation();
        void handleLikeClick(id);
      }
    },
    [isLikeClickLoading, isAdminCard, handleLikeClick, id]
  );

  const handleImageLoad = useCallback(() => setIsImageLoaded(true), []);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isCopied) {
      timeoutId = setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
    return () => clearTimeout(timeoutId);
  }, [isCopied]);

  // Format like count for display
  const formattedLikeCount =
    likeCount > 999 ? `${(likeCount / 1000).toFixed(1)}k` : likeCount;

  return (
    <S.Wrapper
      $columnCount={columnCount}
      $isStaticAds={isStaticAds}
      $span={Math.floor(span)}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {figmaObject && (
        <S.IconContainer $columnCount={columnCount} $isTop={false}>
          <CopyToClipboardHTML onCopy={notifyCopy} textToCopy={figmaObject}>
            <S.FigmaIconWrapper
              $columnCount={columnCount}
              className={isCopied ? 'copied-animation' : ''}
            >
              {isCopyLoading ? '' : isCopied ? 'Copied!' : 'Copy'}
              <S.Icon
                $columnCount={columnCount}
                src={isCopyLoading ? LoadingIcon : FigmaIcon}
                alt={'Copy to Figma'}
              />
            </S.FigmaIconWrapper>
          </CopyToClipboardHTML>
        </S.IconContainer>
      )}

      {!isUpload && (
        <S.IconContainer $columnCount={columnCount}>
          {isAdminCard ? (
            <>
              <S.LikeCount $columnCount={columnCount}>
                <img src={likeCount > 0 ? LikedIcon : LikeIcon} alt="Likes" />
                {formattedLikeCount}
              </S.LikeCount>
              <S.Icon
                $columnCount={columnCount}
                onClick={handleEditButtonClick}
                src={EditIcon}
                alt="Edit design"
                role="button"
                tabIndex={0}
                aria-label="Edit design"
              />
              <S.Icon
                $columnCount={columnCount}
                src={InfoIcon}
                alt="More information"
                role="button"
                tabIndex={0}
                aria-label="More information"
              />
            </>
          ) : (
            <S.Icon
              $columnCount={columnCount}
              onClick={handleLikeButtonClick}
              src={
                isLikeClickLoading
                  ? LoadingIcon
                  : isLiked
                  ? LikedIcon
                  : LikeIcon
              }
              alt={isLiked ? 'Unlike design' : 'Like design'}
              role="button"
              tabIndex={0}
              aria-pressed={isLiked}
              aria-label={isLiked ? 'Unlike design' : 'Like design'}
            />
          )}
        </S.IconContainer>
      )}

      <S.ImageWrapper $isStaticAds={isStaticAds} $columnCount={columnCount}>
        {!isImageLoaded && <SkeletonCardS.Image />}
        <S.Image
          src={image}
          alt={title}
          style={imageVisibilityStyle(isImageLoaded)}
          onLoad={handleImageLoad}
        />
      </S.ImageWrapper>

      {isUserAdmin && !isUpload && isHovered && (
        <AdminActions
          documentId={documentId}
          columnCount={columnCount}
          refreshDesigns={refreshDesigns}
          refreshLikes={refreshLikes}
        />
      )}
    </S.Wrapper>
  );
};

export default Card;
