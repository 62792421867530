/** Dependencies */
import React, { FC, useEffect, useState } from 'react';

/** Images */
import LightArrow from 'assets/images/svg/logoPieces/logo-arrow.svg';
import LightShortBubble from 'assets/images/svg/logoPieces/short-bubble.svg';
import LightLongBubble from 'assets/images/svg/logoPieces/long-bubble.svg';
import LightBigCircle from 'assets/images/svg/logoPieces/big-circle.svg';
import LightMediumCircle from 'assets/images/svg/logoPieces/medium-circle.svg';
import LightSmallCircle from 'assets/images/svg/logoPieces/small-circle.svg';
import DarkArrow from 'assets/images/svg/darkLogoPieces/logo-arrow.svg';
import DarkShortBubble from 'assets/images/svg/darkLogoPieces/short-bubble.svg';
import DarkLongBubble from 'assets/images/svg/darkLogoPieces/long-bubble.svg';
import DarkBigCircle from 'assets/images/svg/darkLogoPieces/big-circle.svg';
import DarkMediumCircle from 'assets/images/svg/darkLogoPieces/medium-circle.svg';
import DarkSmallCircle from 'assets/images/svg/darkLogoPieces/small-circle.svg';

/** Styles */
import * as S from './Loading.styled';
import { selectThemeIsDark } from 'store/slices/Sidebar.slice';
import { useAppSelector } from 'hooks/UseAppSelector';

interface LoadingProps {
  message?: string;
  showProgress?: boolean;
}

const loadingMessages = [
  'Preparing your experience...',
  'Loading content...',
  'Almost there...',
  'Just a moment...',
];

const Loading: FC<LoadingProps> = ({ message, showProgress = false }) => {
  const [progress, setProgress] = useState(0);
  const [currentMessage, setCurrentMessage] = useState(
    message || loadingMessages[0]
  );
  const isDark = useAppSelector(selectThemeIsDark);

  const images = {
    light: {
      Arrow: LightArrow,
      ShortBubble: LightShortBubble,
      LongBubble: LightLongBubble,
      BigCircle: LightBigCircle,
      MediumCircle: LightMediumCircle,
      SmallCircle: LightSmallCircle,
    },
    dark: {
      Arrow: DarkArrow,
      ShortBubble: DarkShortBubble,
      LongBubble: DarkLongBubble,
      BigCircle: DarkBigCircle,
      MediumCircle: DarkMediumCircle,
      SmallCircle: DarkSmallCircle,
    },
  };

  const themedImages = isDark ? images.dark : images.light;

  useEffect(() => {
    if (showProgress) {
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          }
          // Change message at specific progress points
          if (prev < 25 && prev + 2 >= 25) {
            setCurrentMessage(message || loadingMessages[1]);
          } else if (prev < 50 && prev + 2 >= 50) {
            setCurrentMessage(message || loadingMessages[2]);
          } else if (prev < 75 && prev + 2 >= 75) {
            setCurrentMessage(message || loadingMessages[3]);
          }
          return prev + 2;
        });
      }, 100);

      return () => clearInterval(interval);
    }
  }, [showProgress, message]);

  return (
    <S.Wrapper>
      <S.Content>
        <S.Arrow src={themedImages.Arrow} alt={'Arrow'} />
        <S.ShortBubbleContainer>
          <S.ShortBubble src={themedImages.ShortBubble} alt={'Short Bubble'} />
        </S.ShortBubbleContainer>
        <S.LongBubbleContainer>
          <S.LongBubble src={themedImages.LongBubble} alt={'Long Bubble'} />
        </S.LongBubbleContainer>
        <S.BigCircle src={themedImages.BigCircle} alt={'Big Circle'} />
        <S.MediumCircle src={themedImages.MediumCircle} alt={'Medium Circle'} />
        <S.SmallCircle src={themedImages.SmallCircle} alt={'Small Circle'} />
      </S.Content>

      <S.MessageText aria-live="polite">{currentMessage}</S.MessageText>

      {showProgress && (
        <S.ProgressContainer>
          <S.ProgressBar width={progress} />
        </S.ProgressContainer>
      )}
    </S.Wrapper>
  );
};

export default Loading;
