/** Dependencies */
import React, { FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/** Images */
import LogoImage from 'assets/images/svg/logo.svg';
import DarkLogoImage from 'assets/images/svg/dark/logo-dark.svg';
import DayBg from 'assets/images/svg/day-background.svg';
import NightBg from 'assets/images/svg/night-background.svg';
import DayIcon from 'assets/images/svg/day-icon.svg';
import NightIcon from 'assets/images/svg/night-icon.svg';
import UserImage from 'assets/images/svg/user.svg';
import HamburgerMenuImage from 'assets/images/svg/hamburger-menu.svg';

/** Hooks */
import { useAppSelector } from 'hooks/UseAppSelector';
import { useAppDispatch } from 'hooks/UseAppDispatch';
import { useDeviceDetect } from 'hooks/UseDeviceDetect';
import { useTheme } from 'hooks/UseTheme';

/** Components */
import Tab from 'components/Tab/Tab';
import Toggle from 'components/Toggle/Toggle';
import Profile from 'features/Profile/Profile';

/** Types */
import { IFlattenedItem } from 'features/Sidebar/types';

/** Store */
import {
  selectIsCountsRequestTriggered,
  selectIsSidebarMaximized,
  selectIsSidebarVisible,
  setAdminDesignCounts,
  setDesignerDesignCounts,
  setIsCountsRequestTriggered,
  setIsSidebarMaximized,
  setIsSidebarVisible,
} from 'store/slices/Sidebar.slice';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

/** Constants */
import { ROUTE } from 'constants/Routes';
import { ENDPOINTS } from 'constants/EndPoints';

/** Utilities */
import { flattenResponse } from 'utilities/functions';

/** Api */
import { getRequest } from 'api/apiClient';

/** Styles */
import * as S from './Sidebar.styled';

const Sidebar: FC = () => {
  const { isMobile } = useDeviceDetect();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user, isUserDesigner, isUserDefault, isUserAdmin } = useAuth();
  const { isDarkMode, toggleTheme } = useTheme();
  const sidebarRef = useRef<HTMLDivElement>(null);

  const isVisible = useAppSelector(selectIsSidebarVisible);
  const isMaximized = useAppSelector(selectIsSidebarMaximized);
  const isCountsRequestTriggered = useAppSelector(
    selectIsCountsRequestTriggered
  );
  const [sidebarItems, setSidebarItems] = useState<IFlattenedItem[]>();

  const isTabShownToUser = (tab: IFlattenedItem): boolean =>
    (!!isUserDesigner && tab.isForDesigners) ||
    (!!isUserDefault && tab.isForUsers) ||
    (!!isUserAdmin && !tab.isForDesigners && !tab.isForUsers);

  const getSidebarItems = async (): Promise<void> => {
    const response = await getRequest(ENDPOINTS.SIDE_MENU_ITEMS);
    setSidebarItems(flattenResponse(response.data.data));
  };

  const getCounts = async (): Promise<void> => {
    if (isUserAdmin || isUserDesigner) {
      const approvedResponse = await getRequest(
        `${
          isUserDesigner
            ? ENDPOINTS.MY_APPROVED_DESIGNS_COUNT(user.Uid)
            : ENDPOINTS.APPROVED_DESIGNS_COUNT
        }`
      );
      const disapprovedResponse = await getRequest(
        `${
          isUserDesigner
            ? ENDPOINTS.MY_DISAPPROVED_DESIGNS_COUNT(user.Uid)
            : ENDPOINTS.DISAPPROVED_DESIGNS_COUNT
        }`
      );
      const pendingResponse = await getRequest(
        `${
          isUserDesigner
            ? ENDPOINTS.MY_PENDING_DESIGNS_COUNT(user.Uid)
            : ENDPOINTS.PENDING_DESIGNS_COUNT
        }`
      );
      const archivedResponse = isUserAdmin
        ? await getRequest(ENDPOINTS.ARCHIVED_DESIGNS_COUNT)
        : {};

      const counts = {
        approvedDesignsCount: approvedResponse.data.data,
        disapprovedDesignsCount: disapprovedResponse.data.data,
        pendingDesignsCount: pendingResponse.data.data,
        ...(isUserAdmin
          ? {
              archivedDesignsCount: archivedResponse.data.data,
            }
          : {}),
      };

      isUserAdmin && dispatch(setAdminDesignCounts(counts));
      isUserDesigner && dispatch(setDesignerDesignCounts(counts));
    }
    dispatch(setIsCountsRequestTriggered(false));
  };

  const handleMinimizeSidebar = (): void => {
    dispatch(setIsSidebarMaximized(false));
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Escape') {
      dispatch(setIsSidebarMaximized(false));
    }
  };

  useEffect(() => {
    void getSidebarItems();
  }, []);

  const topData =
    sidebarItems?.filter(
      (dt) => isTabShownToUser(dt) && dt.category !== 'footer'
    ) ?? [];

  const footerData =
    sidebarItems?.filter(
      (dt) => isTabShownToUser(dt) && dt.category === 'footer'
    ) ?? [];

  useEffect(() => {
    dispatch(setIsSidebarVisible(!!user && !isMobile));
  }, [isMobile, user]);

  useEffect(() => {
    dispatch(setIsCountsRequestTriggered(true));
  }, [isUserDesigner, isUserAdmin]);

  useEffect(() => {
    if (isCountsRequestTriggered) {
      void getCounts();
    }
  }, [isCountsRequestTriggered]);

  // Add escape key listener to close sidebar
  useEffect(() => {
    const handleEscKey = (e: KeyboardEvent): void => {
      if (e.key === 'Escape' && isMaximized) {
        dispatch(setIsSidebarMaximized(false));
      }
    };

    // Only add listener if sidebar is visible
    if (isMaximized) {
      document.addEventListener(
        'keydown',
        handleEscKey as unknown as EventListener
      );
    }

    return () => {
      document.removeEventListener(
        'keydown',
        handleEscKey as unknown as EventListener
      );
    };
  }, [isMaximized, dispatch]);

  return (
    <>
      {isMobile && isVisible && (
        <S.Overlay
          $isVisible={isVisible}
          onClick={handleMinimizeSidebar}
          aria-hidden="true"
        />
      )}
      <S.Wrapper
        $isVisible={isVisible}
        $isMaximized={isMaximized}
        ref={sidebarRef}
        onKeyDown={handleKeyDown}
        tabIndex={isMaximized ? 0 : -1}
        role="navigation"
        aria-label="Main navigation"
      >
        <S.LogoWrapper
          onClick={() =>
            !isMaximized ? dispatch(setIsSidebarMaximized(!isMaximized)) : {}
          }
        >
          <S.Logo
            $isMaximized={isMaximized}
            onClick={() => navigate(ROUTE.ROOT)}
            src={isDarkMode ? DarkLogoImage : LogoImage}
            alt={'logo'}
          />
          <S.HamburgerMenuWrapper
            onClick={() => dispatch(setIsSidebarMaximized(!isMaximized))}
          >
            <S.HamburgerMenu src={HamburgerMenuImage} alt={'hamburger menu'} />
          </S.HamburgerMenuWrapper>
        </S.LogoWrapper>
        <S.TabsWrapper>
          {topData &&
            topData?.length > 0 &&
            topData.map((tab, i) => (
              <Tab key={i} tab={tab} isTabShownToUser={isTabShownToUser} />
            ))}
        </S.TabsWrapper>
        <S.Footer>
          {footerData.map((tab, i) => (
            <Tab key={i} tab={tab} isTabShownToUser={isTabShownToUser} />
          ))}
          <S.ToggleContainer>
            <Toggle
              background={isDarkMode ? NightBg : DayBg}
              isActive={isDarkMode}
              knobBackground={isDarkMode ? NightIcon : DayIcon}
              onClick={toggleTheme}
              ariaLabel={
                isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'
              }
              size={isMaximized ? 'md' : 'sm'}
            />
          </S.ToggleContainer>

          <S.ProfileWrapper $isMaximized={isMaximized}>
            <Profile image={UserImage} isMaximized={isMaximized} />
          </S.ProfileWrapper>
        </S.Footer>
      </S.Wrapper>
    </>
  );
};

export default Sidebar;
