/** Dependencies */
import React, { FC } from 'react';

/** Image */
import LoginIcon from 'assets/images/svg/login-icon.svg';
import SettingsIcon from 'assets/images/svg/settings.svg';
import SettingsDarkIcon from 'assets/images/svg/dark/settings-dark.svg';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

/** Hooks */
import { useDeviceDetect } from 'hooks/UseDeviceDetect';
import { useTheme } from 'hooks/UseTheme';

/** Styles */
import * as S from 'features/Profile/Profile.styled';

interface IProps {
  image: string;
  isMaximized?: boolean;
}

const Profile: FC<IProps> = ({ image, isMaximized = true }) => {
  const { isMobile } = useDeviceDetect();
  const { isDarkMode } = useTheme();
  const { user, logout, openLogin, openProfile, isLoading } = useAuth();

  const isUserConnected = !!user;

  const handleClick = (): void => {
    if (isMobile || !isMaximized) {
      openProfile();
    }
  };

  const handleLoginClick = (options?: any): void =>
    !isUserConnected ? openLogin(options) : logout();

  const getUserInitial = (): string => {
    if (!isUserConnected || !user.FullName) return '';
    const nameParts = user.FullName.split(' ');
    return nameParts[0]?.[0] || '';
  };

  return (
    <S.Wrapper onClick={handleClick} $isMaximized={isMaximized}>
      {isLoading ? (
        <S.SignInWrapper>Loading...</S.SignInWrapper>
      ) : (
        <>
          {isUserConnected ? (
            <>
              <S.ImageWrapper>
                {user?.ProfileImageS3Url ? (
                  <S.Image
                    src={user.ProfileImageS3Url}
                    alt={user.FullName || 'User profile'}
                  />
                ) : (
                  getUserInitial()
                )}
              </S.ImageWrapper>
              {isMaximized && (
                <>
                  <S.ContentWrapper>
                    <S.Salute>{user.FullName || 'User'}</S.Salute>
                    <S.Settings onClick={handleLoginClick}>Logout</S.Settings>
                  </S.ContentWrapper>
                  <S.SettingsIcon
                    src={isDarkMode ? SettingsDarkIcon : SettingsIcon}
                    alt="Settings"
                    onClick={openProfile}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <S.ImageWrapper>
                <S.Image src={image} alt="profile" />
              </S.ImageWrapper>
              <S.ContentWrapper>
                <S.SignInWrapper onClick={handleLoginClick}>
                  {isMobile ? '' : 'Sign In'}
                  <S.SettingsIcon
                    src={LoginIcon}
                    alt="Sign In"
                    style={{ filter: isDarkMode ? 'brightness(1.5)' : 'none' }}
                  />
                </S.SignInWrapper>
              </S.ContentWrapper>
            </>
          )}
        </>
      )}
    </S.Wrapper>
  );
};

export default Profile;
