export const lightTheme = {
  sidebar_bg: '#E6EDF1',
  parent_bg: '#F4F6FA',
  child_bg: '#F4F6FA',
  parent_text: '#000000',
  child_text: '#0A1A25',
  child_text_hover: '#03090b',
  background: '#FFF',
  accent: '#4361EE',
  text: '#000000',
  secondaryText: '#4A5878',
  shadow: '#00000008',
  border: '#E4E4E7',
  layoutBorder: '#eeeeee',
  card: '#FFFFFF',
  cardBorder: '#E4E4E7',
  input: '#FFFFFF',
  inputBorder: '#E4E4E7',
  button: '#4A5878',
  buttonText: '#FFFFFF',
  buttonHover: '#3A4868',
  buttonDisabled: '#E4E4E7',
  buttonDisabledText: '#9CA3AF',
  tab: 'transparent',
  tabText: '#555555',
  tabActive: '#F0F4FF',
  tabTextActive: '#4361EE',
  tabHover: '#4361EE72',
  tabBorder: '#4361EE',
  link: '#4A5878',
  linkHover: '#3A4868',
  success: '#10B981',
  error: '#EF4444',
  warning: '#F59E0B',
  info: '#3B82F6',
  copyLimitBg: '#F0F4FF',
  copyLimitBgHover: '#d5d8e1',
  copyLimitText: '#555555',
};

export const darkTheme = {
  sidebar_bg: '#1A1A1A',
  parent_bg: '#2E3A59',
  child_bg: '#2E3A59',
  parent_text: '#FFFFFF',
  child_text: 'rgba(255, 255, 255, 0.9)',
  child_text_hover: '#ffffff',
  background: '#121212',
  accent: '#A38DFF',
  text: '#FFFFFF',
  secondaryText: '#B0B7C3',
  shadow: '#00000008',
  border: '#3A3A3C',
  layoutBorder: '#333333',
  card: '#1E1E1E',
  cardBorder: '#333333',
  input: '#2A2A2A',
  inputBorder: '#3A3A3C',
  button: '#6D4AFF',
  buttonText: '#FFFFFF',
  buttonHover: '#5B3DE8',
  buttonDisabled: '#333333',
  buttonDisabledText: '#777777',
  tab: 'transparent',
  tabText: '#AAAAAA',
  tabActive: '#8A6FFF26',
  tabTextActive: '#A38DFF',
  tabHover: '#A38DFF72',
  tabBorder: '#A38DFF',
  link: '#7F9CFF',
  linkHover: '#A0B7FF',
  success: '#10B981',
  error: '#EF4444',
  warning: '#F59E0B',
  info: '#3B82F6',
  copyLimitBg: '#1E1E1E',
  copyLimitBgHover: '#313131',
  copyLimitText: '#AAAAAA',
};
