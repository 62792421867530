/** Dependencies */
import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';

/** Api */
import { getRequest } from 'api/apiClient';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';
import { ModalType } from 'constants/ModalType';

/** Utilities */
import { flattenResponse } from 'utilities/functions';

/** Store */
import { setModal } from 'store/slices/Modal.slice';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

/** Hooks */
import { useAppDispatch } from 'hooks/UseAppDispatch';

/** Components */
import Loading from 'components/Loading/Loading';

/** Styles */
import * as S from './Dashboard.styled';

const Dashboard: FC = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();

  const [transactions, setTransactions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Calculate total balance from transactions
  const totalBalance = transactions.length ? transactions[0].totalBalance : 0;

  // Calculate total spent from transactions
  const totalSpent = transactions
    .filter((t) => t.balanceDelta < 0)
    .reduce((acc, t) => acc + Math.abs(t.balanceDelta), 0);

  const getTransactions = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const response = await getRequest(ENDPOINTS.MY_TRANSACTIONS(user.Uid));
      setTransactions(flattenResponse(response.data.data));
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMessageClick = (message: string): void => {
    dispatch(
      setModal({
        isVisible: true,
        title: `Message From Admin`,
        content: {
          type: ModalType.Message,
          props: {
            message,
          },
        },
      })
    );
  };

  useEffect(() => {
    void getTransactions();
  }, []);

  if (isLoading) {
    return <Loading showProgress message="Loading your transactions..." />;
  }

  return (
    <S.Wrapper>
      <S.Heading>Transaction History</S.Heading>

      <S.Summary>
        <S.SummaryCard>
          <S.SummaryLabel>Current Balance</S.SummaryLabel>
          <S.SummaryValue>${totalBalance}</S.SummaryValue>
        </S.SummaryCard>

        <S.SummaryCard>
          <S.SummaryLabel>Total Spent</S.SummaryLabel>
          <S.SummaryValue>${totalSpent.toFixed(2)}</S.SummaryValue>
        </S.SummaryCard>

        <S.SummaryCard>
          <S.SummaryLabel>Total Transactions</S.SummaryLabel>
          <S.SummaryValue>{transactions.length}</S.SummaryValue>
        </S.SummaryCard>
      </S.Summary>

      <S.Table>
        <S.Header>
          <S.HeaderItem>AMOUNT</S.HeaderItem>
          <S.HeaderItem>PREVIOUS</S.HeaderItem>
          <S.HeaderItem>CURRENT</S.HeaderItem>
          <S.HeaderItem>STATUS</S.HeaderItem>
          <S.HeaderItem>MESSAGE</S.HeaderItem>
          <S.HeaderItem>DATE</S.HeaderItem>
        </S.Header>

        {transactions.length > 0 ? (
          transactions.map((transaction, i) => (
            <S.Row key={i}>
              <S.RowItem>
                <S.Amount>
                  {transaction.balanceDelta < 0 ? '-' : '+'}$
                  {Math.abs(transaction.balanceDelta).toFixed(2)}
                </S.Amount>
                <S.Metric>USD</S.Metric>
              </S.RowItem>
              <S.RowItem>
                <S.Amount>
                  $
                  {(
                    transaction.totalBalance - transaction.balanceDelta
                  ).toFixed(2)}
                </S.Amount>
                <S.Metric>USD</S.Metric>
              </S.RowItem>
              <S.RowItem>
                <S.Amount>${transaction.totalBalance.toFixed(2)}</S.Amount>
                <S.Metric>USD</S.Metric>
              </S.RowItem>
              <S.RowItem>
                <S.Status $isPaid={transaction.balanceDelta < 0}>
                  <div className={'dot'} />
                  {transaction.balanceDelta < 0 ? 'Paid' : 'Approved'}
                </S.Status>
              </S.RowItem>
              <S.RowItem
                onClick={() => handleMessageClick(transaction.message)}
              >
                <S.Message>{transaction.message}</S.Message>
              </S.RowItem>
              <S.RowItem>
                <S.Amount>
                  {moment(transaction.createdAt).format('LLL')}
                </S.Amount>
              </S.RowItem>
            </S.Row>
          ))
        ) : (
          <S.TableEmptyState>
            <S.EmptyStateIcon>💳</S.EmptyStateIcon>
            <S.EmptyStateText>
              No transactions found. Your transaction history will appear here
              once you start making transactions.
            </S.EmptyStateText>
          </S.TableEmptyState>
        )}
      </S.Table>
    </S.Wrapper>
  );
};

export default Dashboard;
