/** Dependencies */
import React, { FC } from 'react';

/** Components */
import Button from 'components/Button/Button';

/** Hooks */
import { useAppDispatch } from 'hooks/UseAppDispatch';

/** Store */
import { confirmModal, hideModal } from 'store/slices/Modal.slice';

/** Styles */
import * as S from './RemovalModalContent.styled';

interface IProps {
  text: string;
  onConfirm: () => void;
}

const RemovalModalContent: FC<IProps> = ({ text }) => {
  const dispatch = useAppDispatch();

  const handleCancel = (): void => {
    dispatch(hideModal());
  };

  const handleConfirm = (): void => {
    dispatch(confirmModal(true));
  };

  return (
    <S.Wrapper>
      <S.Text>{text}</S.Text>
      <S.ButtonsContainer>
        <Button onClick={handleCancel} text={'Cancel'} />
        <Button onClick={handleConfirm} text={'Confirm'} isOutline />
      </S.ButtonsContainer>
    </S.Wrapper>
  );
};

export default RemovalModalContent;
