/** Dependencies */
import React, { FC } from 'react';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

/** Hooks */
import { useTheme } from 'hooks/UseTheme';

/** Images */
import Logo from 'assets/images/svg/logo.svg';
import LogoDark from 'assets/images/svg/dark/logo-dark.svg';
import DayIcon from 'assets/images/svg/day-icon.svg';
import NightIcon from 'assets/images/svg/night-icon.svg';
import NightBg from 'assets/images/svg/night-background.svg';
import DayBg from 'assets/images/svg/day-background.svg';

/** Components */
import Button from 'components/Button/Button';
import Toggle from 'components/Toggle/Toggle';

/** Styles */
import * as S from './Login.styled';

const Login: FC = () => {
  const { user, openSignup, openLogin } = useAuth();
  const { isDarkMode, toggleTheme } = useTheme();

  const designerSignupPlanUid = 'LmJjKpmP';

  return (
    <S.Wrapper>
      <S.ThemeToggleContainer>
        <Toggle
          isActive={isDarkMode}
          onClick={toggleTheme}
          background={isDarkMode ? NightBg : DayBg}
          knobBackground={isDarkMode ? NightIcon : DayIcon}
          ariaLabel={`Switch to ${isDarkMode ? 'light' : 'dark'} mode`}
        />
      </S.ThemeToggleContainer>
      {!user && (
        <S.LogoWrapper>
          <S.Image src={isDarkMode ? LogoDark : Logo} alt="logo" />
        </S.LogoWrapper>
      )}
      <S.Content>
        <S.Title>Welcome to Quick Design</S.Title>
        <S.Description>
          Sign in to access your account or join us as a designer to showcase
          your work.
        </S.Description>
        <S.ButtonsContainer>
          <Button
            text="Join Us as a designer"
            onClick={() => openSignup({ planUid: designerSignupPlanUid })}
            variant="primary"
          />
          {!user && (
            <Button onClick={openLogin} text="Sign in" variant="secondary" />
          )}
        </S.ButtonsContainer>
      </S.Content>
    </S.Wrapper>
  );
};

export default Login;
