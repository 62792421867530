/** Dependencies */
import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

/** Outseta */
import { loadOutseta } from './Outseta';

/** Features */
import { IPlan } from 'features/Sidebar/types';

/** Constants */
import { ROUTE } from 'constants/Routes';
import { ENDPOINTS } from 'constants/EndPoints';

/** Api */
import { getRequest, postRequest, putRequest } from 'api/apiClient';

/** Utilities */
import { compareUsers, flattenResponse } from 'utilities/functions';

/** Hooks */
import { useTheme } from 'hooks/UseTheme';

// Constants for localStorage keys
const LOCAL_STORAGE_TOKEN_KEY = 'quickdesign_auth_token';
const LOCAL_STORAGE_USER_KEY = 'quickdesign_user_data';

interface IOutsetaRef {
  setAccessToken: (token: string) => void;
  getAccessToken: () => string | null;
  getUser: () => Promise<any>;
  on: (event: string, handler: () => void) => void;
  auth: {
    open: (options: any) => void;
  };
  profile: {
    open: (options: any) => void;
  };
}

interface IAuthContextType {
  plans: IPlan[];
  user: any;
  isUserDesigner: boolean | null;
  isUserDefault: boolean | null;
  isUserAdmin: boolean | null;
  isLoading: boolean;
  logout: () => void;
  openLogin: (options?: any) => void;
  openSignup: (options?: any) => void;
  openProfile: (options?: any) => void;
}

const AuthContext = createContext<IAuthContextType>({
  plans: [],
  user: null,
  isUserDesigner: false,
  isUserDefault: false,
  isUserAdmin: false,
  isLoading: true,
  logout: () => ({}),
  openLogin: () => ({}),
  openSignup: () => ({}),
  openProfile: () => ({}),
});

export function useAuth(): IAuthContextType {
  return useContext(AuthContext);
}

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}): ReactElement {
  const outsetaRef = useRef<IOutsetaRef | null>(null);
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();

  const [status, setStatus] = useState<'init' | 'ready'>('init');
  const [user, setUser] = useState<any>(null);
  const [plans, setPlans] = useState<any[]>([]);
  const [isUserDesigner, setIsUserDesigner] = useState<boolean | null>(null);
  const [isUserDefault, setIsUserDefault] = useState<boolean | null>(null);
  const [isUserAdmin, setIsAdmin] = useState<boolean | null>(null);

  // Function to save token to localStorage
  const saveTokenToStorage = (token: string): void => {
    if (token) {
      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    }
  };

  // Function to save user data to localStorage
  const saveUserToStorage = (userData: any): void => {
    if (userData) {
      localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(userData));
    } else {
      localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
    }
  };

  // Function to load token from localStorage
  const loadTokenFromStorage = (): string | null => {
    return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
  };

  // Function to load user data from localStorage
  const loadUserFromStorage = (): any => {
    const userData = localStorage.getItem(LOCAL_STORAGE_USER_KEY);
    return userData ? JSON.parse(userData) : null;
  };

  const getPlans = async (): Promise<void> => {
    const response = await axios.get(
      'https://quickdesign.outseta.com/api/v1/billing/plans'
    );

    setPlans(response.data.items);
  };

  const updateUser = async (): Promise<void> => {
    try {
      // Fetch the current user data from outseta
      const outsetaUser = await outsetaRef.current?.getUser();

      if (outsetaUser) {
        // Update user state
        setUser(outsetaUser);
        // Save user data to localStorage for persistence across tabs
        saveUserToStorage(outsetaUser);
      }

      // Make sure status = ready
      setStatus('ready');
    } catch (error) {
      console.error('Failed to update user:', error);
      setStatus('ready');
    }
  };

  const handleOutsetaUserEvents = (onEvent: () => void): void => {
    // Subscribe to user related events
    // with onEvent function
    const outseta = outsetaRef.current;
    outseta?.on('subscription.update', onEvent);
    outseta?.on('profile.update', onEvent);
    outseta?.on('account.update', onEvent);
  };

  const logout = (): void => {
    navigate(ROUTE.ROOT);
    // Unset access token
    outsetaRef.current?.setAccessToken('');
    // Clear localStorage
    saveTokenToStorage('');
    saveUserToStorage(null);
    // and remove user state
    setUser(null);
  };

  const openLogin = async (options?: any): Promise<void> => {
    outsetaRef.current?.auth.open({
      widgetMode: 'login|register',
      authenticationCallbackUrl: window.location.href,
      theme: {
        displayMode: isDarkMode ? 'dark' : 'light',
      },
      ...options,
    });
  };

  const openSignup = async (options?: any): Promise<void> => {
    outsetaRef.current?.auth.open({
      widgetMode: 'register',
      authenticationCallbackUrl: window.location.href,
      theme: {
        displayMode: isDarkMode ? 'dark' : 'light',
      },
      ...options,
    });
  };

  const openProfile = async (options?: any): Promise<void> => {
    outsetaRef.current?.profile.open({ tab: 'profile', ...options });
  };

  // Setup event listener for storage changes (for cross-tab sync)
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent): void => {
      if (event.key === LOCAL_STORAGE_TOKEN_KEY) {
        const newToken = event.newValue;

        if (newToken && outsetaRef.current) {
          outsetaRef.current.setAccessToken(newToken);
          updateUser();
        } else if (!newToken) {
          setUser(null);
        }
      }

      if (event.key === LOCAL_STORAGE_USER_KEY && !user) {
        const newUserData = event.newValue ? JSON.parse(event.newValue) : null;
        if (newUserData) {
          setUser(newUserData);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [user]);

  useEffect(() => {
    const init = async (): Promise<void> => {
      try {
        // Await injection of the script
        outsetaRef.current = await loadOutseta();

        // Set up handling of user related events
        handleOutsetaUserEvents(updateUser);

        // Try to load stored token first
        const storedToken = loadTokenFromStorage();
        const storedUser = loadUserFromStorage();

        // Get the access token from the callback url (highest priority)
        const accessToken = searchParams.get('access_token');

        if (accessToken) {
          // If there is an access token present in URL
          // pass it along to Outseta
          outsetaRef.current?.setAccessToken(accessToken);
          // and save to localStorage
          saveTokenToStorage(accessToken);
          // clean up URL
          setSearchParams({});
          // Update user data
          updateUser();
        } else if (storedToken) {
          // If we have a stored token but no URL token
          outsetaRef.current?.setAccessToken(storedToken);

          // If we have stored user data, set it while we verify/update
          if (storedUser) {
            setUser(storedUser);
          }

          // Verify and update the user data
          updateUser();
        } else {
          // No authentication found
          setStatus('ready');
        }
      } catch (error) {
        console.error('Failed to initialize auth:', error);
        setStatus('ready');
      }
    };

    init();

    return () => {
      // Clean up user related event subscriptions
      handleOutsetaUserEvents(() => ({}));
    };
  }, [searchParams]);

  useEffect(() => {
    void getPlans();
  }, []);

  useEffect(() => {
    if (user) {
      const selectedPlan = plans.find(
        (plan) => plan.Uid === user?.Account?.CurrentSubscription?.Plan?.Uid
      );
      const isDesigner = selectedPlan?.PlanFamily.Name === 'Designers';

      const isDefault = selectedPlan?.PlanFamily.Name === 'Default';

      const isAdmin = selectedPlan?.PlanFamily.Name === 'Admins';

      const isPro = selectedPlan?.Name === 'Pro';
      const isBasic = selectedPlan?.Name === 'Basic';
      const isFree = selectedPlan?.Name === 'Free Plan';

      setIsUserDesigner(isDesigner);
      setIsUserDefault(isDefault);
      setIsAdmin(isAdmin);

      void getRequest(
        isDesigner
          ? ENDPOINTS.DESIGNER_USER_WITH_UID(user.Uid)
          : ENDPOINTS.SYSTEM_USER_WITH_UID(user.Uid)
      )
        .then((response) => {
          const strapiUser = flattenResponse(response.data.data)[0];

          const hasUserChanged = compareUsers({
            user: strapiUser,
            outsetaUser: user,
          });

          if (hasUserChanged)
            void putRequest(
              isDesigner
                ? ENDPOINTS.DESIGNER_USER_WITH_ID(strapiUser.documentId)
                : ENDPOINTS.SYSTEM_USER_WITH_ID(strapiUser.documentId),
              {
                ...(isDesigner
                  ? {
                      id: strapiUser.id,
                    }
                  : {
                      copyCount: isPro
                        ? 10000
                        : isBasic
                        ? 10
                        : isFree
                        ? 10
                        : null,
                      plan: selectedPlan?.Name,
                    }),
                fullName: user.FullName,
                email: user.Email,
              }
            ).catch((err) => console.log(err));
        })
        .catch(() => {
          void postRequest(
            isDesigner ? ENDPOINTS.DESIGNER_USERS : ENDPOINTS.SYSTEM_USERS,
            {
              ...(isDesigner
                ? {}
                : {
                    likes: [],
                    plan: selectedPlan?.Name,
                    copyCount: isPro
                      ? 10000
                      : isBasic
                      ? 10
                      : isFree
                      ? 10
                      : null,
                  }),
              fullName: user.FullName,
              email: user.Email,
              userId: user.Uid,
            }
          );
        });
    }
  }, [plans, user]);

  return (
    <AuthContext.Provider
      value={{
        plans,
        user,
        isUserDesigner,
        isUserDefault,
        isUserAdmin,
        isLoading: status !== 'ready',
        logout,
        openLogin,
        openSignup,
        openProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
