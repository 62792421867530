import styled, { css } from 'styled-components';
import { MAX_HEIGHT, MIN_HEIGHT, ZINDEX } from 'constants/Style';
import { BreakPoint } from 'constants/BreakPoint';

const HeaderWrapper = styled.div<{
  $isBottomSideDisabled: boolean;
  $isBottomSideVisible: boolean;
  $isVisible: boolean;
}>`
  ${({
    theme: { convertVW },
    $isBottomSideDisabled,
    $isBottomSideVisible,
    $isVisible,
  }) => css`
    position: ${$isVisible ? 'sticky' : 'absolute'};
    top: ${$isVisible ? 0 : '-100%'};
    right: 0;
    z-index: ${ZINDEX.header};
    height: ${$isBottomSideDisabled ? convertVW(70) : ''};
    width: 100%;
    box-shadow: 0 0 ${convertVW(10)} 0 var(--shadow);
    border-bottom: ${convertVW(1)} solid var(--layout-border);
    transition: all 0.5s ease;
    max-height: ${$isBottomSideVisible
      ? convertVW(MAX_HEIGHT.header)
      : convertVW(MIN_HEIGHT.header)};
    overflow: ${$isBottomSideVisible ? 'visible' : 'hidden'};

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      left: 0;
      width: 100%;
      max-height: ${$isBottomSideVisible
        ? convertVW(MAX_HEIGHT.header)
        : convertVW(MIN_HEIGHT.header)};
    }
  `}
`;

const HeaderTopSide = styled.div<{
  $isDark: boolean;
  $isBottomSideVisible: boolean;
  $isBottomSideDisabled: boolean;
}>`
  ${({
    theme: { convertVW },
    $isDark,
    $isBottomSideVisible,
    $isBottomSideDisabled,
  }) => css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: ${$isDark && $isBottomSideVisible && !$isBottomSideDisabled
        ? convertVW(1)
        : 0}
      solid var(--border);
    box-shadow: 0 0 ${convertVW(15)} 0 var(--shadow);
    padding: ${convertVW(22)} ${convertVW(20)} ${convertVW(23)};
    transition: ${!$isBottomSideDisabled ? 'all 0.5s' : 'none'};
  `}
`;

const HeaderPageNameWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderActionContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: ${convertVW(16)};
  `}
`;

const HeaderAction = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    align-items: center;
    height: ${convertVW(24)};
    padding: 0 ${convertVW(16)};
    border-radius: ${convertVW(8)};
    transition: all 0.2s ease;
    background: var(--copy-limit-bg);
    cursor: pointer;

    font-size: ${convertVW(12)};
    font-family: Plus Jakarta Sans, sans-serif;
    font-weight: 400;
    color: var(--copy-limit-text);

    &:hover {
      background: var(--copy-limit-bg-hover);
      transform: translateY(-2px);
    }
  `}
`;

const HeaderBurgerMenuWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${convertVW(48)};
    height: ${convertVW(48)};
    margin-left: -${convertVW(14)};
    margin-right: ${convertVW(8)};
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(0.95);
    }
  `}
`;

const HeaderBurgerMenu = styled.img`
  ${({ theme: { convertVW } }) => css`
    width: ${convertVW(24)};
    height: ${convertVW(24)};
  `}
`;

const HeaderPageName = styled.div`
  ${({ theme: { convertVW, text, accent } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(20)};
    line-height: ${convertVW(24)};
    font-weight: 600;
    color: ${text};
    position: relative;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      bottom: -${convertVW(4)};
      left: 0;
      width: 100%;
      height: ${convertVW(2)};
      background-color: ${accent};
      opacity: 0;
      transform: scaleX(0.7);
      transition: all 0.3s ease;
    }

    &:hover:after {
      opacity: 1;
      transform: scaleX(1);
    }

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      color: #66d996;
      font-size: ${convertVW(16)};
    }
  `}
`;

const HeaderBottomSide = styled.div<{
  $isBottomSideDisabled: boolean;
}>`
  ${({ $isBottomSideDisabled }) => css`
    display: ${!$isBottomSideDisabled ? 'inline-block' : 'none'};
    width: 100%;
  `}
`;

const HeaderFilters = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: ${convertVW(20)};
    background-color: var(--card);

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      align-items: flex-start;
      flex-direction: column;
      gap: ${convertVW(16)};
    }
  `}
`;

const HeaderCategorySelection = styled.div<{
  $isDark: boolean;
}>`
  ${({ theme: { convertVW }, $isDark }) => css`
    display: flex;
    justify-content: flex-start;
    align-content: center;
    height: ${convertVW(48)};
    padding: 0 ${convertVW(20)};
    border-bottom: ${$isDark ? convertVW(1) : 0} solid var(--border);
    box-shadow: 0 0 ${convertVW(15)} 0 var(--shadow);
  `}
`;

const HeaderCategory = styled.div<{
  $isActive: boolean;
}>`
  ${({ theme: { convertVW }, $isActive }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 ${convertVW(20)};
    cursor: pointer;

    font-family: DM Sans, sans-serif;
    font-weight: 500;
    font-size: ${convertVW(13)};
    line-height: ${convertVW(17)};
    color: var(--tab-text);

    ${$isActive &&
    css`
      border-bottom: ${convertVW(2)} solid var(--tab-text-active);
      padding: ${convertVW(2)} ${convertVW(20)} 0;

      color: var(--tab-text-active);
    `};
  `}
`;

const HeaderTagsContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: ${convertVW(16)};
    width: ${convertVW(1420)};

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      width: 100%;
    }
  `}
`;

const HeaderButtonsContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: ${convertVW(8)};
    padding-bottom: ${convertVW(8)};
    width: 100%;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      width: 100%;
    }
  `}
`;

const HeaderShrinkButton = styled.div`
  ${({ theme: { convertVW, secondaryText, border } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: ${convertVW(12)};
    width: ${convertVW(48)};
    border-top-left-radius: ${convertVW(4)};
    border-top-right-radius: ${convertVW(4)};
    background-color: ${border};
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: ${secondaryText};
    }
  `}
`;

const HeaderShrinkArrowIcon = styled.img<{
  $isBottomSideVisible: boolean;
}>`
  ${({ $isBottomSideVisible }) => css`
    height: 100%;
    transition: all 0.5s ease;
    transform: rotate(${$isBottomSideVisible ? '180deg' : '0'});
  `}
`;

export {
  HeaderWrapper as Wrapper,
  HeaderTopSide as TopSide,
  HeaderPageNameWrapper as PageNameWrapper,
  HeaderActionContainer as ActionContainer,
  HeaderBurgerMenuWrapper as BurgerMenuWrapper,
  HeaderBurgerMenu as BurgerMenu,
  HeaderPageName as PageName,
  HeaderAction as Action,
  HeaderBottomSide as BottomSide,
  HeaderFilters as Filters,
  HeaderCategorySelection as CategorySelection,
  HeaderCategory as Category,
  HeaderTagsContainer as TagsContainer,
  HeaderButtonsContainer as ButtonsContainer,
  HeaderShrinkButton as ShrinkButton,
  HeaderShrinkArrowIcon as ShrinkArrowIcon,
};
