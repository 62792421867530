const ENDPOINTS = {
  /** Designs */
  DESIGN: (id: string) => `/designs/${id}?populate=*`,
  MY_DESIGNS: (userId: string) =>
    `&filters[designerUser][userId][$eq]=${userId}`,
  APPROVED_DESIGNS: `/designs?populate[0]=image&populate[1]=likedUsers&filters[isApproved][$eq]=true`,
  DISAPPROVED_DESIGNS: `/designs?populate=*&filters[isApproved][$eq]=false`,
  PENDING_DESIGNS: `/designs?populate=*&filters[isApproved][$null]=true`,
  ARCHIVED_DESIGNS: `/unpublished-designs`,
  ARCHIVED_DESIGN: (documentId: string) => `/unpublished-designs/${documentId}`,
  ARCHIVE_DESIGN: `/unpublish-design`,
  UNARCHIVE_DESIGN: `/publish-design`,
  NOT_APPROVED_DESIGNS: `&filters[$or][0][isApproved][$eq]=false&filters[$or][1][isApproved][$null]=true`,
  DESIGNS: `/designs?populate=*`,

  /** Design Counts */
  APPROVED_DESIGNS_COUNT: `/approved-designs-count`,
  DISAPPROVED_DESIGNS_COUNT: `/disapproved-designs-count`,
  PENDING_DESIGNS_COUNT: `/pending-designs-count`,
  MY_APPROVED_DESIGNS_COUNT: (userId: string) =>
    `/my-approved-designs-count/${userId}`,
  MY_DISAPPROVED_DESIGNS_COUNT: (userId: string) =>
    `/my-disapproved-designs-count/${userId}`,
  MY_PENDING_DESIGNS_COUNT: (userId: string) =>
    `/my-pending-designs-count/${userId}`,
  ARCHIVED_DESIGNS_COUNT: `/archived-designs-count`,

  /** Users */
  DESIGNER_USERS: '/designer-users?populate=*',
  SYSTEM_USERS: '/system-users',
  SYSTEM_USER_WITH_UID: (uid: string) =>
    `/system-users?filters[userId][$eq]=${uid}`,
  SYSTEM_USER_WITH_UID_POPULATED: (uid: string) =>
    `/system-users?populate=likes&filters[userId][$eq]=${uid}`,
  SYSTEM_USER_WITH_ID: (userId: string) => `/system-users/${userId}`,
  DESIGNER_USER_WITH_UID: (uid: string) =>
    `/designer-users?filters[userId][$eq]=${uid}`,
  DESIGNER_USER_WITH_ID: (userId: string) => `/designer-users/${userId}`,
  LIKE_DESIGN: (likesObjectId: number) => `/system-users/${likesObjectId}`,

  /** Transactions */
  TRANSACTIONS: '/transactions',
  MY_TRANSACTIONS: (userId: string) =>
    `/transactions?sort[0]=createdAt:desc&filters[designerUser][userId][$eq]=${userId}`,

  /** Side Menu Items */
  SIDE_MENU_ITEMS: '/side-menu-items?populate[0]=icon&populate[1]=iconDark',

  /** Tags */
  CATEGORIES: `/categories`,
  INDUSTRIES: `/industries`,
  SUB_CATEGORIES: `/sub-categories`,
  SUB_CATEGORIES_BY_CATEGORY: (categoryId: number) =>
    `/sub-categories?filters[category][id][$eq]=${categoryId}`,
  BRANDS: `/brands`,
  EMAIL_TAGS: `/email-tags`,

  /** Media */
  UPLOAD: '/upload',

  /** Sorts */
  SORTS: '/sorts',
};

export { ENDPOINTS };
