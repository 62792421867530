import styled, { css } from 'styled-components';

const DashboardWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: relative;
    padding: ${convertVW(32)};
    width: 100%;
  `}
`;

const DashboardHeading = styled.h1`
  ${({ theme: { convertVW } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    font-weight: 700;
    font-size: ${convertVW(24)};
    color: ${({ theme }) => theme.text};
    margin-bottom: ${convertVW(24)};
  `}
`;

const DashboardSummary = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    gap: ${convertVW(20)};
    margin-bottom: ${convertVW(32)};
  `}
`;

const DashboardSummaryCard = styled.div`
  ${({ theme: { convertVW } }) => css`
    flex: 1;
    padding: ${convertVW(20)};
    background-color: ${({ theme }) => theme.card};
    border-radius: ${convertVW(12)};
    box-shadow: 0 ${convertVW(4)} ${convertVW(12)} ${convertVW(-2)}
      ${({ theme }) => theme.shadow};
    border: ${convertVW(1)} solid ${({ theme }) => theme.cardBorder};
  `}
`;

const DashboardSummaryLabel = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-size: ${convertVW(14)};
    color: ${({ theme }) => theme.secondaryText};
    margin-bottom: ${convertVW(8)};
  `}
`;

const DashboardSummaryValue = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-size: ${convertVW(24)};
    font-weight: 700;
    color: ${({ theme }) => theme.text};
  `}
`;

const DashboardTable = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${({ theme }) => theme.card};
    border-radius: ${convertVW(12)};
    overflow: hidden;
    border: ${convertVW(1)} solid ${({ theme }) => theme.cardBorder};
    box-shadow: 0 ${convertVW(8)} ${convertVW(12)} ${convertVW(-2)}
      ${({ theme }) => theme.shadow};
  `}
`;

const DashboardHeader = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: grid;
    grid-template-columns: 10% 10% 10% 10% 40% 20%;
    padding: ${convertVW(16)} ${convertVW(32)};
    background-color: ${({ theme }) => theme.background};
    border-bottom: ${convertVW(1)} solid ${({ theme }) => theme.border};
  `}
`;

const DashboardRow = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: grid;
    grid-template-columns: 10% 10% 10% 10% 40% 20%;
    align-items: center;
    padding: ${convertVW(16)} ${convertVW(32)};
    background-color: ${({ theme }) => theme.card};
    border-bottom: ${convertVW(1)} solid ${({ theme }) => theme.border};
    transition: background-color 0.2s ease;

    &:hover {
      background-color: ${({ theme }) => theme.input};
    }

    &:last-child {
      border-bottom: none;
    }
  `}
`;

const DashboardHeaderItem = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: Plus Jakarta Sans, sans-serif;
    font-weight: 700;
    font-size: ${convertVW(14)};
    letter-spacing: ${convertVW(0.5)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.accent};
    padding: ${convertVW(4)} 0;
  `}
`;

const DashboardRowItem = styled.div<{
  isAlignStart?: boolean;
}>`
  ${({ theme: { convertVW }, isAlignStart = true }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${isAlignStart ? 'flex-start' : 'flex-end'};
    padding: ${convertVW(8)} 0;
    gap: ${convertVW(4)};
  `}
`;

const DashboardAmount = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    font-weight: 600;
    font-size: ${convertVW(16)};
    color: ${({ theme }) => theme.text};
  `}
`;

const DashboardMetric = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    font-weight: 400;
    font-size: ${convertVW(12)};
    color: ${({ theme }) => theme.secondaryText};
  `}
`;

const DashboardStatus = styled.div<{
  $isPaid: boolean;
}>`
  ${({ theme: { convertVW }, $isPaid }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: ${convertVW(6)} ${convertVW(12)};
    border-radius: ${convertVW(16)};
    gap: ${convertVW(6)};
    font-family: Plus Jakarta Sans, sans-serif;
    font-weight: 600;
    font-size: ${convertVW(12)};
    background-color: ${$isPaid
      ? 'rgba(16, 185, 129, 0.15)'
      : 'rgba(237, 137, 54, 0.15)'};
    color: ${$isPaid ? '#10B981' : '#ED8936'};

    .dot {
      width: ${convertVW(8)};
      height: ${convertVW(8)};
      border-radius: 50%;
      background-color: ${$isPaid ? '#10B981' : '#ED8936'};
    }
  `}
`;

const DashboardMessage = styled.div`
  ${({ theme: { convertVW } }) => css`
    cursor: pointer;
    padding: ${convertVW(8)} ${convertVW(8)} ${convertVW(8)} 0;
    color: ${({ theme }) => theme.text};
    font-weight: 400;
    line-height: 1.5;
    max-height: ${convertVW(80)};
    overflow: auto;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: ${convertVW(20)};
      background: linear-gradient(
        to bottom,
        transparent,
        ${({ theme }) => theme.card}
      );
      pointer-events: none;
      opacity: 0.7;
    }

    &::-webkit-scrollbar {
      width: ${convertVW(4)};
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.border};
      border-radius: ${convertVW(10)};
    }

    &:hover {
      color: ${({ theme }) => theme.link};
      text-decoration: underline;
    }
  `}
`;

const DashboardTableEmptyState = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${convertVW(60)} ${convertVW(20)};
    text-align: center;
  `}
`;

const DashboardEmptyStateIcon = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-size: ${convertVW(48)};
    color: ${({ theme }) => theme.secondaryText};
    margin-bottom: ${convertVW(16)};
  `}
`;

const DashboardEmptyStateText = styled.p`
  ${({ theme: { convertVW } }) => css`
    font-size: ${convertVW(16)};
    color: ${({ theme }) => theme.secondaryText};
    max-width: ${convertVW(400)};
    margin: 0 auto;
  `}
`;

export {
  DashboardWrapper as Wrapper,
  DashboardHeading as Heading,
  DashboardSummary as Summary,
  DashboardSummaryCard as SummaryCard,
  DashboardSummaryLabel as SummaryLabel,
  DashboardSummaryValue as SummaryValue,
  DashboardTable as Table,
  DashboardHeader as Header,
  DashboardRow as Row,
  DashboardRowItem as RowItem,
  DashboardHeaderItem as HeaderItem,
  DashboardAmount as Amount,
  DashboardMetric as Metric,
  DashboardStatus as Status,
  DashboardMessage as Message,
  DashboardTableEmptyState as TableEmptyState,
  DashboardEmptyStateIcon as EmptyStateIcon,
  DashboardEmptyStateText as EmptyStateText,
};
