/** Dependencies */
import React, { FC, MouseEvent, useCallback } from 'react';

/** Hooks */
import { useDesignApprove } from 'hooks/UseDesignApprove';

/** Images */
import CheckIcon from 'assets/images/svg/check-icon.svg';
import CloseIcon from 'assets/images/svg/close-icon.svg';
import PauseIcon from 'assets/images/svg/pause-icon.svg';
import ArchiveIcon from 'assets/images/svg/archive-icon.svg';
import UnarchiveIcon from 'assets/images/svg/unarchive-icon.svg';

/** Styles */
import { AdminIconContainer, Icon } from '../AdminActions';
import { useLocation } from 'react-router-dom';
import { ROUTE } from 'constants/Routes';

interface AdminActionsProps {
  documentId: string;
  columnCount: number;
  refreshDesigns?: () => Promise<void>;
  refreshLikes?: () => Promise<void>;
}

const AdminActions: FC<AdminActionsProps> = ({
  documentId,
  columnCount,
  refreshDesigns = async () => Promise.resolve(),
  refreshLikes = async () => Promise.resolve(),
}) => {
  const { pathname } = useLocation();

  const actionVisibilities = {
    approve: (
      [ROUTE.DISAPPROVED_DESIGNS, ROUTE.PENDING_DESIGNS] as string[]
    ).includes(pathname),
    disapprove: (
      [ROUTE.APPROVED_DESIGNS, ROUTE.PENDING_DESIGNS] as string[]
    ).includes(pathname),
    suspend: (
      [ROUTE.APPROVED_DESIGNS, ROUTE.DISAPPROVED_DESIGNS] as string[]
    ).includes(pathname),
    archive: (
      [
        ROUTE.APPROVED_DESIGNS,
        ROUTE.DISAPPROVED_DESIGNS,
        ROUTE.PENDING_DESIGNS,
      ] as string[]
    ).includes(pathname),
    unarchive: ([ROUTE.ARCHIVE] as string[]).includes(pathname),
  };

  const {
    directApprove,
    directDisapprove,
    directSuspend,
    directArchive,
    directUnarchive,
  } = useDesignApprove({
    id: documentId,
    getDesigns: refreshDesigns,
    getLikes: refreshLikes,
    isArchivedCard: !actionVisibilities.approve,
  });

  // Handler for admin actions to prevent bubbling to card onClick
  const handleAdminAction = useCallback(
    (e: MouseEvent<HTMLImageElement>, action: () => Promise<void>): void => {
      e.preventDefault();
      e.stopPropagation();
      void action();
    },
    []
  );

  return (
    <AdminIconContainer $columnCount={columnCount}>
      <Icon
        $columnCount={columnCount}
        $isVisible={actionVisibilities.approve}
        onClick={(e: MouseEvent<HTMLImageElement>) =>
          handleAdminAction(e, directApprove)
        }
        src={CheckIcon}
        alt="Approve design"
        role="button"
        tabIndex={0}
        aria-label="Approve design"
      />

      <Icon
        $columnCount={columnCount}
        $isVisible={actionVisibilities.disapprove}
        onClick={(e: MouseEvent<HTMLImageElement>) =>
          handleAdminAction(e, directDisapprove)
        }
        src={CloseIcon}
        alt="Disapprove design"
        role="button"
        tabIndex={0}
        aria-label="Disapprove design"
      />

      <Icon
        $columnCount={columnCount}
        $isVisible={actionVisibilities.suspend}
        onClick={(e: MouseEvent<HTMLImageElement>) =>
          handleAdminAction(e, directSuspend)
        }
        src={PauseIcon}
        alt="Suspend design"
        role="button"
        tabIndex={0}
        aria-label="Suspend design"
      />

      <Icon
        $columnCount={columnCount}
        $isVisible={actionVisibilities.archive}
        onClick={(e: MouseEvent<HTMLImageElement>) =>
          handleAdminAction(e, directArchive)
        }
        src={ArchiveIcon}
        alt="Archive design"
        role="button"
        tabIndex={0}
        aria-label="Archive design"
      />

      <Icon
        $columnCount={columnCount}
        $isVisible={actionVisibilities.unarchive}
        onClick={(e: MouseEvent<HTMLImageElement>) =>
          handleAdminAction(e, directUnarchive)
        }
        src={UnarchiveIcon}
        alt="Unarchive design"
        role="button"
        tabIndex={0}
        aria-label="Unarchive design"
      />
    </AdminIconContainer>
  );
};

export default AdminActions;
