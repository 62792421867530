import styled, { css } from 'styled-components';
import { WIDTH, ZINDEX } from 'constants/Style';
import { BreakPoint } from 'constants/BreakPoint';

const SidebarWrapper = styled.div<{
  $isVisible: boolean;
  $isMaximized: boolean;
}>`
  ${({ theme: { convertVW }, $isVisible, $isMaximized }) => css`
    position: fixed;
    width: ${$isMaximized ? convertVW(WIDTH.sidebar) : convertVW(57)};
    height: 100vh;
    background-color: var(--card);
    border-right: ${convertVW(1)} solid var(--layout-border);
    z-index: ${ZINDEX.sidebar};
    left: ${$isVisible ? 0 : convertVW(-WIDTH.sidebar)};
    transition: all var(--transition-speed-slow) var(--transition-timing);
    box-shadow: ${$isVisible ? `0 0 ${convertVW(15)} var(--shadow)` : 'none'};

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      box-shadow: ${$isVisible ? `0 0 ${convertVW(15)} var(--shadow)` : 'none'};
    }
  `}
`;

const SidebarOverlay = styled.div<{
  $isVisible: boolean;
}>`
  ${({ $isVisible }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--shadow);
    z-index: ${ZINDEX.sidebar - 1};
    opacity: ${$isVisible ? 1 : 0};
    visibility: ${$isVisible ? 'visible' : 'hidden'};
    transition: opacity var(--transition-speed) var(--transition-timing),
      visibility var(--transition-speed) var(--transition-timing);

    @media only screen and (min-width: ${BreakPoint.Mobile + 1}px) {
      display: none;
    }
  `}
`;

const SidebarLogoWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    height: ${convertVW(70)};
    padding: ${convertVW(16)} ${convertVW(20)};
    border-bottom: ${convertVW(1)} solid var(--layout-border);
    margin-bottom: ${convertVW(20)};
    transition: background-color var(--transition-speed-fast)
      var(--transition-timing);
  `}
`;

const SidebarLogo = styled.img<{
  $isMaximized: boolean;
}>`
  ${({ theme: { convertVW }, $isMaximized }) => css`
    width: ${$isMaximized ? convertVW(132) : 0};
    transition: all var(--transition-speed) var(--transition-timing);
  `}
`;

const SidebarHamburgerMenuWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${convertVW(32)};
    width: ${convertVW(32)};
  `}
`;

const SidebarHamburgerMenu = styled.img`
  ${({ theme: { convertVW } }) => css`
    width: ${convertVW(16)};
    transition: filter var(--transition-speed) var(--transition-timing);
  `}
`;

const SidebarTabsWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex: 1;
    padding: 0 ${convertVW(8)};
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: ${convertVW(4)};
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--secondary-text);
      opacity: 0.3;
      border-radius: ${convertVW(4)};
    }
  `}
`;

const SidebarFooter = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: ${convertVW(16)} ${convertVW(8)};
    border-top: ${convertVW(1)} solid var(--border);
    background-color: var(--card);
    transition: background-color var(--transition-speed)
        var(--transition-timing),
      border-color var(--transition-speed) var(--transition-timing);
  `}
`;

const SidebarToggleContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    left: 0;
    margin-top: ${convertVW(10)};
    gap: ${convertVW(8)};
    transition: opacity var(--transition-speed) var(--transition-timing);
  `}
`;

const SidebarProfileWrapper = styled.div<{
  $isMaximized: boolean;
}>`
  ${({ theme: { convertVW }, $isMaximized }) => css`
    display: flex;
    width: 100%;
    margin-top: ${convertVW(12)};
    border-top: ${convertVW(1)} solid var(--border);
    padding: ${convertVW(12)} ${$isMaximized ? convertVW(12) : 0} 0;
    transition: all var(--transition-speed) var(--transition-timing);
  `}
`;

export {
  SidebarWrapper as Wrapper,
  SidebarOverlay as Overlay,
  SidebarLogoWrapper as LogoWrapper,
  SidebarLogo as Logo,
  SidebarHamburgerMenuWrapper as HamburgerMenuWrapper,
  SidebarHamburgerMenu as HamburgerMenu,
  SidebarTabsWrapper as TabsWrapper,
  SidebarFooter as Footer,
  SidebarToggleContainer as ToggleContainer,
  SidebarProfileWrapper as ProfileWrapper,
};
