import styled, { css, keyframes } from 'styled-components';
import * as CardS from './Card.styled';
import { SPACING } from 'constants/Spacing';

const successPulse = keyframes`
  0% {
    background-color: rgba(255, 255, 255, 0.8);
    transform: scale(1);
  }
  50% {
    background-color: rgba(144, 238, 144, 0.9);
    transform: scale(1.1);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.8);
    transform: scale(1);
  }
`;

// Add new animation for improved figma button
const slideExpand = keyframes`
  0% {
    width: ${40}px;
    opacity: 0.9;
  }
  100% {
    width: ${85}px;
    opacity: 1;
  }
`;

const CardWrapper = styled.div<{
  $columnCount: number;
  $span?: number;
  $isStaticAds?: boolean;
}>`
  ${({ theme: { convertVW }, $columnCount, $span, $isStaticAds }) => css`
    position: relative;
    aspect-ratio: ${$isStaticAds && $span ? 'auto' : '2 / 3'};
    height: ${$isStaticAds ? 'fit-content' : 'unset'};
    background: var(--card);
    border-radius: ${convertVW(10 - $columnCount)};
    padding: ${$isStaticAds ? 0 : convertVW((10 - $columnCount) * 3)};
    box-shadow: 0 ${convertVW(8)} ${convertVW(12)} ${convertVW(-2)}
        var(--shadow),
      0 ${convertVW(4)} ${convertVW(4)} ${convertVW(-4)} rgba(82, 82, 82, 0.1);
    border: ${convertVW(1)} solid var(--border);
    transition: all var(--transition-speed) var(--transition-timing);
    cursor: pointer;
    transform: translateY(0);

    ${$isStaticAds &&
    css`
      grid-row: span ${$span ?? 0};
    `};

    ${CardS.Icon} {
      display: none;
    }

    ${CardS.FigmaIconWrapper} {
      display: none;
    }

    &:hover {
      transform: translateY(-${convertVW(SPACING.xs)});
      box-shadow: 0 ${convertVW(12)} ${convertVW(16)} ${convertVW(-2)}
          var(--shadow),
        0 ${convertVW(6)} ${convertVW(8)} ${convertVW(-4)}
          rgba(82, 82, 82, 0.15);

      ${CardS.Icon} {
        display: flex;
      }

      ${CardS.FigmaIconWrapper} {
        display: flex;
      }
    }

    &:active {
      transform: translateY(0);
      transition: transform 0.1s ease-out;
    }
  `}
`;

const CardIconContainer = styled.div<{
  $isTop?: boolean;
  $columnCount: number;
}>`
  ${({ theme: { convertVW }, $isTop = true, $columnCount }) => css`
    position: absolute;
    right: ${convertVW((10 - $columnCount) * 5)};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: ${convertVW((10 - $columnCount) * 2)};
    z-index: 1;
    transition: transform var(--transition-speed-fast) var(--transition-timing);

    ${$isTop
      ? css`
          top: ${convertVW((10 - $columnCount) * 5)};
        `
      : css`
          bottom: ${convertVW((10 - $columnCount) * 5)};
        `}
  `}
`;

const CardFigmaIconWrapper = styled.div<{
  $columnCount: number;
}>`
  ${({ theme: { convertVW }, $columnCount }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${convertVW((10 - $columnCount) * 6)};
    min-width: ${convertVW((10 - $columnCount) * 6)};
    padding: ${convertVW(10 - $columnCount)};
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.95),
      rgba(245, 245, 245, 0.9)
    );
    color: #333;
    border-radius: ${convertVW((10 - $columnCount) * 3)};
    gap: ${convertVW(10 - $columnCount)};
    border: ${convertVW((10 - $columnCount) / 6)} solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    overflow: hidden;
    transition: all var(--transition-speed-fast) var(--transition-timing);
    box-shadow: 0 ${convertVW((10 - $columnCount) / 3)}
      ${convertVW(10 - $columnCount)} rgba(0, 0, 0, 0.12);
    backdrop-filter: blur(${convertVW((10 - $columnCount) / 1.5)});
    -webkit-backdrop-filter: blur(${convertVW((10 - $columnCount) / 1.5)});

    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW((10 - $columnCount) * 2)};
    font-weight: 500;
    letter-spacing: ${convertVW((10 - $columnCount) / 20)};
    user-select: none;

    /* Figma icon styling */

    ${CardS.Icon} {
      width: unset;
      height: 100%;
      border-radius: 0;
      padding: 0;
      background: transparent;
      transition: transform var(--transition-speed-fast)
        var(--transition-timing);
    }

    /* Hover state */

    &:hover {
      width: auto;
      min-width: ${convertVW((10 - $columnCount) * 14)};
      animation: ${slideExpand} 0.3s ease-out forwards;
      background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.98),
        rgba(245, 245, 245, 0.95)
      );
      box-shadow: 0 ${convertVW((10 - $columnCount) / 2)}
        ${convertVW(((10 - $columnCount) / 1.5) * 2)} rgba(0, 0, 0, 0.18);
      transform: translateY(-${convertVW(1)});

      ${CardS.Icon} {
        transform: scale(1.1);
      }

      &::before {
        opacity: 1;
      }
    }

    /* Active state */

    &:active {
      transform: scale(0.95) translateY(0);
      box-shadow: 0 ${convertVW((10 - $columnCount) / 6)}
        ${convertVW((10 - $columnCount) / 2)} rgba(0, 0, 0, 0.1);
    }

    /* Success animation when copied */

    &.copied-animation {
      animation: ${successPulse} 0.8s ease-in-out;
      width: auto;
      min-width: ${convertVW((10 - $columnCount) * 16)};
      color: #1a5928;
      font-weight: bold;
      box-shadow: 0 ${convertVW((10 - $columnCount) / 3)}
        ${convertVW(((10 - $columnCount) / 1.5) * 2)} rgba(20, 160, 20, 0.3);
      border: ${convertVW(1)} solid rgba(80, 200, 80, 0.4);
    }
  `}
`;

const CardIcon = styled.img<{
  $columnCount: number;
}>`
  ${({ theme: { convertVW }, $columnCount }) => css`
    justify-content: center;
    align-items: center;
    width: ${convertVW((10 - $columnCount) * 6)};
    height: ${convertVW((10 - $columnCount) * 6)};
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    padding: ${convertVW(10 - $columnCount)};
    cursor: pointer;
    transition: all var(--transition-speed-fast) var(--transition-timing);
    box-shadow: 0 ${convertVW((10 - $columnCount) / 3)}
      ${convertVW((10 - $columnCount) / 1.5)} rgba(0, 0, 0, 0.1);

    &:hover {
      transform: scale(1.1);
      background-color: rgba(255, 255, 255, 0.95);
      box-shadow: 0 ${convertVW((10 - $columnCount) / 2)}
        ${convertVW(10 - $columnCount)} rgba(0, 0, 0, 0.15);
    }

    &:active {
      transform: scale(0.9);
      box-shadow: 0 ${convertVW((10 - $columnCount) / 6)}
        ${convertVW((10 - $columnCount) / 3)} rgba(0, 0, 0, 0.1);
    }

    &[src*='red-heart-icon'] {
      animation: pulse 0.5s ease-out infinite;
    }

    &[src*='loading'] {
      animation: rotate 1s linear infinite;
    }

    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(180deg);
      }
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.05);
      }
      100% {
        transform: scale(1);
      }
    }

    /* Improve focus for accessibility */

    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 ${convertVW((10 - $columnCount) / 3)} var(--button),
        0 ${convertVW((10 - $columnCount) / 2)} ${convertVW(10 - $columnCount)}
          rgba(0, 0, 0, 0.2);
    }
  `}
`;

const CardImageWrapper = styled.div<{
  $isStaticAds: boolean;
  $columnCount: number;
}>`
  ${({ theme: { convertVW }, $isStaticAds, $columnCount }) => css`
    width: 100%;
    height: 100%;
    overflow: scroll;
    border-radius: ${convertVW(10 - $columnCount)};
    transition: transform var(--transition-speed) var(--transition-timing);

    ${$isStaticAds &&
    css`
      display: flex;
    `};

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

const CardImage = styled.img`
  width: 100%;
`;

// New component for like count display
const CardLikeCount = styled.div<{
  $columnCount: number;
}>`
  ${({ theme: { convertVW }, $columnCount }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: ${convertVW((10 - $columnCount) * 7)};
    height: ${convertVW((10 - $columnCount) * 6)};
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: ${convertVW((10 - $columnCount) * 3)};
    padding: ${convertVW(10 - $columnCount)}
      ${convertVW((10 - $columnCount) * 2)};
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: ${convertVW((10 - $columnCount) * 2.25)};
    font-weight: 600;
    color: #333;
    gap: ${convertVW((10 - $columnCount) / 1.5)};
    box-shadow: 0 ${convertVW((10 - $columnCount) / 3)}
      ${convertVW((10 - $columnCount) / 1.5)} rgba(0, 0, 0, 0.1);
    transition: all var(--transition-speed-fast) var(--transition-timing);

    img {
      height: 85%;
    }

    &:hover {
      transform: scale(1.05);
      background-color: rgba(255, 255, 255, 0.95);
      box-shadow: 0 ${convertVW((10 - $columnCount) / 2)}
        ${convertVW(10 - $columnCount)} rgba(0, 0, 0, 0.15);
    }
  `}
`;

export {
  CardWrapper as Wrapper,
  CardIconContainer as IconContainer,
  CardFigmaIconWrapper as FigmaIconWrapper,
  CardIcon as Icon,
  CardImageWrapper as ImageWrapper,
  CardImage as Image,
  CardLikeCount as LikeCount,
};
