/** Dependencies */
import React, { FC } from 'react';

/** Features */
import Page from 'features/Page/Page';

/** Hooks */
import { useDesignCopy } from 'hooks/UseDesignCopy';
import { useDesigns } from 'hooks/UseDesigns';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

const Emails: FC = () => {
  const noContentTexts = {
    title: '',
    description: '',
    acknowledgment: '',
  };

  const handleDesignCopy = useDesignCopy();

  const { designs, isPageLoading, loadMoreDesigns, hasMoreData, loadingMore } =
    useDesigns({
      endpoint: ENDPOINTS.APPROVED_DESIGNS,
      itemsPerPage: 12,
    });

  const handleLoadMore = async (): Promise<void> => {
    await loadMoreDesigns();
  };

  return (
    <Page
      isContentShown={!!designs?.length}
      noContentTexts={noContentTexts}
      isLoading={isPageLoading}
      designs={designs}
      onCopy={handleDesignCopy}
      onLoadMore={handleLoadMore}
      hasMoreData={hasMoreData}
      loadingMore={loadingMore}
    />
  );
};

export default Emails;
