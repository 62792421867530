import styled, { css, keyframes } from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Add a subtle pulse animation for the skeleton
const skeletonPulse = keyframes`
  0% {
    opacity: 0.6;
    transform: scale(0.98);
  }
  50% {
    opacity: 0.8;
    transform: scale(1);
  }
  100% {
    opacity: 0.6;
    transform: scale(0.98);
  }
`;

const SkeletonCardWrapper = styled.div<{ $columnCount: number }>`
  ${({ theme: { convertVW, card, border, shadow }, $columnCount }) => css`
    position: relative;
    aspect-ratio: 2 / 3;
    background: ${card};
    border-radius: ${convertVW(10 - $columnCount)};
    padding: ${convertVW((10 - $columnCount) * 3)};
    box-shadow: 0 ${convertVW(8)} ${convertVW(12)} ${convertVW(-2)} ${shadow},
      0 ${convertVW(4)} ${convertVW(4)} ${convertVW(-4)} rgba(82, 82, 82, 0.1);
    border: ${convertVW(1)} solid ${border};
    transition: all var(--transition-speed) var(--transition-timing);
    animation: ${skeletonPulse} 1.5s ease-in-out infinite;
  `}
`;

const SkeletonCardImage = styled(Skeleton)`
  ${({ theme: { convertVW } }) => css`
    width: 99%;
    height: 99%;
    border-radius: ${convertVW(6)};

    /* Customize the skeleton animation */

    &.react-loading-skeleton {
      --base-color: rgba(0, 0, 0, 0.06);
      --highlight-color: rgba(0, 0, 0, 0.12);

      /* Apply theme-appropriate colors based on dark/light mode */

      .dark-theme & {
        --base-color: rgba(255, 255, 255, 0.05);
        --highlight-color: rgba(255, 255, 255, 0.1);
      }
    }
  `}
`;

export { SkeletonCardWrapper as Wrapper, SkeletonCardImage as Image };
