interface IModalType {
  CopyLimit: string;
  Remove: string;
  Message: string;
}

export const ModalType: IModalType = {
  CopyLimit: 'CopyLimit',
  Remove: 'Remove',
  Message: 'Message',
};
