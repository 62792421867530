/** Dependencies */
import React, { FC } from 'react';
import { SkeletonStyleProps } from 'react-loading-skeleton';

/** Hooks */
import { useAppSelector } from 'hooks/UseAppSelector';

/** Store */
import { selectColumnCount } from 'store/slices/Grid.slice';

/** Components */
import SkeletonCard from 'components/SkeletonCard/SkeletonCard';

const SkeletonList: FC<
  SkeletonStyleProps & {
    cardCount?: number;
  }
> = (skeletonStyleProps) => {
  const columnCount = useAppSelector(selectColumnCount);

  return (
    <>
      {Array.from(
        new Array(skeletonStyleProps?.cardCount ?? columnCount * 2)
      ).map((item, i) => (
        <SkeletonCard key={i} {...skeletonStyleProps} />
      ))}
    </>
  );
};

export default SkeletonList;
