import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/App.store';
import { ITag } from 'features/Header/types';

interface IHeaderState {
  isHeaderVisible: boolean;
  isBottomSideDisabled: boolean;
  headerTitle: string;
  selectedFilterCategory: any;
  selectedFilterSubjects: ITag[];
  selectedFilterIndustries: ITag[];
  copyCount: number;
  isCopyCountRequestTriggered: boolean;
}

const initialState: IHeaderState = {
  isHeaderVisible: false,
  isBottomSideDisabled: true,
  headerTitle: 'Emails Library',
  selectedFilterCategory: { id: 0 },
  selectedFilterSubjects: [],
  selectedFilterIndustries: [],
  copyCount: 0,
  isCopyCountRequestTriggered: false,
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setHeaderVisibility: (state, action) => {
      state.isHeaderVisible = action.payload;
    },
    setIsHeaderBottomSideDisabled: (state, action) => {
      state.isBottomSideDisabled = action.payload;
    },
    setHeaderTitle: (state, action) => {
      state.headerTitle = action.payload;
    },
    setSelectedFilterCategory: (state, action) => {
      state.selectedFilterCategory = action.payload;
    },
    setSelectedFilterSubjects: (state, action) => {
      state.selectedFilterSubjects = action.payload;
    },
    setSelectedFilterIndustries: (state, action) => {
      state.selectedFilterIndustries = action.payload;
    },
    setCopyCount: (state, action) => {
      state.copyCount = action.payload;
    },
    setIsCopyCountRequestTriggered: (state, action) => {
      state.isCopyCountRequestTriggered = action.payload;
    },
  },
});

export const {
  setHeaderVisibility,
  setIsHeaderBottomSideDisabled,
  setHeaderTitle,
  setSelectedFilterCategory,
  setSelectedFilterSubjects,
  setSelectedFilterIndustries,
  setCopyCount,
  setIsCopyCountRequestTriggered,
} = headerSlice.actions;

export const selectIsHeaderVisible = (state: RootState): boolean =>
  state.header.isHeaderVisible;

export const selectIsBottomSideDisabled = (state: RootState): boolean =>
  state.header.isBottomSideDisabled;

export const selectHeaderTitle = (state: RootState): string =>
  state.header.headerTitle;

export const selectFilterCategory = (state: RootState): any =>
  state.header.selectedFilterCategory;

export const selectFilterSubjects = (state: RootState): ITag[] =>
  state.header.selectedFilterSubjects;

export const selectFilterIndustries = (state: RootState): ITag[] =>
  state.header.selectedFilterIndustries;

export const selectCopyCount = (state: RootState): number =>
  state.header.copyCount;

export const selectIsCopyCountRequestTriggered = (state: RootState): boolean =>
  state.header.isCopyCountRequestTriggered;

export default headerSlice.reducer;
