/** Dependencies */
import React, { FC } from 'react';

/** Images */
import logo from 'assets/images/svg/logo-without-text.svg';
import darkLogo from 'assets/images/svg/logo-without-text-dark.svg';

/** Hooks */
import { useAppSelector } from 'hooks/UseAppSelector';

/** Features */
import SkeletonList from 'features/SkeletonList/SkeletonList';

/** Store */
import {
  selectIsSidebarMaximized,
  selectThemeIsDark,
} from 'store/slices/Sidebar.slice';
import { selectColumnCount } from 'store/slices/Grid.slice';

/** Styles */
import * as S from './NoContent.styled';

interface NoContentProps {
  texts?: {
    title: string;
    description: string;
    acknowledgment: string;
  };
  isError?: boolean;
  onRetry?: () => void;
}

const DEFAULT_TEXTS = {
  title: 'Content',
  description: 'No content is available at the moment.',
  acknowledgment: 'Try again later',
};

const NoContent: FC<NoContentProps> = ({ texts, isError = false, onRetry }) => {
  const isSidebarMaximized = useAppSelector(selectIsSidebarMaximized);
  const columnCount = useAppSelector(selectColumnCount);
  const isDark = useAppSelector(selectThemeIsDark);

  // Prepare text content with fallbacks
  const displayTexts = {
    title: texts?.title || DEFAULT_TEXTS.title,
    description: texts?.description || DEFAULT_TEXTS.description,
    acknowledgment: texts?.acknowledgment || DEFAULT_TEXTS.acknowledgment,
  };

  const titleText = isError
    ? displayTexts.title
    : `No ${displayTexts.title} Designs`;

  return (
    <>
      <S.Shadow $isSidebarMaximized={isSidebarMaximized}>
        <S.ShadowLogoWrapper>
          <S.ShadowImage
            src={isDark ? darkLogo : logo}
            alt="Quick Design logo"
            draggable={false}
          />
        </S.ShadowLogoWrapper>

        <S.ShadowTitle aria-label={isError ? 'Error' : 'No Content'}>
          {titleText}
        </S.ShadowTitle>

        <S.ShadowDescription>{displayTexts.description}</S.ShadowDescription>

        {displayTexts.acknowledgment && (
          <S.ShadowAcknowledgment>
            {displayTexts.acknowledgment}
          </S.ShadowAcknowledgment>
        )}

        {onRetry && (
          <S.RetryButton onClick={onRetry} aria-label="Retry loading content">
            Try Again
          </S.RetryButton>
        )}
      </S.Shadow>

      <SkeletonList duration={0} cardCount={columnCount} />
    </>
  );
};

export default NoContent;
