/** Dependencies */
import React, { FC } from 'react';

/** Store */
import { ModalContentConfig } from 'store/slices/Modal.slice';

/** Constants */
import { ModalType } from 'constants/ModalType';

/** Components */
import { CopyLimitModalContent } from 'components/CopyLimitModalContent';
import { RemovalModalContent } from 'components/RemovalModalContent';
import { MessageModalContent } from 'components/MessageModalContent';

interface IProps {
  content: ModalContentConfig | null;
}

const ModalContentRenderer: FC<IProps> = ({ content }) => {
  if (!content) return null;

  const components = {
    [ModalType.CopyLimit]: CopyLimitModalContent,
    [ModalType.Remove]: RemovalModalContent,
    [ModalType.Message]: MessageModalContent,
  };

  const Component = Object.getOwnPropertyDescriptor(
    components,
    content.type
  )?.value;

  if (!Component) {
    console.warn(`Unknown modal content type: ${content.type}`);
    return null;
  }

  return <Component {...content.props} />;
};

export default ModalContentRenderer;
