import { darkTheme, lightTheme } from 'App.theme';

/**
 * Sets CSS variables on document root based on the selected theme
 * @param isDarkMode Boolean indicating whether dark mode is enabled
 */
export const setThemeVariables = (isDarkMode: boolean): void => {
  if (typeof document === 'undefined') {
    return;
  }

  const root = document.documentElement;
  const theme = isDarkMode ? darkTheme : lightTheme;

  root.style.setProperty('--background', theme.background);
  root.style.setProperty('--text', theme.text);
  root.style.setProperty('--accent', theme.accent);
  root.style.setProperty('--secondary-text', theme.secondaryText);
  root.style.setProperty('--border', theme.border);
  root.style.setProperty('--layout-border', theme.layoutBorder);
  root.style.setProperty('--card', theme.card);
  root.style.setProperty('--card-border', theme.cardBorder);
  root.style.setProperty('--input', theme.input);
  root.style.setProperty('--input-border', theme.inputBorder);
  root.style.setProperty('--button', theme.button);
  root.style.setProperty('--button-text', theme.buttonText);
  root.style.setProperty('--button-hover', theme.buttonHover);
  root.style.setProperty('--button-disabled', theme.buttonDisabled);
  root.style.setProperty('--button-disabled-text', theme.buttonDisabledText);
  root.style.setProperty('--tab', theme.tab);
  root.style.setProperty('--tab-text', theme.tabText);
  root.style.setProperty('--tab-active', theme.tabActive);
  root.style.setProperty('--tab-text-active', theme.tabTextActive);
  root.style.setProperty('--tab-hover', theme.tabHover);
  root.style.setProperty('--tab-border', theme.tabBorder);
  root.style.setProperty('--link', theme.link);
  root.style.setProperty('--link-hover', theme.linkHover);
  root.style.setProperty('--success', theme.success);
  root.style.setProperty('--error', theme.error);
  root.style.setProperty('--warning', theme.warning);
  root.style.setProperty('--info', theme.info);
  root.style.setProperty('--copy-limit-bg', theme.copyLimitBg);
  root.style.setProperty('--copy-limit-bg-hover', theme.copyLimitBgHover);
  root.style.setProperty('--copy-limit-text', theme.copyLimitText);
  root.style.setProperty('--shadow', theme.shadow);

  // Set consistent transition speeds
  root.style.setProperty('--transition-speed', '0.3s');
  root.style.setProperty('--transition-timing', 'ease');
  root.style.setProperty('--transition-speed-fast', '0.15s');
  root.style.setProperty('--transition-speed-slow', '0.5s');
};
