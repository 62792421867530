import styled, { css } from 'styled-components';
import { ZINDEX } from 'constants/Style';

const ModalOverlay = styled.div`
  ${() => css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: ${ZINDEX.modal};
    background: rgba(0, 0, 0, 0.3);
  `}
`;

export { ModalOverlay as Overlay };
