/** Dependencies */
import React, { FC, useEffect, useState } from 'react';

/** Images */
import BurgerMenuIcon from 'assets/images/svg/hamburger-menu.svg';
import ArrowIcon from 'assets/images/svg/arrow-down-icon.svg';

/** Hooks */
import { useDeviceDetect } from 'hooks/UseDeviceDetect';
import { useAppDispatch } from 'hooks/UseAppDispatch';
import { useAppSelector } from 'hooks/UseAppSelector';

/** Features */
import Tags from 'features/Tags/Tags';
import GridSizeButton from 'features/GridSizeButton/GridSizeButton';
import SortButton from 'features/SortButton/SortButton';

/** Store */
import {
  selectThemeIsDark,
  setIsSidebarMaximized,
} from 'store/slices/Sidebar.slice';
import {
  selectCopyCount,
  selectFilterCategory,
  selectHeaderTitle,
  selectIsBottomSideDisabled,
  selectIsCopyCountRequestTriggered,
  selectIsHeaderVisible,
  setCopyCount,
  setHeaderVisibility,
  setIsCopyCountRequestTriggered,
  setSelectedFilterCategory,
  setSelectedFilterIndustries,
  setSelectedFilterSubjects,
} from 'store/slices/Header.slice';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

/** Types */
import { ITag } from 'features/Header/types';

/** Utilities */
import { flattenResponse } from 'utilities/functions';

/** Api */
import { getRequest } from 'api/apiClient';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

/** Styles */
import * as S from './Header.styled';

const Header: FC = () => {
  const { isMobile } = useDeviceDetect();
  const dispatch = useAppDispatch();
  const { user, isUserDesigner, isUserDefault, plans } = useAuth();

  const isHeaderVisible = useAppSelector(selectIsHeaderVisible);
  const isBottomSideDisabled = useAppSelector(selectIsBottomSideDisabled);
  const isDark = useAppSelector(selectThemeIsDark);
  const copyCount = useAppSelector(selectCopyCount);
  const isCopyCountRequestTriggered = useAppSelector(
    selectIsCopyCountRequestTriggered
  );
  const title = useAppSelector(selectHeaderTitle);
  const filterCategory = useAppSelector(selectFilterCategory);

  const selectedPlan = plans.find(
    (plan) => plan.Uid === user?.Account?.CurrentSubscription?.Plan?.Uid
  );

  const isPro = selectedPlan?.Name === 'Pro';

  const [isBottomSideVisible, setIsBottomSideVisible] = useState<boolean>(true);
  const [categories, setCategories] = useState<any[]>([]);
  const [subjects, setSubjects] = useState<ITag[]>([]);
  const [industries, setIndustries] = useState<ITag[]>([]);
  const [designerUser, setDesignerUser] = useState<any>();

  const getCategories = async (): Promise<void> => {
    const categoriesResponse = await getRequest(ENDPOINTS.CATEGORIES);

    const categoriesSortedArray = flattenResponse(
      categoriesResponse.data.data
    ).sort((itemA, itemB) => itemB.id - itemA.id);

    setCategories(categoriesSortedArray);
    dispatch(setSelectedFilterCategory(categoriesSortedArray[0]));
  };

  const getSubjects = async (): Promise<void> => {
    const response = await getRequest(
      ENDPOINTS.SUB_CATEGORIES_BY_CATEGORY(filterCategory.id)
    );

    setSubjects(flattenResponse(response.data.data));
  };

  const getIndustries = async (): Promise<void> => {
    const response = await getRequest(ENDPOINTS.INDUSTRIES);

    setIndustries(flattenResponse(response.data.data));
  };

  const getDesignerUser = async (): Promise<void> => {
    const response = await getRequest(
      ENDPOINTS.DESIGNER_USER_WITH_UID(user.Uid)
    );

    setDesignerUser(flattenResponse(response.data.data)[0]);
  };

  const getSystemUserCopyCount = async (): Promise<void> => {
    const response = await getRequest(ENDPOINTS.SYSTEM_USER_WITH_UID(user.Uid));

    dispatch(setCopyCount(flattenResponse(response.data.data)[0]?.copyCount));
    dispatch(setIsCopyCountRequestTriggered(false));
  };

  const handleCategoryClick = (category: any): void => {
    dispatch(setSelectedFilterCategory(category));
    dispatch(setSelectedFilterIndustries([]));
    dispatch(setSelectedFilterSubjects([]));
  };

  useEffect(() => {
    void getCategories();
    void getIndustries();
  }, []);

  useEffect(() => {
    filterCategory?.id && void getSubjects();
  }, [filterCategory]);

  useEffect(() => {
    isUserDesigner && void getDesignerUser();
  }, [isUserDesigner]);

  useEffect(() => {
    isUserDefault && dispatch(setIsCopyCountRequestTriggered(true));
  }, [isUserDefault]);

  useEffect(() => {
    isCopyCountRequestTriggered && getSystemUserCopyCount();
  }, [isCopyCountRequestTriggered]);

  useEffect(() => {
    dispatch(setHeaderVisibility(!!user));
  }, [user]);

  return (
    <S.Wrapper
      $isBottomSideDisabled={isBottomSideDisabled}
      $isBottomSideVisible={isBottomSideVisible}
      $isVisible={isHeaderVisible}
    >
      <S.TopSide
        $isDark={isDark}
        $isBottomSideDisabled={isBottomSideDisabled}
        $isBottomSideVisible={isBottomSideVisible}
      >
        <S.PageNameWrapper>
          {isMobile && (
            <S.BurgerMenuWrapper
              onClick={() => dispatch(setIsSidebarMaximized(true))}
            >
              <S.BurgerMenu src={BurgerMenuIcon} alt={'Burger Menu'} />
            </S.BurgerMenuWrapper>
          )}
          <S.PageName>{title}</S.PageName>
        </S.PageNameWrapper>
        <S.ActionContainer>
          {isUserDesigner && (
            <S.Action>
              Total balance: ${designerUser?.totalBalance ?? 0}
            </S.Action>
          )}
          {isUserDefault && !isPro && (
            <S.Action>Daily copies remaining: {copyCount}</S.Action>
          )}
        </S.ActionContainer>
      </S.TopSide>
      <S.BottomSide $isBottomSideDisabled={isBottomSideDisabled}>
        <S.CategorySelection $isDark={isDark}>
          {categories.map((category, i) => (
            <S.Category
              $isActive={filterCategory?.id === category.id}
              key={i}
              onClick={() => handleCategoryClick(category)}
            >
              {category.text}
            </S.Category>
          ))}
        </S.CategorySelection>
        <S.Filters>
          <S.TagsContainer>
            <Tags
              title={'Subject'}
              placeholder={'All Selected'}
              tags={subjects}
              onSelect={(tags) => dispatch(setSelectedFilterSubjects(tags))}
            />
            <Tags
              title={'Industry'}
              placeholder={'All Selected'}
              tags={industries}
              onSelect={(tags) => dispatch(setSelectedFilterIndustries(tags))}
            />
            <Tags
              title={'Color'}
              placeholder={'All Selected'}
              tags={subjects}
              onSelect={(tags) => dispatch(setSelectedFilterSubjects(tags))}
            />
          </S.TagsContainer>
          <S.ButtonsContainer>
            {!isMobile && <GridSizeButton />}
            <SortButton />
          </S.ButtonsContainer>
          <S.ShrinkButton
            onClick={() => setIsBottomSideVisible(!isBottomSideVisible)}
          >
            <S.ShrinkArrowIcon
              $isBottomSideVisible={isBottomSideVisible}
              src={ArrowIcon}
              alt={'Shrink'}
            />
          </S.ShrinkButton>
        </S.Filters>
      </S.BottomSide>
    </S.Wrapper>
  );
};

export default Header;
