export const ZINDEX = {
  header: 10,
  sidebar: 11,
  modal: 12,
};

export const MAX_HEIGHT = {
  header: 1000,
};

export const MIN_HEIGHT = {
  header: 70,
};

export const WIDTH = {
  sidebar: 280,
  header_padding: 80,
  sidebar_padding: 48,
};
