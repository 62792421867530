/** Dependencies */
import React, { FC } from 'react';

/** Features */
import Page from 'features/Page/Page';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

/** Hooks */
import { useDesigns } from 'hooks/UseDesigns';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

const MyPending: FC = () => {
  const { user } = useAuth();

  const noContentTexts = {
    title: 'Pending',
    description:
      'You currently have no pending designs in your queue. Keep an eye out for future opportunities or upload new designs for review.',
    acknowledgment:
      'Thank you for your continued creativity and contributions!',
  };

  const { designs, isPageLoading, loadMoreDesigns, hasMoreData, loadingMore } =
    useDesigns({
      endpoint: `${ENDPOINTS.PENDING_DESIGNS}${ENDPOINTS.MY_DESIGNS(
        user?.Uid
      )}`,
      itemsPerPage: 12,
    });

  const handleLoadMore = async (): Promise<void> => {
    await loadMoreDesigns();
  };

  return (
    <Page
      isContentShown={!!designs?.length}
      noContentTexts={noContentTexts}
      isLoading={isPageLoading}
      designs={designs}
      isAdminCard
      onLoadMore={handleLoadMore}
      hasMoreData={hasMoreData}
      loadingMore={loadingMore}
    />
  );
};

export default MyPending;
