/** Dependencies */
import React, { FC, useEffect } from 'react';

/** Hooks */
import { useAppSelector } from 'hooks/UseAppSelector';

/** Features */
import NoContent from 'features/NoContent/NoContent';

/** Hooks */
import { useAppDispatch } from 'hooks/UseAppDispatch';

/** Store */
import { selectColumnCount } from 'store/slices/Grid.slice';
import { setIsHeaderBottomSideDisabled } from 'store/slices/Header.slice';

/** Styles */
import * as S from 'pages/Error/Error.styled';
import { useLocation } from 'react-router-dom';
import { ROUTE } from 'constants/Routes';

const Error: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const columnCount = useAppSelector(selectColumnCount);

  const isPageFound = Object.values(ROUTE)
    .map((item) => item.toString())
    .includes(location.pathname);

  const noContentTexts = {
    title: '404',
    description: isPageFound
      ? 'This page is under construction, but the future is bright—thank you for your patience!'
      : 'page not found!',
    acknowledgment: '',
  };

  useEffect(() => {
    dispatch(setIsHeaderBottomSideDisabled(false));

    return () => {
      dispatch(setIsHeaderBottomSideDisabled(true));
    };
  }, []);

  return (
    <S.Wrapper $columnCount={columnCount}>
      <NoContent isError texts={noContentTexts} />
    </S.Wrapper>
  );
};

export default Error;
