import styled, { css, keyframes } from 'styled-components';
import { ButtonS } from 'components/Button';

// Animations
const popIn = keyframes`
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const fillBar = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
`;

const bounceIn = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

// Main container
const PopupContainer = styled.div<{ $isDark?: boolean }>`
  ${({ theme: { convertVW, card }, $isDark }) => css`
    display: grid;
    grid-template-columns: ${convertVW(330)} 1fr;
    width: ${convertVW(950)};
    max-width: 95vw;
    max-height: 90vh;
    border-radius: ${convertVW(24)};
    overflow: hidden;
    box-shadow: 0 ${convertVW(25)} ${convertVW(50)} rgba(0, 0, 0, 0.25);
    animation: ${popIn} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    position: relative;
    background: ${$isDark ? card : 'white'};

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
      width: 90%;
      max-width: ${convertVW(500)};
      height: 90vh;
      max-height: ${convertVW(700)};
      overflow-y: auto;
    }
  `}
`;

// Left section
const PopupLeft = styled.div`
  ${({ theme: { convertVW, button, error } }) => css`
    background: linear-gradient(135deg, ${button} 0%, ${error} 100%);
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: radial-gradient(
        circle at 30% 107%,
        rgba(255, 255, 255, 0.13) 0%,
        rgba(255, 255, 255, 0.05) 55%,
        rgba(255, 255, 255, 0.03) 75%
      );
      z-index: 1;
    }

    &::after {
      content: '';
      position: absolute;
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
      background: radial-gradient(
        circle at center,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0) 70%
      );
      z-index: 1;
      opacity: 0.5;
      animation: ${pulse} 15s infinite;
    }

    @media (max-width: 900px) {
      min-height: ${convertVW(300)};
    }
  `}
`;

const BrandContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    align-items: center;
    gap: ${convertVW(8)};
    margin: ${convertVW(40)} ${convertVW(30)} ${convertVW(40)};
    font-size: ${convertVW(18)};
    font-weight: 700;
    position: relative;
    z-index: 2;
    opacity: 0.9;
  `}
`;

const BrandIcon = styled.img`
  ${({ theme: { convertVW } }) => css`
    height: ${convertVW(48)};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

const BrandText = styled.div`
  font-family: 'Plus Jakarta Sans', sans-serif;
`;

const HeaderContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    margin: 0 ${convertVW(30)} ${convertVW(30)};
    position: relative;
    z-index: 2;
  `}
`;

const Title = styled.h2`
  ${({ theme: { convertVW } }) => css`
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: ${convertVW(26)};
    font-weight: 800;
    margin: 0 0 ${convertVW(10)};
    line-height: 1.2;
    letter-spacing: -0.01em;
  `}
`;

const Subtitle = styled.p`
  ${({ theme: { convertVW } }) => css`
    font-size: ${convertVW(15)};
    font-weight: 400;
    margin: 0;
    opacity: 0.85;
  `}
`;

const StatusContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    margin: 0 ${convertVW(30)} ${convertVW(25)};
    background: rgba(255, 255, 255, 0.15);
    padding: ${convertVW(15)};
    border-radius: ${convertVW(12)};
    backdrop-filter: blur(${convertVW(10)});
    position: relative;
    z-index: 2;
  `}
`;

const StatusLabel = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-size: ${convertVW(13)};
    font-weight: 500;
    margin-bottom: ${convertVW(8)};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

const StatusAlert = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: ${convertVW(5)};
    background: rgba(255, 107, 107, 0.3);
    padding: ${convertVW(3)} ${convertVW(8)};
    border-radius: ${convertVW(20)};
    font-size: ${convertVW(12)};
  `}
`;

const AlertIcon = styled.span`
  ${({ theme: { convertVW } }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${convertVW(16)};
    height: ${convertVW(16)};
    background: rgba(255, 107, 107, 0.5);
    border-radius: 50%;
    font-size: ${convertVW(10)};
    font-weight: bold;
  `}
`;

const StatusBar = styled.div`
  ${({ theme: { convertVW } }) => css`
    height: ${convertVW(8)};
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: ${convertVW(10)};
    overflow: hidden;
    position: relative;
  `}
`;

const StatusFill = styled.div`
  ${() => css`
    position: absolute;
    height: 100%;
    width: 100%;
    background: white;
    border-radius: inherit;
    left: 0;
    top: 0;
    animation: ${fillBar} 1.5s ease-out forwards;
  `}
`;

const BenefitContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    margin: auto ${convertVW(30)} ${convertVW(40)};
    position: relative;
    z-index: 2;
  `}
`;

const BenefitTitle = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-size: ${convertVW(14)};
    font-weight: 600;
    margin-bottom: ${convertVW(15)};
    opacity: 0.9;
  `}
`;

const BenefitList = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${convertVW(14)};
  `}
`;

const BenefitItem = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    align-items: center;
    gap: ${convertVW(12)};
  `}
`;

const BenefitIcon = styled.div`
  ${({ theme: { convertVW } }) => css`
    width: ${convertVW(32)};
    height: ${convertVW(32)};
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${convertVW(14)};
    flex-shrink: 0;
  `}
`;

const BenefitText = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-size: ${convertVW(14)};
    opacity: 0.9;
  `}
`;

const BenefitHighlight = styled.span`
  font-weight: 600;
`;

// Right section
const PopupRight = styled.div`
  ${({ theme: { convertVW } }) => css`
    padding: ${convertVW(40)};
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.background};

    @media (max-width: 900px) {
      padding: ${convertVW(30)} ${convertVW(20)};
    }
  `}
`;

const RightTitle = styled.h3`
  ${({ theme: { convertVW } }) => css`
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: ${convertVW(20)};
    font-weight: 700;
    color: ${({ theme }) => theme.text};
    margin-bottom: ${convertVW(5)};
    text-align: center;
  `}
`;

const RightSubtitle = styled.p`
  ${({ theme: { convertVW } }) => css`
    color: ${({ theme }) => theme.secondaryText};
    font-size: ${convertVW(14)};
    text-align: center;
    margin-top: 0;
  `}
`;

// Pricing toggle
const PricingToggleContainer = styled.div<{ $isYearly: boolean }>`
  ${({ theme: { convertVW } }) => css`
    margin: ${convertVW(25)} auto;
    padding: ${convertVW(3)};
    background: ${({ theme }) =>
      theme.card === '#1E1E1E' ? '#252525' : '#f0f0f0'};
    border-radius: ${convertVW(30)};
    display: inline-flex;
    position: relative;
    overflow: hidden;
    box-shadow: 0 ${convertVW(2)} ${convertVW(8)} rgba(0, 0, 0, 0.08);
    border: ${convertVW(1)} solid rgba(0, 0, 0, 0.05);
    width: 100%;
    max-width: ${convertVW(300)};
  `}
`;

const ToggleOption = styled.div<{ $isActive: boolean }>`
  ${({ theme: { convertVW }, $isActive }) => css`
    padding: ${convertVW(10)} ${convertVW(15)};
    font-size: ${convertVW(14)};
    font-weight: 600;
    color: ${$isActive ? 'white' : ({ theme }) => theme.secondaryText};
    cursor: pointer;
    border-radius: ${convertVW(30)};
    position: relative;
    z-index: 2;
    transition: all 0.3s ease;
    text-align: center;
    flex: 1;
    user-select: none;
    text-shadow: ${$isActive
      ? `0 ${convertVW(1)} ${convertVW(2)} rgba(0,0,0,0.1)`
      : 'none'};
  `}
`;

const ToggleBg = styled.div<{ $isYearly: boolean }>`
  ${({ theme: { convertVW }, $isYearly }) => css`
    position: absolute;
    height: calc(100% - ${convertVW(6)});
    top: ${convertVW(3)};
    width: calc(50% - ${convertVW(3)});
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.button} 0%,
      ${({ theme }) => theme.error} 100%
    );
    border-radius: ${convertVW(30)};
    transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    z-index: 1;
    box-shadow: 0 ${convertVW(2)} ${convertVW(8)} rgba(99, 102, 241, 0.3);
    transform: ${$isYearly
      ? `translateX(calc(100% + ${convertVW(3)}))`
      : 'translateX(0)'};
  `}
`;

const YearlyLabelContainer = styled.span`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${convertVW(5)};
    position: relative;
  `}
`;

const SaveLabel = styled.span`
  ${({ theme: { convertVW } }) => css`
    font-size: ${convertVW(9)};
    padding: ${convertVW(2)} ${convertVW(6)};
    background: ${({ theme }) => theme.error};
    color: white;
    border-radius: ${convertVW(10)};
    font-weight: 700;
    box-shadow: 0 ${convertVW(2)} ${convertVW(4)} rgba(255, 107, 107, 0.3);
    position: relative;
    top: ${convertVW(-1)};
    animation: ${pulse} 2s infinite;
    letter-spacing: ${convertVW(0.5)};
  `}
`;

// Plans
const PlansContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    gap: ${convertVW(15)};
    margin-bottom: ${convertVW(25)};

    @media (max-width: 900px) {
      flex-direction: column;
    }
  `}
`;

const PlanCard = styled.div<{ $isPopular?: boolean; $isSelected?: boolean }>`
  ${({ theme: { convertVW }, $isPopular, $isSelected }) => css`
    flex: 1;
    background: ${({ theme }) => theme.card};
    border: ${convertVW(1)} solid
      ${({ theme }) =>
        $isSelected
          ? theme.success
          : $isPopular
          ? theme.button
          : theme.cardBorder};
    border-radius: ${convertVW(14)};
    padding: ${convertVW(20)} ${convertVW($isSelected ? 0 : 20)}
      ${convertVW(20)} ${convertVW($isSelected ? 40 : 20)};
    transition: all 0.3s;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: ${convertVW(4)};
      background: ${({ theme }) =>
        $isSelected
          ? theme.success
          : $isPopular
          ? `linear-gradient(135deg, ${theme.button} 0%, ${theme.error} 100%)`
          : 'transparent'};
      opacity: ${$isPopular && !$isSelected ? 0.5 : 1};
    }

    &:hover {
      border-color: ${({ theme }) =>
        $isSelected ? theme.success : theme.buttonHover};
      transform: translateY(${convertVW(-5)});
      box-shadow: 0 ${convertVW(5)} ${convertVW(20)}
        ${({ theme }) => theme.shadow};
    }

    ${$isPopular &&
    !$isSelected &&
    css`
      background-color: ${({ theme }) =>
        theme.card === '#1E1E1E'
          ? 'rgba(138, 133, 255, 0.05)'
          : 'rgba(99, 102, 241, 0.03)'};
      box-shadow: 0 ${convertVW(5)} ${convertVW(20)} rgba(99, 102, 241, 0.15);
    `}
  `}
`;

const Badge = styled.div<{ $type: 'popular' | 'save' }>`
  ${({ theme: { convertVW }, $type }) => css`
    position: absolute;
    padding: ${convertVW(4)} ${convertVW(10)};
    border-radius: ${convertVW(30)};
    font-size: ${convertVW(10)};
    font-weight: 700;
    box-shadow: 0 ${convertVW(4)} ${convertVW(10)} rgba(0, 0, 0, 0.15);
    z-index: 5;
    transition: transform 0.3s;
    letter-spacing: ${convertVW(0.5)};
    text-transform: uppercase;
    background: ${$type === 'popular'
      ? ({ theme }) => theme.button
      : ({ theme }) => theme.error};
    color: white;

    ${$type === 'popular'
      ? css`
          top: ${convertVW(10)};
          right: ${convertVW(10)};
        `
      : css`
          top: ${convertVW(35)};
          right: ${convertVW(10)};
        `}
  `}
`;

const SelectedMark = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    top: ${convertVW(15)};
    left: ${convertVW(15)};
    width: ${convertVW(24)};
    height: ${convertVW(24)};
    border-radius: 50%;
    background: ${({ theme }) => theme.success};
    color: white;
    font-size: ${convertVW(12)};
    display: flex;
    align-items: center;
    justify-content: center;
    animation: ${bounceIn} 0.5s;
  `}
`;

const PlanTitle = styled.h4`
  ${({ theme: { convertVW } }) => css`
    font-weight: 700;
    font-size: ${convertVW(18)};
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: ${({ theme }) => theme.text};
    letter-spacing: -0.01em;
    line-height: 1.2;
    margin: 0 0 ${convertVW(5)};
  `}
`;

const PlanSubtitle = styled.p`
  ${({ theme: { convertVW } }) => css`
    font-size: ${convertVW(12)};
    color: ${({ theme }) => theme.secondaryText};
    font-weight: 500;
    margin-bottom: ${convertVW(15)};
    margin-top: 0;
  `}
`;

const PlanPrice = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-size: ${convertVW(24)};
    font-weight: 800;
    color: ${({ theme }) => theme.text};
    font-family: 'Plus Jakarta Sans', sans-serif;
    display: flex;
    align-items: baseline;
    letter-spacing: -0.02em;
    margin-bottom: ${convertVW(5)};
  `}
`;

const OriginalPrice = styled.span`
  ${({ theme: { convertVW } }) => css`
    margin-right: ${convertVW(8)};
    font-size: ${convertVW(16)};
    font-weight: 500;
    color: ${({ theme }) => theme.secondaryText};
    text-decoration: line-through;
    opacity: 0.8;
    position: relative;
    top: ${convertVW(-2)};
  `}
`;

const PriceUnit = styled.span`
  ${({ theme: { convertVW } }) => css`
    font-size: ${convertVW(13)};
    font-weight: 500;
    color: ${({ theme }) => theme.secondaryText};
    margin-left: ${convertVW(2)};
  `}
`;

const PlanBilling = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-size: ${convertVW(12)};
    color: ${({ theme }) => theme.secondaryText};
    font-weight: 500;
    margin-bottom: ${convertVW(15)};
  `}
`;

const PlanFeatures = styled.div`
  ${({ theme: { convertVW } }) => css`
    margin-top: ${convertVW(15)};
  `}
`;

const PlanFeature = styled.div<{ $highlight?: boolean }>`
  ${({ theme: { convertVW }, $highlight }) => css`
    font-size: ${convertVW(13)};
    color: ${$highlight
      ? ({ theme }) =>
          theme.card === '#1E1E1E' ? theme.button : theme.buttonHover
      : ({ theme }) => theme.secondaryText};
    position: relative;
    padding-left: ${convertVW(22)};
    line-height: 1.4;
    margin-bottom: ${convertVW(8)};
    font-weight: ${$highlight ? 600 : 400};

    &::before {
      content: '✓';
      position: absolute;
      left: 0;
      color: ${$highlight
        ? ({ theme }) => theme.button
        : ({ theme }) => theme.success};
      font-weight: bold;
      font-size: ${convertVW(14)};
    }
  `}
`;

// CTA Section
const CtaSection = styled.div`
  ${() => css`
    margin-top: auto;
  `}
`;

const CountdownContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    background: linear-gradient(
      to right,
      ${({ theme }) =>
        theme.card === '#1E1E1E'
          ? 'rgba(99, 102, 241, 0.05)'
          : 'rgba(99, 102, 241, 0.05)'},
      ${({ theme }) =>
        theme.card === '#1E1E1E'
          ? 'rgba(255, 107, 107, 0.05)'
          : 'rgba(255, 107, 107, 0.05)'}
    );
    border-radius: ${convertVW(12)};
    padding: ${convertVW(8)} ${convertVW(12)};
    margin-bottom: ${convertVW(20)};
    border: ${convertVW(1)} dashed
      ${({ theme }) =>
        theme.card === '#1E1E1E'
          ? 'rgba(138, 133, 255, 0.2)'
          : 'rgba(99, 102, 241, 0.2)'};
    text-align: center;
  `}
`;

const Countdown = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${convertVW(5)};
    color: ${({ theme }) => theme.text};
    font-size: ${convertVW(13)};
    font-weight: 600;
  `}
`;

const Timer = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    align-items: center;
    gap: ${convertVW(4)};
    margin-left: ${convertVW(5)};
  `}
`;

const TimeUnit = styled.span`
  ${({ theme: { convertVW } }) => css`
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.button} 0%,
      ${({ theme }) => theme.error} 100%
    );
    color: white;
    padding: ${convertVW(4)} ${convertVW(7)};
    border-radius: ${convertVW(6)};
    font-weight: 700;
    min-width: ${convertVW(20)};
    text-align: center;
    box-shadow: 0 ${convertVW(3)} ${convertVW(10)} rgba(0, 0, 0, 0.15);
    position: relative;
    overflow: hidden;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: ${convertVW(14)};

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.15),
        rgba(255, 255, 255, 0)
      );
      border-radius: ${convertVW(6)};
    }
  `}
`;

const TimeSeparator = styled.span`
  ${({ theme: { convertVW } }) => css`
    color: ${({ theme }) => theme.button};
    font-weight: 700;
    font-size: ${convertVW(14)};
  `}
`;

const SupportText = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${convertVW(6)};
    font-size: ${convertVW(12)};
    color: ${({ theme }) => theme.secondaryText};
    margin: ${convertVW(15)} 0 ${convertVW(20)};
    font-weight: 500;
  `}
`;

const GuaranteeIcon = styled.span`
  ${({ theme: { convertVW } }) => css`
    color: ${({ theme }) => theme.success};
    font-size: ${convertVW(14)};
  `}
`;

const NoThanksButton = styled.button`
  ${({ theme: { convertVW } }) => css`
    background: transparent;
    color: ${({ theme }) => theme.secondaryText};
    padding: ${convertVW(8)};
    border: none;
    font-size: ${convertVW(13)};
    cursor: pointer;
    transition: all 0.3s;
    text-decoration: underline;
    opacity: 0.7;
    font-weight: 500;
    width: fit-content;
    margin: 0 auto;
    display: block;

    &:hover {
      color: ${({ theme }) => theme.text};
      opacity: 1;
    }
  `}
`;

// Legacy exports for backward compatibility
const CopyLimitModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CopyLimitModalParagraph = styled.p``;

const CopyLimitButtonsContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    gap: ${convertVW(8)};
    margin-top: ${convertVW(24)};
    margin-bottom: ${convertVW(-19.2)};

    ${ButtonS.Wrapper} {
      border: none;
    }
  `}
`;

// Close button
const CloseButton = styled.button`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    top: ${convertVW(15)};
    right: ${convertVW(15)};
    width: ${convertVW(32)};
    height: ${convertVW(32)};
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
    transition: background 0.2s;
    color: #333;
    font-size: ${convertVW(16)};

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }

    &:focus {
      outline: none;
    }
  `}
`;

export {
  // Main containers
  PopupContainer,
  PopupLeft,
  PopupRight,

  // Left section components
  BrandContainer,
  BrandIcon,
  BrandText,
  HeaderContainer,
  Title,
  Subtitle,
  StatusContainer,
  StatusLabel,
  StatusAlert,
  AlertIcon,
  StatusBar,
  StatusFill,
  BenefitContainer,
  BenefitTitle,
  BenefitList,
  BenefitItem,
  BenefitIcon,
  BenefitText,
  BenefitHighlight,

  // Right section components
  RightTitle,
  RightSubtitle,
  PricingToggleContainer,
  ToggleOption,
  ToggleBg,
  YearlyLabelContainer,
  SaveLabel,
  PlansContainer,
  PlanCard,
  Badge,
  SelectedMark,
  PlanTitle,
  PlanSubtitle,
  PlanPrice,
  OriginalPrice,
  PriceUnit,
  PlanBilling,
  PlanFeatures,
  PlanFeature,
  CtaSection,
  CountdownContainer,
  Countdown,
  Timer,
  TimeUnit,
  TimeSeparator,
  SupportText,
  GuaranteeIcon,
  NoThanksButton,

  // Legacy exports
  CopyLimitModalWrapper as Wrapper,
  CopyLimitModalParagraph as Paragraph,
  CopyLimitButtonsContainer as ButtonsContainer,

  // Close button
  CloseButton,
};
