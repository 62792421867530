/** Dependencies */
import React, {
  ChangeEvent,
  ClipboardEvent,
  FC,
  KeyboardEvent,
  MouseEvent,
} from 'react'; /** Styles */
import * as S from './Input.styled';

interface IProps {
  name?: string;
  defaultValue?: string | string[];
  placeholder?: string;
  type?: string;
  accept?: string;
  isError?: boolean;
  icon?: string;
  isIconShown?: boolean;
  disabled?: boolean;
  isMultiple?: boolean;
  isSelectedValuesShown?: boolean;
  onMultiSelectObjectClick?: (vl: string) => void;
  onPaste?: (e: ClipboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onPasteTextArea?: (e: ClipboardEvent<HTMLTextAreaElement>) => void;
  onKeyDownTextArea?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
  onChangeTextArea?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const Input: FC<IProps> = ({
  name,
  defaultValue = '',
  placeholder = '',
  type = '',
  accept = '',
  icon = '',
  isError = false,
  isIconShown = false,
  isMultiple = false,
  isSelectedValuesShown = true,
  disabled = false,
  onMultiSelectObjectClick,
  onPaste = () => ({}),
  onKeyDown = () => ({}),
  onChange = () => ({}),
  onPasteTextArea = () => ({}),
  onKeyDownTextArea = () => ({}),
  onChangeTextArea = () => ({}),
}) => {
  const handleMultiSelectObjectClick = (
    e: MouseEvent<HTMLDivElement>,
    vl: string
  ): void => {
    e.preventDefault();
    e.stopPropagation();

    onMultiSelectObjectClick && onMultiSelectObjectClick(vl);
  };

  return (
    <S.Wrapper>
      {name && <S.Label htmlFor={name}>{name}</S.Label>}
      {isMultiple ? (
        <S.MultiSelect>
          <S.MultiSelectObjects>
            {Array.isArray(defaultValue) &&
              (defaultValue.length > 0 ? (
                !isSelectedValuesShown ? (
                  <S.Placeholder>{defaultValue.length} Selected</S.Placeholder>
                ) : (
                  defaultValue.map((vl, i) => (
                    <S.MultiSelectObject
                      onClick={(e) => handleMultiSelectObjectClick(e, vl)}
                      key={i}
                    >
                      <S.MultiSelectObjectDelete>X</S.MultiSelectObjectDelete>
                      {vl}
                    </S.MultiSelectObject>
                  ))
                )
              ) : (
                <S.Placeholder>{placeholder}</S.Placeholder>
              ))}
          </S.MultiSelectObjects>
        </S.MultiSelect>
      ) : type === 'textarea' ? (
        <S.TextArea
          id={name}
          value={defaultValue}
          placeholder={placeholder}
          $isError={isError}
          onPaste={(e) => onPasteTextArea(e)}
          onKeyDown={(e) => onKeyDownTextArea(e)}
          onChange={onChangeTextArea}
        />
      ) : (
        <S.Text
          id={name}
          disabled={disabled}
          value={defaultValue}
          placeholder={placeholder}
          $isError={isError}
          type={type}
          onPaste={(e) => onPaste(e)}
          onKeyDown={(e) => onKeyDown(e)}
          onChange={onChange}
          accept={accept}
        />
      )}
      {isIconShown && <S.Icon src={icon} alt={'arrow'} />}
    </S.Wrapper>
  );
};

export default Input;
