import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${() => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: var(--background);
  `}
`;

export const ThemeToggleContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: absolute;
    top: ${convertVW(20)};
    right: ${convertVW(20)};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${convertVW(8)};
    z-index: 10;
  `}
`;

export const LogoWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${convertVW(40)};
  `}
`;

export const Image = styled.img`
  ${({ theme: { convertVW } }) => css`
    height: ${convertVW(48)};
  `}
`;

export const Content = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${convertVW(20)};
    width: 100%;
    max-width: ${convertVW(400)};
    padding: ${convertVW(30)};
    background-color: var(--card);
    border-radius: ${convertVW(12)};
    box-shadow: 0 ${convertVW(4)} ${convertVW(20)} var(--shadow);
    border: ${convertVW(1)} solid var(--border);
  `}
`;

export const Title = styled.h1`
  ${({ theme: { convertVW } }) => css`
    font-size: ${convertVW(24)};
    font-weight: 600;
    color: var(--text);
    margin-bottom: ${convertVW(16)};
    text-align: center;
  `}
`;

export const Description = styled.p`
  ${({ theme: { convertVW } }) => css`
    font-size: ${convertVW(14)};
    color: var(--secondary-text);
    margin-bottom: ${convertVW(24)};
    text-align: center;
  `}
`;

export const ButtonsContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${convertVW(12)};
    width: 100%;
  `}
`;
