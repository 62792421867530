import styled, { css } from 'styled-components';
import { BreakPoint } from 'constants/BreakPoint';
import { darkTheme } from 'App.theme';

const ProfileWrapper = styled.div<{
  $isMaximized: boolean;
}>`
  ${({ theme: { convertVW }, $isMaximized }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: ${convertVW(8)} ${$isMaximized ? '0' : convertVW(2)};
    border-radius: ${convertVW(6)};
    transition: background-color var(--transition-speed-fast)
      var(--transition-timing);
    cursor: pointer;

    &:hover {
      background-color: var(--input);
    }

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      gap: ${convertVW(8)};
    }
  `}
`;

const ProfileImageWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: relative;
    width: ${convertVW(36)};
    height: ${convertVW(36)};
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--tab-border);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    color: var(--button-text);
    font-size: ${convertVW(18)};
    font-weight: 500;
  `}
`;

const ProfileImage = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ProfileSettings = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${convertVW(8)};
    cursor: pointer;
    font-family: 'DM Sans', sans-serif;
    font-size: ${convertVW(12)};
    line-height: ${convertVW(16)};
    font-weight: 500;
    color: var(--secondary-text);
    word-spacing: 0;
    transition: color var(--transition-speed-fast) var(--transition-timing);

    &:hover {
      color: var(--text);
      text-decoration: underline;
    }

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      display: none;
    }
  `}
`;

const ProfileContentWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: ${convertVW(2)};
    flex: 1;
    overflow: hidden;
    margin: 0 ${convertVW(12)};
  `}
`;

const ProfileSalute = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-family: 'DM Sans', sans-serif;
    font-size: ${convertVW(14)};
    line-height: ${convertVW(18)};
    font-weight: 500;
    color: var(--text);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      display: none;
    }
  `}
`;

const ProfileSettingsIcon = styled.img`
  ${({ theme }) => css`
    width: ${theme.convertVW(20)};
    height: ${theme.convertVW(20)};
    opacity: 0.6;
    transition: opacity var(--transition-speed-fast) var(--transition-timing),
      transform var(--transition-speed-fast) var(--transition-timing);
    margin-left: auto;
    filter: ${theme.background === darkTheme.background
      ? 'brightness(1.5)'
      : 'none'};

    ${ProfileWrapper}:hover & {
      opacity: 0.9;
      transform: rotate(15deg);
    }

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      height: ${theme.convertVW(24)};
    }
  `}
`;

const ProfileSignInWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${convertVW(6)};
    cursor: pointer;

    font-family: 'DM Sans', sans-serif;
    font-size: ${convertVW(12)};
    line-height: ${convertVW(16)};
    font-weight: 500;
    color: var(--secondary-text);
    transition: color var(--transition-speed-fast) var(--transition-timing);

    &:hover {
      color: var(--text);
    }

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      font-size: ${convertVW(10)};

      &:hover {
        text-decoration: none;
      }
    }
  `}
`;

export {
  ProfileWrapper as Wrapper,
  ProfileImageWrapper as ImageWrapper,
  ProfileImage as Image,
  ProfileContentWrapper as ContentWrapper,
  ProfileSalute as Salute,
  ProfileSettings as Settings,
  ProfileSettingsIcon as SettingsIcon,
  ProfileSignInWrapper as SignInWrapper,
};
