/** Dependencies */
import React, { FC } from 'react';
import { SkeletonStyleProps } from 'react-loading-skeleton';

/** Hooks */
import { useAppSelector } from 'hooks/UseAppSelector';

/** Store */
import { selectColumnCount } from 'store/slices/Grid.slice';

/** Styles */
import * as S from './SkeletonCard.styled';

const SkeletonCard: FC<SkeletonStyleProps> = (skeletonStyleProps) => {
  const columnCount = useAppSelector(selectColumnCount);

  return (
    <S.Wrapper $columnCount={columnCount}>
      <S.Image {...skeletonStyleProps} />
    </S.Wrapper>
  );
};

export default SkeletonCard;
