/** Dependencies */
import React, { FC, useEffect } from 'react';

/** Hooks */
import { useAppSelector } from 'hooks/UseAppSelector';
import { useAppDispatch } from 'hooks/UseAppDispatch';
import { useDeviceDetect } from 'hooks/UseDeviceDetect';

/** Store */
import {
  incrementColumCount,
  selectColumnCount,
  setColumCount,
} from 'store/slices/Grid.slice';

/** Styles */
import * as S from 'features/GridSizeButton/GridSizeButton.styled';

const GridSizeButton: FC = () => {
  const dispatch = useAppDispatch();
  const { isMobile } = useDeviceDetect();

  const columnCount = useAppSelector(selectColumnCount);

  const handleClick = (): void => {
    dispatch(incrementColumCount());
  };

  useEffect(() => {
    if (isMobile) dispatch(setColumCount(4));
  }, [isMobile]);

  return (
    <S.Wrapper onClick={handleClick}>
      <S.Label>Grid Size:</S.Label>
      <S.Columns>
        {Array.from(Array(columnCount).keys()).map((_, i) => (
          <S.Column key={i} />
        ))}
      </S.Columns>
    </S.Wrapper>
  );
};

export default GridSizeButton;
