/** Dependencies */
import React, { ReactNode } from 'react';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

/** Images */
/** Constants */
import { PLANS } from 'constants/Plans';

/** Components */
import Loading from 'components/Loading/Loading';
import Login from 'features/Login';

/** Styles */

interface IPlan {
  label: string;
  uid: string;
}

function hasCorrectPlan(plans: IPlan[], user: any): boolean {
  if (user) {
    const planIdForUser = user?.Account?.CurrentSubscription?.Plan?.Uid;
    return plans.some((plan) => plan.uid === planIdForUser);
  } else {
    return false;
  }
}

export default function ProtectedRoute({
  plans,
  children,
}: {
  plans?: IPlan[];
  children: ReactNode | ReactNode[];
}): Element | any {
  const { user, isLoading } = useAuth();

  // Pro routes only accessible with pro plan
  // Basic routes accessible with basic or pro plan
  const plansWithAccess = plans
    ? plans
    : [PLANS.BASIC, PLANS.PRO, PLANS.BASIC_DESIGNER, PLANS.ADMIN];
  const allowAccess = hasCorrectPlan(plansWithAccess, user);
  const isDashboardPage =
    hasCorrectPlan([PLANS.BASIC_DESIGNER], user) && Array.isArray(children);
  const isPanelPage =
    hasCorrectPlan([PLANS.ADMIN], user) && Array.isArray(children);

  if (isLoading) return <Loading />;

  if (allowAccess) {
    return isDashboardPage
      ? children[2]
      : isPanelPage
      ? children[1]
      : Array.isArray(children)
      ? children[0]
      : children;
  } else {
    return <Login />;
  }
}
