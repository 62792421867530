import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/App.store';

type ModalContentConfig = {
  type: string;
  props: Record<string, any> | undefined;
};

interface IModalState {
  isVisible: boolean;
  isLoading: boolean;
  isConfirmed: boolean;
  title: string;
  content: ModalContentConfig | null;
}

const initialState: IModalState = {
  isVisible: false,
  isLoading: false,
  isConfirmed: false,
  title: '',
  content: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    hideModal: (state) => {
      Object.assign(state, initialState);
    },
    setModal: (state, action) => {
      state.isVisible = action.payload.isVisible;
      state.title = action.payload.title;
      state.content = action.payload.content;
    },
    confirmModal: (state, action) => {
      state.isConfirmed = action.payload;
    },
    setIsLoadingModal: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export type { ModalContentConfig };

export const { hideModal, setModal, confirmModal, setIsLoadingModal } =
  modalSlice.actions;

export const selectModal = (state: RootState): IModalState => state.modal;

export default modalSlice.reducer;
