import styled, { css, keyframes } from 'styled-components';
import { ZINDEX } from 'constants/Style';
import { SPACING } from 'constants/Spacing';
import { BreakPoint } from 'constants/BreakPoint';

// Animation for the shadow container
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Animation for the logo
const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
`;

// Animation for the title
const slideUp = keyframes`
  from {
    transform: translateY(${SPACING.xs}px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Button hover effect
const buttonGlow = keyframes`
  0% {
    box-shadow: 0 0 5px rgba(162, 109, 247, 0.5);
  }
  50% {
    box-shadow: 0 0 15px rgba(162, 109, 247, 0.8);
  }
  100% {
    box-shadow: 0 0 5px rgba(162, 109, 247, 0.5);
  }
`;

// Preserving the original positioning structure while adding visual enhancements
const NoContentShadow = styled.div<{
  $isSidebarMaximized: boolean;
}>`
  ${({ theme: { convertVW, sidebar_bg } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: ${convertVW(24)};
    position: fixed;
    left: 0;
    bottom: ${convertVW(-1)};
    width: calc(100%);
    height: 100%;
    z-index: ${ZINDEX.header - 1};
    box-shadow: 0 ${convertVW(-240)} ${convertVW(240)} 0 ${sidebar_bg} inset;
    transition: left 0.5s, width 0.5s;
    animation: ${fadeIn} 0.8s ease-out forwards;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      padding: 0 ${convertVW(SPACING.md)};
    }
  `}
`;

const NoContentShadowLogoWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    width: ${convertVW(240)};
    height: ${convertVW(240)};
    animation: ${pulse} 4s ease-in-out infinite;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      width: ${convertVW(180)};
      height: ${convertVW(180)};
    }

    @media only screen and (max-width: ${BreakPoint.SmallMobile}px) {
      width: ${convertVW(140)};
      height: ${convertVW(140)};
    }
  `}
`;

const NoContentShadowImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const NoContentShadowTitle = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: ${convertVW(72)};
    color: #ed724a;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    animation: ${slideUp} 0.6s ease-out forwards;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      font-size: ${convertVW(48)};
    }

    @media only screen and (max-width: ${BreakPoint.SmallMobile}px) {
      font-size: ${convertVW(36)};
    }
  `}
`;

const NoContentShadowDescription = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: ${convertVW(32)};
    text-align: center;
    width: 75%;
    color: #ed724a;
    animation: ${slideUp} 0.8s ease-out 0.2s both;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      font-size: ${convertVW(24)};
      width: 90%;
    }

    @media only screen and (max-width: ${BreakPoint.SmallMobile}px) {
      font-size: ${convertVW(18)};
      width: 95%;
    }
  `}
`;

const NoContentShadowAcknowledgment = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: ${convertVW(32)};
    color: #a26df7;
    font-weight: 600;
    text-align: center;
    animation: ${slideUp} 1s ease-out 0.4s both;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      font-size: ${convertVW(20)};
    }

    @media only screen and (max-width: ${BreakPoint.SmallMobile}px) {
      font-size: ${convertVW(16)};
    }
  `}
`;

const NoContentRetryButton = styled.button`
  ${({ theme: { convertVW } }) => css`
    margin-top: ${convertVW(SPACING.xl)};
    padding: ${convertVW(SPACING.sm)} ${convertVW(SPACING.xl)};
    background: linear-gradient(135deg, #a26df7 0%, #8a4fe0 100%);
    color: white;
    border: none;
    border-radius: ${convertVW(SPACING.xs * 2)};
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    font-size: ${convertVW(16)};
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    animation: ${slideUp} 1.2s ease-out 0.6s both;

    &:hover {
      transform: translateY(-${convertVW(2)});
      animation: ${buttonGlow} 2s infinite;
    }

    &:active {
      transform: translateY(${convertVW(1)});
    }

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      font-size: ${convertVW(14)};
      padding: ${convertVW(SPACING.sm)} ${convertVW(SPACING.lg)};
    }
  `}
`;

export {
  NoContentShadow as Shadow,
  NoContentShadowTitle as ShadowTitle,
  NoContentShadowDescription as ShadowDescription,
  NoContentShadowAcknowledgment as ShadowAcknowledgment,
  NoContentShadowLogoWrapper as ShadowLogoWrapper,
  NoContentShadowImage as ShadowImage,
  NoContentRetryButton as RetryButton,
};
