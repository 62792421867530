/** Dependencies */
import React, { ReactElement, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Toaster } from 'react-hot-toast';

/** Components */
import Modal from 'components/Modal/Modal';

/** Features */
import Header from 'features/Header/Header';
import Sidebar from 'features/Sidebar/Sidebar';
import ProtectedRoute from 'features/ProtectedRoute/ProtectedRoute';

/** Pages */
import MyPending from 'pages/MyPending/MyPending';
import Pending from 'pages/Pending/Pending';
import MyApproved from 'pages/MyApproved/MyApproved';
import MyDisapproved from 'pages/MyDisapproved/MyDisapproved';
import Transact from 'pages/Transact/Transact';
import Approved from 'pages/Approved/Approved';
import Disapproved from 'pages/Disapproved/Disapproved';
import Emails from 'pages/Emails/Emails';
import Upload from 'pages/Upload/Upload';
import Dashboard from 'pages/Dashboard/Dashboard';
import Archive from 'pages/Archive/Archive';
import Saved from 'pages/Saved/Saved';
import Error from 'pages/Error/Error';

/** Constants */
import { ROUTE } from 'constants/Routes';
import { PLANS } from 'constants/Plans';

/** Hooks */
import { useMetricConverter } from 'hooks/UseMetricConverter';
import { useAppSelector } from 'hooks/UseAppSelector';
import { useTheme } from 'hooks/UseTheme';

/** Store */
import {
  selectIsSidebarMaximized,
  selectIsSidebarVisible,
} from 'store/slices/Sidebar.slice';

/** Outseta */
import AuthProvider from 'outseta/AuthProvider';

/** Themes */
import { darkTheme, lightTheme } from 'App.theme';
import { setThemeVariables } from 'ThemeVariables';

/** Styles */
import * as S from 'App.styled';

// Route transition wrapper component
const AnimatedRoutes = (): ReactElement => {
  const location = useLocation();

  return (
    <S.PageTransition key={location.pathname}>
      <Routes location={location}>
        <Route
          element={
            <ProtectedRoute
              plans={[
                PLANS.FREE,
                PLANS.BASIC,
                PLANS.PRO,
                PLANS.BASIC_DESIGNER,
                PLANS.ADMIN,
              ]}
            >
              {[
                <Emails key={0} />,
                <Approved key={2} />,
                <Dashboard key={1} />,
              ]}
            </ProtectedRoute>
          }
          path={ROUTE.ROOT}
        />
        <Route
          element={
            <ProtectedRoute
              plans={[PLANS.PRO, PLANS.BASIC, PLANS.FREE, PLANS.ADMIN]}
            >
              <Saved />
            </ProtectedRoute>
          }
          path={ROUTE.MY_SAVED_LIBRARY}
        />
        <Route
          element={
            <ProtectedRoute plans={[PLANS.ADMIN]}>
              <Disapproved />
            </ProtectedRoute>
          }
          path={ROUTE.DISAPPROVED_DESIGNS}
        />
        <Route
          element={
            <ProtectedRoute plans={[PLANS.ADMIN]}>
              <Pending />
            </ProtectedRoute>
          }
          path={ROUTE.PENDING_DESIGNS}
        />
        <Route
          element={
            <ProtectedRoute plans={[PLANS.ADMIN]}>
              <Archive />
            </ProtectedRoute>
          }
          path={ROUTE.ARCHIVE}
        />
        <Route
          element={
            <ProtectedRoute plans={[PLANS.ADMIN]}>
              <Transact />
            </ProtectedRoute>
          }
          path={ROUTE.TRANSACT}
        />
        <Route
          element={
            <ProtectedRoute plans={[PLANS.BASIC_DESIGNER, PLANS.ADMIN]}>
              <Upload />
            </ProtectedRoute>
          }
          path={ROUTE.EDIT_TYPE}
        />
        <Route
          element={
            <ProtectedRoute plans={[PLANS.BASIC_DESIGNER]}>
              <MyApproved />
            </ProtectedRoute>
          }
          path={ROUTE.MY_APPROVED_DESIGNS}
        />
        <Route
          element={
            <ProtectedRoute plans={[PLANS.BASIC_DESIGNER]}>
              <MyDisapproved />
            </ProtectedRoute>
          }
          path={ROUTE.MY_DISAPPROVED_DESIGNS}
        />
        <Route
          element={
            <ProtectedRoute plans={[PLANS.BASIC_DESIGNER]}>
              <MyPending />
            </ProtectedRoute>
          }
          path={ROUTE.MY_PENDING_DESIGNS}
        />
        <Route
          element={
            <ProtectedRoute plans={[PLANS.BASIC_DESIGNER]}>
              <Upload />
            </ProtectedRoute>
          }
          path={ROUTE.UPLOAD}
        />
        <Route path="*" element={<Error />} />
      </Routes>
    </S.PageTransition>
  );
};

function App(): ReactElement {
  const { convertVW, convertPxToVh } = useMetricConverter();
  const { isDarkMode } = useTheme();

  const isSidebarMaximized = useAppSelector(selectIsSidebarMaximized);
  const isSidebarVisible = useAppSelector(selectIsSidebarVisible);

  const ToastOptions = {
    duration: 3000,
    style: {
      background: isDarkMode ? darkTheme.card : lightTheme.card,
      color: isDarkMode ? darkTheme.text : lightTheme.text,
      borderRadius: convertVW(8),
      boxShadow: `0 ${convertVW(4)} ${convertVW(12)} ${
        isDarkMode ? darkTheme.shadow : lightTheme.shadow
      }`,
    },
  };

  useEffect(() => {
    setThemeVariables(isDarkMode);
  }, [isDarkMode]);

  return (
    <ThemeProvider
      theme={{
        ...(isDarkMode ? darkTheme : lightTheme),
        convertVW,
        convertPxToVh,
      }}
    >
      <S.Wrapper
        $isSidebarVisible={isSidebarVisible}
        $isSidebarMaximized={isSidebarMaximized}
      >
        <BrowserRouter>
          <AuthProvider>
            <Toaster toastOptions={ToastOptions} />
            <Modal />
            <Sidebar />
            <Header />
            <AnimatedRoutes />
          </AuthProvider>
        </BrowserRouter>
      </S.Wrapper>
    </ThemeProvider>
  );
}

export default App;
