import { useEffect } from 'react';
import { useAppSelector } from './UseAppSelector';
import { useAppDispatch } from './UseAppDispatch';
import {
  selectThemeIsDark,
  selectUseSystemTheme,
  setThemeIsDark,
  setUseSystemTheme,
} from 'store/slices/Sidebar.slice';
import {
  getSystemThemePreference,
  listenToSystemThemeChanges,
} from 'utilities/systemTheme';

interface IReturnObject {
  toggleTheme: () => void;
  isDarkMode: boolean;
  setDarkMode: (isDark: boolean) => void;
  useSystemTheme: boolean;
  setUseSystemThemePreference: (useSystem: boolean) => void;
}

// Helper to check if we're in a browser environment
const isBrowser = typeof window !== 'undefined';

export const useTheme = (): IReturnObject => {
  const dispatch = useAppDispatch();
  const isDarkMode = useAppSelector(selectThemeIsDark);
  const shouldUseSystemTheme = useAppSelector(selectUseSystemTheme);

  // Set up effect to listen for system theme changes
  useEffect(() => {
    // Only apply system theme if the setting is enabled and we're in a browser
    if (shouldUseSystemTheme && isBrowser) {
      // Set initial theme based on system preference
      dispatch(setThemeIsDark(getSystemThemePreference()));

      // Set up listener for system theme changes
      const removeListener = listenToSystemThemeChanges((prefersDark) => {
        dispatch(setThemeIsDark(prefersDark));
      });

      // Clean up listener on unmount or when setting changes
      return removeListener;
    }
  }, [shouldUseSystemTheme, dispatch]);

  const toggleTheme = (): void => {
    dispatch(setThemeIsDark(!isDarkMode));
  };

  const setDarkMode = (isDark: boolean): void => {
    dispatch(setThemeIsDark(isDark));
  };

  const setUseSystemThemePreference = (useSystem: boolean): void => {
    dispatch(setUseSystemTheme(useSystem));

    // If enabling system theme, immediately apply system preference
    if (useSystem && isBrowser) {
      dispatch(setThemeIsDark(getSystemThemePreference()));
    }
  };

  return {
    isDarkMode,
    toggleTheme,
    setDarkMode,
    useSystemTheme: shouldUseSystemTheme,
    setUseSystemThemePreference,
  };
};
