import styled, { css } from 'styled-components';

const GridSizeButtonWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${convertVW(10)};
    width: ${convertVW(152)};
    cursor: pointer;
  `}
`;

const GridSizeButtonColumns = styled.div`
  ${({ theme: { convertVW, border, card, shadow, text } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${convertVW(6)};
    height: ${convertVW(46)};
    padding: 0 ${convertVW(6)};
    border: ${convertVW(1)} solid ${border};
    border-radius: ${convertVW(6)};
    transition: all var(--transition-speed-fast) var(--transition-timing);
    background-color: ${card};
    color: ${text};

    &:hover {
      box-shadow: 0 ${convertVW(2)} ${convertVW(6)} ${shadow};
      transform: translateY(-1px);
    }

    &:active {
      transform: translateY(1px);
      box-shadow: none;
    }
  `}
`;

const GridSizeButtonLabel = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(14)};
    line-height: ${convertVW(17)};
    color: var(--tab-text);
    user-select: none;
  `}
`;

const GridSizeButtonColumn = styled.div`
  ${({ theme: { convertVW } }) => css`
    width: ${convertVW(8)};
    height: ${convertVW(24)};
    border: 1px solid var(--tab-text);
    transition: background-color var(--transition-speed-fast)
      var(--transition-timing);
    border-radius: ${convertVW(3)};

    ${GridSizeButtonColumns}:hover & {
      opacity: 0.8;
    }
  `}
`;

export {
  GridSizeButtonWrapper as Wrapper,
  GridSizeButtonColumns as Columns,
  GridSizeButtonColumn as Column,
  GridSizeButtonLabel as Label,
};
