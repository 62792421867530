/** Dependencies */
import React, { FC } from 'react';

/** Features */
import Page from 'features/Page/Page';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

/** Hooks */
import { useDesigns } from 'hooks/UseDesigns';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

const MyDisapproved: FC = () => {
  const { user } = useAuth();

  const noContentTexts = {
    title: 'Disapproved',
    description:
      'You currently have no disapproved designs. Keep up the great work, and continue submitting your creative ideas!',
    acknowledgment: '',
  };

  const { designs, isPageLoading, loadMoreDesigns, hasMoreData, loadingMore } =
    useDesigns({
      endpoint: `${ENDPOINTS.DISAPPROVED_DESIGNS}${ENDPOINTS.MY_DESIGNS(
        user?.Uid
      )}`,
      itemsPerPage: 12,
    });

  const handleLoadMore = async (): Promise<void> => {
    await loadMoreDesigns();
  };

  return (
    <Page
      isContentShown={!!designs?.length}
      noContentTexts={noContentTexts}
      isLoading={isPageLoading}
      designs={designs}
      isAdminCard
      onLoadMore={handleLoadMore}
      hasMoreData={hasMoreData}
      loadingMore={loadingMore}
    />
  );
};

export default MyDisapproved;
