/** Dependencies */
import React, { FC } from 'react';

/** Features */
import Page from 'features/Page/Page';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

/** Hooks */
import { useDesigns } from 'hooks/UseDesigns';

const Pending: FC = () => {
  const noContentTexts = {
    title: 'Pending',
    description: 'There is currently no pending designs in the queue.',
    acknowledgment: '',
  };

  const {
    designs,
    isPageLoading,
    getLikes,
    getDesigns,
    loadMoreDesigns,
    hasMoreData,
    loadingMore,
  } = useDesigns({
    endpoint: ENDPOINTS.PENDING_DESIGNS,
    itemsPerPage: 12,
  });

  const handleLoadMore = async (): Promise<void> => {
    await loadMoreDesigns();
  };

  return (
    <Page
      designs={designs}
      noContentTexts={noContentTexts}
      isLoading={isPageLoading}
      isContentShown={!!designs?.length}
      isAdminCard
      onLoadMore={handleLoadMore}
      hasMoreData={hasMoreData}
      loadingMore={loadingMore}
      refreshDesigns={getDesigns}
      refreshLikes={getLikes}
    />
  );
};

export default Pending;
