/**
 * Detect the user's system theme preference
 * @returns boolean - true if dark mode is preferred, false otherwise
 */
export const getSystemThemePreference = (): boolean => {
  // Check if we're in a browser environment
  if (typeof window === 'undefined') {
    return false;
  }

  // Check if the user's system prefers dark mode
  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    return true;
  }

  // Default to light mode if no preference is detected
  return false;
};

/**
 * Sets up a listener for system theme changes
 * @param callback Function to call when the system theme changes
 * @returns function to remove the listener
 */
export const listenToSystemThemeChanges = (
  callback: (isDarkMode: boolean) => void
): (() => void) => {
  // Check if we're in a browser environment
  if (typeof window === 'undefined') {
    return () => null; // Empty cleanup function for SSR
  }

  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

  // Define the event listener
  const handleChange = (e: MediaQueryListEvent): void => {
    callback(e.matches);
  };

  // Add the listener
  if (mediaQuery.addEventListener) {
    mediaQuery.addEventListener('change', handleChange);
  } else {
    // For older browsers
    mediaQuery.addListener(handleChange);
  }

  // Return a function to remove the listener
  return () => {
    if (mediaQuery.removeEventListener) {
      mediaQuery.removeEventListener('change', handleChange);
    } else {
      // For older browsers
      mediaQuery.removeListener(handleChange);
    }
  };
};
