import styled, { css } from 'styled-components';
import { BreakPoint } from 'constants/BreakPoint';
import { SPACING } from 'constants/Spacing';
import TickIcon from 'assets/images/svg/tick.svg';
import TickIconDark from 'assets/images/svg/dark/tick-dark.svg';

const SelectWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: ${convertVW(SPACING.sm)};
    width: 100%;
    cursor: pointer;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      gap: ${convertVW(SPACING.sm + 4)};
    }
  `}
`;

const SelectList = styled.div`
  ${({ theme: { convertVW, card, border, shadow } }) => css`
    position: absolute;
    top: calc(100% + ${convertVW(SPACING.xs)});
    left: 0;
    width: 100%;
    max-height: ${convertVW(200)};
    border-radius: ${convertVW(SPACING.xs + 2)};
    border: ${convertVW(1)} solid ${border};
    background-color: ${card};
    overflow: auto;
    z-index: 1;
    transition: background-color var(--transition-speed)
        var(--transition-timing),
      border-color var(--transition-speed) var(--transition-timing),
      box-shadow var(--transition-speed) var(--transition-timing);
    box-shadow: 0 ${convertVW(SPACING.xs)} ${convertVW(SPACING.sm)} ${shadow};

    /* Improve scrollbar styling */

    &::-webkit-scrollbar {
      width: ${convertVW(SPACING.sm)};
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${border};
      border-radius: ${convertVW(SPACING.xs)};
      border: ${convertVW(2)} solid ${card};
    }

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      position: relative;
      top: 0;
    }
  `}
`;

const SelectListItem = styled.div<{ $isSelected: boolean }>`
  ${({ theme: { convertVW, text, border, background }, $isSelected }) => css`
    position: relative;
    user-select: none;
    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(11)};
    line-height: ${convertVW(20)};
    color: ${text};
    padding: ${convertVW(SPACING.sm + 4)} ${convertVW(SPACING.xl)};
    transition: background-color var(--transition-speed-fast)
        var(--transition-timing),
      color var(--transition-speed-fast) var(--transition-timing);

    &:before {
      content: '';
      display: ${$isSelected ? '' : 'none'};
      background: url(${background === '#121212' || background === '#343434'
          ? TickIconDark
          : TickIcon})
        no-repeat;
      background-size: contain;
      position: absolute;
      right: ${convertVW(SPACING.xl)};
      top: 50%;
      transform: translateY(-50%);
      width: ${convertVW(15)};
      height: ${convertVW(15)};
      transition: background-image var(--transition-speed)
        var(--transition-timing);
    }

    &:hover {
      background-color: ${border};
    }
  `}
`;

export {
  SelectWrapper as Wrapper,
  SelectList as List,
  SelectListItem as ListItem,
};
